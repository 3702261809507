import { useRef, useCallback } from 'react';
import { AnchorProvider } from "@coral-xyz/anchor";
import { PumpFunSDK } from 'pumpdotfun-sdk';

const usePumpFunTokenSDK = (connection) => {
  const sdkRef = useRef(null);
 
  const initializeSDK = useCallback(async () => {
    try {
      // If we already have an SDK instance, return it
      if (sdkRef.current) {
        return sdkRef.current;
      }
      const wallet = window.solana;
      const provider = new AnchorProvider(connection, wallet, { commitment: "confirmed" });
      const newSdk = new PumpFunSDK(provider);
      sdkRef.current = newSdk;
      return newSdk;
    } catch (error) {
      console.error("Error initializing PumpFun SDK:", error);
      throw error;
    }
  }, [connection]);
  return { sdkRef, initializeSDK };
};
export default usePumpFunTokenSDK;