import React from 'react';
import './TransactionSettings.css'

const TransactionSettings = ({ 
  slippagePercent, 
  setSlippagePercent, 
  priorityFee, 
  setPriorityFee, 
  jitoTip, 
  setJitoTip 
}) => {
  return (
    <div className="transaction-settings">
      <h1>Settings</h1>
      <div className="settings-fields">
      <div className="form-field">
        <label htmlFor="slippage">Slippage:</label>
        <input
          type="number"
          id="slippage"
          value={slippagePercent}
          onChange={(e) => setSlippagePercent(parseFloat(e.target.value))}
          placeholder='%'
          step="0.5"
          min="0.5"
          max="100"
        />
      </div>
      <div className="form-field">
        <label htmlFor="priorityFee">Priority Fee:</label>
        <input
          type="number"
          id="priorityFee"
          value={priorityFee}
          onChange={(e) => setPriorityFee(parseFloat(e.target.value))}
          placeholder='SOL'
          step="0.000001"
          min="0"
        />
        <p>(for dev only)</p>
      </div>
      <div className="form-field">
        <label htmlFor="jitoTip">Jito Tip:</label>
        <input
          type="number"
          id="jitoTip"
          value={jitoTip}
          onChange={(e) => setJitoTip(parseFloat(e.target.value))}
          placeholder='SOL'
          step="0.000001"
          min="0"
        />
        <p>(for bundles only)</p>
      </div>
      </div>
    </div>
  );
};

export default TransactionSettings;