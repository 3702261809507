import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import "./LicenseManager.css";

const LicenseValidator = {
    interval: null,
    
    async validateSession(onValidLicense) {
        const deviceId = localStorage.getItem('deviceId');
        if (!deviceId) return false;

        try {
            const response = await fetch('https://letspump.fun/api/validate-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ deviceId })
            });
            
            if (!response.ok) {
                throw new Error('Session validation failed');
            }

            const data = await response.json();
            localStorage.setItem('sessionValid', data.valid);
            onValidLicense(data.valid);
            return data.valid;
        } catch (error) {
            if (error.message === 'Session validation failed') {
                localStorage.setItem('sessionValid', 'false');
                onValidLicense(false);
            }
            return false;
        }
    },

    start(onValidLicense) {
        if (this.interval) return;

        if (!localStorage.getItem('deviceId')) {
            localStorage.setItem('deviceId', uuidv4());
        }

        this.validateSession(onValidLicense);
        this.interval = setInterval(() => {
            this.validateSession(onValidLicense);
        }, 30000);
    },

    stop() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
};

if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', () => {
        LicenseValidator.stop();
    });
}

const LicenseManager = ({ onValidLicense }) => {
    const [licenseKey, setLicenseKey] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [sessionValid, setSessionValid] = useState(() => 
        localStorage.getItem('sessionValid') === 'true'
    );

    useEffect(() => {
        LicenseValidator.start(valid => {
            setSessionValid(valid);
            onValidLicense(valid);
        });

        return () => {
            // Don't stop the validator on component unmount
            // Let it continue running in the background
        };
    }, [onValidLicense]);

    const activateLicense = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        
        try {
            const deviceId = localStorage.getItem('deviceId');
            const response = await fetch('https://letspump.fun/api/activate-license', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ licenseKey, deviceId })
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Failed to activate license');
            }
            
            setSessionValid(true);
            localStorage.setItem('sessionValid', 'true');
            onValidLicense(true);
            setLicenseKey('');
        } catch (error) {
            setError(error.message || 'Failed to activate license');
            setSessionValid(false);
            localStorage.setItem('sessionValid', 'false');
            onValidLicense(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError('');
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

    return (
        <div className="license-activation">
            
            {error && (
                <div className="license-activation-error">
                    {error}
                </div>
            )}
            
            {!sessionValid && (
                <div>
                <h1>Welcome to LetsPump.fun!</h1>
                <p>Enter your license key below to continue:</p>
                <form onSubmit={activateLicense}>
                    <input
                        type="text"
                        value={licenseKey}
                        onChange={(e) => setLicenseKey(e.target.value.trim())}
                        placeholder="Enter your license key"
                        disabled={loading}
                        required
                        className="license-input"
                    />
                    <button 
                        type="submit" 
                        disabled={loading || !licenseKey}
                        className="license-button"
                    >
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Activate License"}
                    </button>
                </form>
            </div>
            )}
        </div>
    );
};

export default LicenseManager;