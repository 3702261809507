import React, { useState, useEffect } from 'react';
import { Keypair, PublicKey, VersionedTransaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { getSdk } from '../utils/solanaUtils';
import { getAssociatedTokenAddress, getAccount } from "@solana/spl-token";
import Toast from './Toast';
import bs58 from 'bs58';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faRocket, faRotate } from '@fortawesome/free-solid-svg-icons';
import './DevPanel.css'
import { handleError } from '../utils/errorUtils';
import { useToast } from '../hooks/useToast';
import { truncateAddress, copyToClipboard } from '../utils/formatUtils';

const JITO_API_URL = process.env.REACT_APP_JITO_API_URL || 'https://mainnet.block-engine.jito.wtf/api/v1/bundles';

const DevPanel = ({
  connection, 
  setMintAddress, 
  mintAddress, 
  tokenSupply,
  setLaunchBundle, 
  transactionSettings, 
  devWalletBalance, 
  setDevWalletBalance,
  onDevSupplyUpdate,
  pool
}) => {
  const [sdk, setSdk] = useState(null);
  const [devAccount, setDevAccount] = useState(null);
  const [mint, setMint] = useState(null);
  const [tokenBalance, setTokenBalance] = useState({ raw: 0n, formatted: 0 });
  const [launchingLoading, setLaunchingLoading] = useState(false);
  const [buyingLoading, setBuyingLoading] = useState(false);
  const [sellingLoading, setSellingLoading] = useState(false);
  const [tokenMetadata, setTokenMetadata] = useState({
    name: "",
    symbol: "",
    description: "",
    twitter: "",
    telegram: "",
    website: "",
  });
  const [file, setFile] = useState(null);
  const [buyAmount, setBuyAmount] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previousSolBalance, setPreviousSolBalance] = useState(0);
  const [buyAmountSOL, setBuyAmountSOL] = useState('0.5');
  const [mintSecretKey, setMintSecretKey] = useState('');
  const [sellPercentage, setSellPercentage] = useState(100);
  const [refreshingBalances, setRefreshingBalances] = useState(false);
  const { toast, showToast, clearToast } = useToast();
  const { slippagePercent, priorityFee, jitoTip } = transactionSettings;

  const [useLaunchBundle, setUseLaunchBundle] = useState(() => {
    const saved = localStorage.getItem('useLaunchBundle');
    return saved !== null ? JSON.parse(saved) : false;
  });

  const [bundleWallets, setBundleWallets] = useState(() => {
    const saved = localStorage.getItem('launchBundleWallets');
    return saved ? JSON.parse(saved) : [];
  });

  let previousBalance = null; // Store the previous balance globally

  useEffect(() => {
    const initializeSdk = async () => {
      const newSdk = getSdk(connection);
      setSdk(newSdk);
      
      console.log('Initialized SDK:', newSdk);
      console.log('SDK Program:', newSdk.program);
      console.log('Available methods in program:', Object.keys(newSdk.program));
  
      // Get dev wallet key from localStorage instead of env
      const savedKeys = JSON.parse(localStorage.getItem('walletKeys') || '{}');
      const devAccountSecretKey = savedKeys.devWallet;
      
      if (!devAccountSecretKey) {
        console.error('Dev wallet secret key not found in localStorage');
        showToast('Dev wallet not configured', 'error');
        return;
      }
  
      try {
        const account = Keypair.fromSecretKey(bs58.decode(devAccountSecretKey));
        setDevAccount(account);
      } catch (error) {
        console.error('Error initializing dev account:', error);
        showToast('Failed to initialize dev wallet', 'error');
      }
    };
  
    initializeSdk();
  }, [connection]);

  useEffect(() => {
    const fetchSolBalance = async () => {
      if (sdk && devAccount) {
        try {
          const solBalance = await connection.getBalance(devAccount.publicKey);
          const newSolBalance = solBalance / LAMPORTS_PER_SOL;
          setDevWalletBalance(newSolBalance);
          
          // Check if SOL balance has changed
          if (newSolBalance !== previousSolBalance) {
            console.log('SOL balance changed:', newSolBalance, previousSolBalance);
            if (launchingLoading) {
              setLaunchingLoading(false);
              showToast('Token launched successfully!', 'success', 5000);
            }
            setPreviousSolBalance(newSolBalance);
          }
        } catch (error) {
          console.error('Error fetching SOL balance:', error);
        }
      }
    };
  
    const fetchTokenBalance = async () => {
      // Reset token balance if mintAddress is empty or invalid
      if (!sdk || !devAccount || !mintAddress) {
        setTokenBalance({ raw: 0n, formatted: 0 });
        previousBalance = 0n;
        return;
      }
    
      try {
        // Get token decimals first
        const mintInfo = await sdk.connection.getParsedAccountInfo(new PublicKey(mintAddress));
        if (!mintInfo.value || !mintInfo.value.data || !mintInfo.value.data.parsed) {
          console.debug('Unable to parse mint info');
          setTokenBalance({ raw: 0n, formatted: 0 });
          return;
        }
        const tokenDecimals = mintInfo.value.data.parsed.info.decimals;
    
        const mintPublicKey = new PublicKey(mintAddress);
        const associatedTokenAddress = await getAssociatedTokenAddress(
          mintPublicKey,
          devAccount.publicKey
        );
        
        const tokenAccount = await getAccount(sdk.connection, associatedTokenAddress);
        const rawBalance = BigInt(tokenAccount.amount.toString());
        const formattedBalance = Number(rawBalance) / Math.pow(10, tokenDecimals);
    
        // Detect if the balance has changed
        if (previousBalance !== null && rawBalance !== previousBalance) {
          const balanceDiff = rawBalance - previousBalance;
          const action = balanceDiff > 0 ? 'bought' : 'sold';
          showToast(`Successfully ${action} ${Math.abs(Number(balanceDiff) / Math.pow(10, tokenDecimals)).toFixed(6)} tokens.`);
          
          // Set loading states to false if the balance has changed
          setBuyingLoading(false);
          setSellingLoading(false);
        }
    
        setTokenBalance({ raw: rawBalance, formatted: formattedBalance });
        previousBalance = rawBalance;
      } catch (error) {
        if (error.name === 'TokenAccountNotFoundError') {
          console.log("This token does not exist.");
          setTokenBalance({ raw: 0n, formatted: 0 });
          previousBalance = 0n;
        } else {
          console.error("Error fetching token balance:", error);
          setTokenBalance({ raw: 0n, formatted: 0 });
          previousBalance = 0n;
        }
      }
    };
  
    const fetchBalances = () => {
      fetchSolBalance();
      fetchTokenBalance();
    };
  
    // Fetch balances immediately when the component mounts or dependencies change
    fetchBalances();
  
    let intervalId;
    if (buyingLoading || sellingLoading || launchingLoading) {
      // Set up an interval to fetch balances every 1 second only if loading
      intervalId = setInterval(fetchBalances, 3000);
    }
  
    // Clean up function to clear the interval when the component unmounts or dependencies change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sdk, devAccount, setDevWalletBalance, mint, buyingLoading, sellingLoading, launchingLoading, previousSolBalance, mintAddress, connection]);

  useEffect(() => {
    // Add this to update the mint address in the parent component
    if (mint && mint.publicKey) {
      setMintAddress(mint.publicKey.toBase58());
    }
  }, [mint, setMintAddress]);

  useEffect(() => {
    if (mintAddress) {
      try {
        setMint(new PublicKey(mintAddress));
      } catch (error) {
        console.error('Invalid mint address:', error);
        setMint(null);
      }
    } else {
      setMint(null);
    }
  }, [mintAddress]);

  useEffect(() => {
    // Update localStorage when useLaunchBundle changes
    localStorage.setItem('useLaunchBundle', JSON.stringify(useLaunchBundle));
    // Update parent component state
    setLaunchBundle(useLaunchBundle);
  }, [useLaunchBundle, setLaunchBundle]);

  const handleLaunchBundleCheckbox = (e) => {
    setUseLaunchBundle(e.target.checked);
  };
  
  const updateMint = (secretKey) => {
    try {
      let decodedMintSecretKey;
      if (secretKey.startsWith('[') && secretKey.endsWith(']')) {
        // Handle array format
        decodedMintSecretKey = new Uint8Array(JSON.parse(secretKey));
      } else {
        // Handle base58 format
        decodedMintSecretKey = bs58.decode(secretKey);
      }
      const newMint = Keypair.fromSecretKey(decodedMintSecretKey);
      setMint(newMint);
    } catch (error) {
      console.error('Error updating mint:', error);
      setMint(null);
    }
  };

  const handleMintSecretKeyChange = (e) => {
    const newSecretKey = e.target.value;
    setMintSecretKey(newSecretKey);
    // Remove the line that was saving to localStorage
    updateMint(newSecretKey); // Update mint state
  };  

  const resetMintSecretKey = () => {
    setMintSecretKey('');
  }; 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTokenMetadata(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleCreateAndBuy = async (e) => {
    e.preventDefault();
    if (!sdk) {
      console.error('SDK is not initialized');
      showToast('Error: SDK is not initialized', 'error');
      return;
    }
  
    let mintKeypair;
    if (mintSecretKey) {
      // If mint secret key is provided, we're launching a new token
      try {
        let decodedMintSecretKey;
        if (mintSecretKey.startsWith('[') && mintSecretKey.endsWith(']')) {
          // Handle array format
          decodedMintSecretKey = new Uint8Array(JSON.parse(mintSecretKey));
        } else {
          // Handle base58 format
          decodedMintSecretKey = bs58.decode(mintSecretKey);
        }
        mintKeypair = Keypair.fromSecretKey(decodedMintSecretKey);
      } catch (error) {
        console.error('Error decoding mint secret key:', error);
        showToast('Invalid mint secret key', 'error');
        return;
      }
    } else if (mintAddress) {
      // If mint address is provided, we're buying an existing token
      try {
        mintKeypair = new PublicKey(mintAddress);
      } catch (error) {
        console.error('Error creating PublicKey from mint address:', error);
        showToast('Invalid mint address', 'error');
        return;
      }
    } else {
      showToast('Please enter a valid mint secret key or mint address', 'error');
      return;
    }
  
    setLaunchingLoading(true);
    showToast("Initiating token launch or buy...");
  
    try {
      if (useLaunchBundle) {
        await handleBundleLaunch(mintKeypair);
      } else {
        await handleRegularLaunch(mintKeypair);
      }
    } catch (error) {
      console.error('Error in handleCreateAndBuy:', error);
      handleError(error, showToast);
      setLaunchingLoading(false);
    }
  };

  const handleRegularLaunch = async (mintKeypair) => {
    console.log('Starting token creation process...');
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", tokenMetadata.name);
    formData.append("symbol", tokenMetadata.symbol);
    formData.append("description", tokenMetadata.description);
    formData.append("twitter", tokenMetadata.twitter);
    formData.append("telegram", tokenMetadata.telegram);
    formData.append("website", tokenMetadata.website);
  
    console.log('Sending metadata to IPFS...');
    const metadataResponse = await fetch('http://localhost:5000/api/ipfs', {
      method: "POST",
      body: formData,
    });
  
    if (!metadataResponse.ok) {
      throw new Error(`IPFS Error: ${metadataResponse.statusText}`);
    }
  
    const metadata = await metadataResponse.json();
    console.log('Metadata received:', metadata);
  
    const createTokenMetadata = {
      name: tokenMetadata.name,
      symbol: tokenMetadata.symbol,
      description: tokenMetadata.description,
      file: file,
      twitter: tokenMetadata.twitter,
      telegram: tokenMetadata.telegram,
      website: tokenMetadata.website
    };
  
    const buyAmountSol = BigInt(Math.floor(parseFloat(buyAmountSOL) * LAMPORTS_PER_SOL));
    const slippageBasisPoints = BigInt(Math.floor(slippagePercent * 100));
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
    const priorityFeeConfig = {
      unitLimit: computeUnits,
      unitPrice: microLamports
    };
  
    console.log('Calling SDK createAndBuy with parameters:', {
      devAccount: devAccount.publicKey.toString(),
      mint: mintKeypair instanceof PublicKey ? mintKeypair.toString() : mintKeypair.publicKey.toString(),
      createTokenMetadata,
      buyAmountSol: buyAmountSol.toString(),
      slippageBasisPoints: slippageBasisPoints.toString(),
      priorityFeeConfig
    });
  
    const result = await sdk.createAndBuy(
      devAccount,
      mintKeypair,
      createTokenMetadata,
      buyAmountSol,
      slippageBasisPoints,
      priorityFeeConfig
    );
  
    console.log('SDK createAndBuy result:', result);
  
    if (result.success) {
      console.log('Token creation initiated successfully!', result);
      showToast('Token launched successfully!', 'success', 5000);
      // The balance change will handle setting launchingLoading to false
    } else {
      console.error('Error in createAndBuy:', result.error);
      handleError(result.error, showToast);
      setLaunchingLoading(false);
    }
  };

  const handleBundleLaunch = async (mintKeypair) => {
    try {
      const devWallet = devAccount;
      const signerKeyPairs = bundleWallets.map(wallet => Keypair.fromSecretKey(bs58.decode(wallet.secretKey)));

      // Create token metadata
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", tokenMetadata.name);
      formData.append("symbol", tokenMetadata.symbol);
      formData.append("description", tokenMetadata.description);
      formData.append("telegram", tokenMetadata.telegram);
      formData.append("twitter", tokenMetadata.twitter);
      formData.append("website", tokenMetadata.website);
      formData.append("showName", "true");

      const metadataResponse = await fetch("http://localhost:5000/api/ipfs", {
        method: "POST",
        body: formData,
      });
      const metadataResponseJSON = await metadataResponse.json();

      const bundledTxArgs = [
        {
          publicKey: devWallet.publicKey.toBase58(),
          action: "create",
          tokenMetadata: {
            name: tokenMetadata.name,
            symbol: tokenMetadata.symbol,
            uri: metadataResponseJSON.metadataUri
          },
          mint: mintKeypair instanceof PublicKey ? mintKeypair.toString() : mintKeypair.publicKey.toString(),
          denominatedInSol: "true",
          amount: buyAmountSOL,
          slippage: slippagePercent,
          priorityFee: jitoTip,
          pool: "pump"
        },
        ...bundleWallets.map(wallet => ({
          publicKey: wallet.publicKey,
          action: "buy",
          mint: mintKeypair instanceof PublicKey ? mintKeypair.toString() : mintKeypair.publicKey.toString(),
          denominatedInSol: "true",
          amount: wallet.airdropAmount,
          slippage: slippagePercent,
          priorityFee: jitoTip,
          pool: "pump"
        }))
      ];

      const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bundledTxArgs)
      });

      if (response.status === 200) {
        const transactions = await response.json();
        let encodedSignedTransactions = [];
        let signatures = [];

        for (let i = 0; i < bundledTxArgs.length; i++) {
          const tx = VersionedTransaction.deserialize(new Uint8Array(bs58.decode(transactions[i])));
          if (i === 0) {
            tx.sign([mintKeypair instanceof PublicKey ? new Keypair({ publicKey: mintKeypair }) : mintKeypair, devWallet]);
          } else {
            tx.sign([signerKeyPairs[i - 1]]);
          }
          encodedSignedTransactions.push(bs58.encode(tx.serialize()));
          signatures.push(bs58.encode(tx.signatures[0]));
        }

        const jitoResponse = await fetch(JITO_API_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [encodedSignedTransactions]
          })
        });

        const result = await jitoResponse.json();
        console.log('Jito bundle launch result:', result);
        showToast('Token launched successfully via Jito bundle!', 'success');
        signatures.forEach((sig, i) => {
          console.log(`Transaction ${i}: https://solscan.io/tx/${sig}`);
        });
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error in bundle launch:', error);
      handleError(error, showToast);
    } finally {
      setLaunchingLoading(false);
    }
  };

  const handleManualBuy = async () => {
    if (!mintAddress) {
      showToast("No mint address set.", 'error', 5000);
      return;
    }
  
    if (!buyAmount || parseFloat(buyAmount) <= 0) {
      showToast("Please enter a valid amount to buy.", 'error', 5000);
      return;
    }
  
    setBuyingLoading(true);
    showToast("Initiating purchase...");
  
    try {
      const bundledTxArgs = [{
        publicKey: devAccount.publicKey.toBase58(),
        action: "buy",
        mint: mintAddress,
        denominatedInSol: "true",
        amount: buyAmount,
        slippage: slippagePercent,
        priorityFee: jitoTip,
        pool: pool
      }];
  
      const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bundledTxArgs)
      });

      setBuyingLoading(false);
  
      if (response.status === 200) {
        const transactions = await response.json();
        const tx = VersionedTransaction.deserialize(new Uint8Array(bs58.decode(transactions[0])));
        tx.sign([devAccount]);
        const encodedSignedTransaction = bs58.encode(tx.serialize());
        const signature = bs58.encode(tx.signatures[0]);
  
        const jitoResponse = await fetch(JITO_API_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [[encodedSignedTransaction]]
          })
        });
  
        console.log(`Jito Response:`, jitoResponse);
        console.log(`Buy Transaction: https://solscan.io/tx/${signature}`);

        await connection.confirmTransaction(signature, 'confirmed');
        
        // Update balances after confirmation
        await silentBalanceUpdate();
        showToast("Purchase confirmed!", 'success');
        setBuyAmount(''); // Reset input field
      } else {
        throw new Error(`Transaction failed: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error buying tokens:", error);
      handleError(error, showToast);
    }
  };
  
  const handleSell = async () => {
    if (!mintAddress) {
      showToast("No mint address set.", 'error', 5000);
      return;
    }
  
    if (sellPercentage === 0) {
      showToast("Sell percentage has to be more than 0.", 'error', 5000);
      return;
    }
  
    if (tokenBalance.formatted === 0) {
      showToast("You don't have any tokens to sell.", 'error', 5000);
      return;
    }
  
    setSellingLoading(true);
    showToast("Initiating sale...");
  
    try {
      const bundledTxArgs = [{
        publicKey: devAccount.publicKey.toBase58(),
        action: "sell",
        mint: mintAddress,
        denominatedInSol: "false",
        amount: `${sellPercentage}%`,
        slippage: slippagePercent,
        priorityFee: jitoTip,
        pool: pool
      }];
  
      const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bundledTxArgs)
      });

      setSellingLoading(false);
  
      if (response.status === 200) {
        const transactions = await response.json();
        const tx = VersionedTransaction.deserialize(new Uint8Array(bs58.decode(transactions[0])));
        tx.sign([devAccount]);
        const encodedSignedTransaction = bs58.encode(tx.serialize());
        const signature = bs58.encode(tx.signatures[0]);
  
        const jitoResponse = await fetch(JITO_API_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [[encodedSignedTransaction]]
          })
        });
  
        console.log(`Jito Response:`, jitoResponse);
        console.log(`Sell Transaction: https://solscan.io/tx/${signature}`);

        await connection.confirmTransaction(signature, 'confirmed');
        
        // Update balances after confirmation
        await silentBalanceUpdate();
        showToast("Sale confirmed!", 'success');
      } else {
        throw new Error(`Transaction failed: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error selling tokens:", error);
      handleError(error, showToast);
    }
  };

  const handleCopyToClipboard = (text) => {
    copyToClipboard(
      text,
      (message) => showToast(message, 'success', 3000),
      (message) => showToast(message, 'error', 3000)
    );
  };

  const handleSellPercentageChange = (e) => {
    const value = parseInt(e.target.value);
    const snappedValue = Math.round(value / 25) * 25;
    setSellPercentage(snappedValue);
  };

  const calculatePriorityFee = (solAmount, computeUnits) => {
    // Convert SOL to lamports
    const lamports = solAmount * LAMPORTS_PER_SOL;
    // Calculate microLamports per compute unit
    const microLamports = Math.floor((lamports * 1_000_000) / computeUnits);
    return microLamports;
  };

  const refreshBalances = async () => {

    if (!connection) {
      showToast('No connection available, please check your RPC URL', 'error');
      return;
    }
    
    if (!sdk || !devAccount) return;
    setRefreshingBalances(true);
    
    try {
      // Fetch SOL balance
      const solBalance = await connection.getBalance(devAccount.publicKey);
      const newSolBalance = solBalance / LAMPORTS_PER_SOL;
      setDevWalletBalance(newSolBalance);
      
      // Fetch token balance if mint address exists
      if (mintAddress) {
        try {
          // Get token decimals first
          const mintInfo = await sdk.connection.getParsedAccountInfo(new PublicKey(mintAddress));
          if (!mintInfo.value || !mintInfo.value.data || !mintInfo.value.data.parsed) {
            console.debug('Unable to parse mint info');
            setTokenBalance({ raw: 0n, formatted: 0 });
            return;
          }
          const tokenDecimals = mintInfo.value.data.parsed.info.decimals;
  
          const mintPublicKey = new PublicKey(mintAddress);
          const associatedTokenAddress = await getAssociatedTokenAddress(
            mintPublicKey,
            devAccount.publicKey
          );
          
          const tokenAccount = await getAccount(sdk.connection, associatedTokenAddress);
          const rawBalance = BigInt(tokenAccount.amount.toString());
          const formattedBalance = Number(rawBalance) / Math.pow(10, tokenDecimals);
          
          setTokenBalance({ raw: rawBalance, formatted: formattedBalance });
        } catch (error) {
          if (error.name === 'TokenAccountNotFoundError') {
            setTokenBalance({ raw: 0n, formatted: 0 });
          } else {
            console.error('Error fetching token balance:', error);
            setTokenBalance({ raw: 0n, formatted: 0 });
          }
        }
      }
      
      showToast('Balances updated successfully!', 'success', 2000);
    } catch (error) {
      console.error('Error refreshing balances:', error);
      showToast('Failed to refresh balances', 'error');
    } finally {
      setTimeout(() => {
        setRefreshingBalances(false);
      }, 1000);
    }
  };

  const formatTokenBalance = (balance) => {
    if (!balance || balance === 0) return '0';
    
    if (balance >= 1_000_000) {
      return `${(balance / 1_000_000).toFixed(2)}M`;
    }
    
    if (balance >= 1_000) {
      return `${(balance / 1_000).toFixed(2)}k`;
    }
    
    return Math.floor(balance).toString();
  };

  const calculateDevSupplyPercentage = () => {
    if (!tokenSupply || !tokenBalance.formatted) return 0;
    // Both tokenBalance.formatted and tokenSupply are already decimal-adjusted
    return ((tokenBalance.formatted / tokenSupply) * 100).toFixed(10);
  };

  // Add useEffect to update parent when token balance changes
  useEffect(() => {
    onDevSupplyUpdate(Number(calculateDevSupplyPercentage()));
  }, [tokenBalance, onDevSupplyUpdate]);

  const silentBalanceUpdate = async () => {
    if (!sdk || !devAccount) return;
    
    try {
      // Fetch SOL balance
      const solBalance = await connection.getBalance(devAccount.publicKey);
      const newSolBalance = solBalance / LAMPORTS_PER_SOL;
      setDevWalletBalance(newSolBalance);
      
      // Fetch token balance if mint address exists
      if (mintAddress) {
        try {
          const mintInfo = await sdk.connection.getParsedAccountInfo(new PublicKey(mintAddress));
          if (!mintInfo.value || !mintInfo.value.data || !mintInfo.value.data.parsed) {
            console.debug('Unable to parse mint info');
            setTokenBalance({ raw: 0n, formatted: 0 });
            return;
          }
          const tokenDecimals = mintInfo.value.data.parsed.info.decimals;
  
          const mintPublicKey = new PublicKey(mintAddress);
          const associatedTokenAddress = await getAssociatedTokenAddress(
            mintPublicKey,
            devAccount.publicKey
          );
          
          const tokenAccount = await getAccount(sdk.connection, associatedTokenAddress);
          const rawBalance = BigInt(tokenAccount.amount.toString());
          const formattedBalance = Number(rawBalance) / Math.pow(10, tokenDecimals);
          
          setTokenBalance({ raw: rawBalance, formatted: formattedBalance });
        } catch (error) {
          if (error.name === 'TokenAccountNotFoundError') {
            setTokenBalance({ raw: 0n, formatted: 0 });
          } else {
            console.error('Error fetching token balance:', error);
            setTokenBalance({ raw: 0n, formatted: 0 });
          }
        }
      }
    } catch (error) {
      console.error('Error updating balances:', error);
    }
  };

  const generateMintSecretKey = () => {
    const newKeypair = Keypair.generate();
    const keyArray = Array.from(newKeypair.secretKey);
    setMintSecretKey(`[${keyArray.toString()}]`);
    updateMint(`[${keyArray.toString()}]`);
  };

  return (
    <div className="dev-panel">
  <h1>Dev</h1>
  
  <div className="info-section">
  <p>
    <span className="label">Wallet:</span>
    <span className="value">
    <span 
      className="truncated-address" 
      onClick={() => handleCopyToClipboard(devAccount?.publicKey.toBase58())}
      title={devAccount?.publicKey.toBase58()}
    >
      {truncateAddress(devAccount?.publicKey.toBase58())}
    </span>
      <i className="fas fa-copy copy-icon"></i>
    </span>
  </p>
  <div className="dev-balance-refresh">
  <div className='dev-balance'>
    <span className="sol-balance">
      <span className="label">Balance:</span>
      <span className="value">{devWalletBalance.toFixed(3)} SOL</span>
    </span>
    <div className='balance-divider'></div>
    <span className='token-balance'>
      <span className="label">Tokens:</span>
      <span className="value">{formatTokenBalance(tokenBalance.formatted)}</span>
    </span>
  </div>
  <div className="refresh-balance">
      <button
          className="refresh-button"
          onClick={refreshBalances}
          disabled={refreshingBalances}
        >
        <FontAwesomeIcon 
          icon={faRotate} 
          spin={refreshingBalances}
          className="refresh-icon"
        />
      </button>
    </div>
  </div>
</div>

<div className="main-actions-title">
  <span>Create Token</span>
  </div>

<form onSubmit={handleCreateAndBuy} className="token-form">
  
  <div className='tokenMetadataRequired'>
  <div className='tokenNameSymbolImage'>
  <div className='tokenImage'>
  <div 
  className="circular-element" 
  onClick={() => document.getElementById('file-input').click()}
  style={{ backgroundImage: selectedImage ? `url(${selectedImage})` : 'none' }}
>
  <span>{selectedImage ? '' : '+'}</span>
</div>
<input
  type="file"
  id="file-input"
  className="file-input"
  onChange={handleFileChange}
  accept="image/*"
  required
/>
</div>
  
<div className='tokenNameSymbol'>
  <div className="form-field">
    <label htmlFor="name">Token Name:</label>
    <input
      type="text"
      id="name"
      name="name"
      value={tokenMetadata.name}
      onChange={handleInputChange}
      required
    />
  </div>
  
  <div className="form-field">
    <label htmlFor="symbol">Token Symbol:</label>
    <input
      type="text"
      id="symbol"
      name="symbol"
      value={tokenMetadata.symbol}
      onChange={handleInputChange}
      required
    />
  </div>
  </div>
  </div>
  
  <div className="form-field description-field">
    <label htmlFor="description">Description:</label>
    <textarea
      id="description"
      name="description"
      value={tokenMetadata.description}
      onChange={handleInputChange}
      required
    ></textarea>
  </div>
  </div>
  
  <div className="social-fields">
    <div className="form-field">
      <label htmlFor="telegram">Telegram:</label>
      <input
        type="text"
        id="telegram"
        name="telegram"
        value={tokenMetadata.telegram}
        onChange={handleInputChange}
      />
    </div>
    
    <div className="form-field">
      <label htmlFor="twitter">Twitter:</label>
      <input
        type="text"
        id="twitter"
        name="twitter"
        value={tokenMetadata.twitter}
        onChange={handleInputChange}
      />
    </div>
    <div className="form-field">
      <label htmlFor="website">Website:</label>
      <input
        type="text"
        id="website"
        name="website"
        value={tokenMetadata.website}
        onChange={handleInputChange}
      />
    </div>
  </div>


    <div className='tokenMintSecretKey'>
      <div className="form-field">
        <div className="mint-status">
          <div className="mintlabel-status">
            <label htmlFor="mintSecretKey">Mint Secret Key:</label>
          </div>
          <div className="generate-reset">
          {!mintSecretKey && (
          <div className="reset">
              <button type="button" onClick={generateMintSecretKey}>
                [Generate]
              </button>
            </div>
            )}
            {mintSecretKey && (
            <div className="reset">
              <button type="button" onClick={resetMintSecretKey}>
                [Clear]
              </button>
            </div>
            )}
          </div>
          </div>
          <input
            type="password"
            id="mintSecretKey"
            name="mintSecretKey"
            value={mintSecretKey}
            onChange={handleMintSecretKeyChange}
            required
            placeholder="(base58 or Uint8Array)"
          />
      </div>
    </div>

  <div className='tokenBuyAmount'>
    <div className="form-field">
      <label htmlFor="buyAmountSOL">Initial Buy Amount (SOL):</label>
      <input
        type="number"
        id="buyAmountSOL"
        name="buyAmountSOL"
        value={buyAmountSOL}
        onChange={(e) => setBuyAmountSOL(e.target.value)}
        step="0.0001"
        min="0"
        required
      />
    </div>
  </div>
  
  <div className="bundle-launch-checkbox">
  <div className="form-field">
    <input
      type="checkbox"
      checked={useLaunchBundle}
      onChange={handleLaunchBundleCheckbox}
    />
  <p className="bundle-launch-checkbox-label">Launch Bundle</p>
  </div>
  </div>

  <div className='tokenMetadataButton'>
  <button type="submit" disabled={launchingLoading || (!mintSecretKey && !mintAddress)}>
    {launchingLoading ? (
      <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      <>
        <FontAwesomeIcon icon={faRocket} style={{ marginRight: '8px' }} />
        Launch
      </>
    )}
  </button>
</div>

</form>

<div className="divider"></div>

<div className="action-buttons">
        <div className='dev-buy-more'>
          <div className='dev-buy-more-input-button'>
            <div className='dev-buy-more-input'>
              <input
                type="number"
                value={buyAmount}
                onChange={(e) => setBuyAmount(e.target.value)}
                placeholder="SOL"
                disabled={buyingLoading || sellingLoading}
              />
            </div>
            <div className='dev-buy-more-button'>
              <button onClick={handleManualBuy} disabled={buyingLoading || sellingLoading || !mintAddress}>
                {buyingLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Buy'}
              </button>
            </div>
          </div>
        </div>
        
        <div className='dev-sell'>
        <div className='dev-sell-slider'>
          <input
            type="range"
            min="0"
            max="100"
            step="25"
            value={sellPercentage}
            onChange={handleSellPercentageChange}
            className="slider"
          />
        </div>
        <div className='dev-sell-button'>
          <button onClick={handleSell} disabled={sellingLoading || buyingLoading || !mintAddress}>
            {sellingLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : `Sell ${sellPercentage}%`}
          </button>
        </div>
      </div>
      </div>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={clearToast}
        />
      )}
    </div>
  );
};

export default DevPanel;