import React, { useState, useEffect } from 'react';
import { Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, ComputeBudgetProgram, SendTransactionError } from '@solana/web3.js';
import bs58 from 'bs58';
import './MainWalletPanel.css';
import Toast from './Toast';
import { useToast } from '../hooks/useToast';
import { handleError } from '../utils/errorUtils';
import { truncateAddress, copyToClipboard } from '../utils/formatUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPaperPlane, faWallet, faCoins, faTimes, faRotate, faBoltLightning } from '@fortawesome/free-solid-svg-icons';

const MainWalletPanel = ({ 
  bundleAirdropAmount, 
  launchBundleAirdropAmount, 
  updateBumpWalletBalance, 
  updateDevWalletBalance,
  updateBundleWalletsBalances,
  updateLaunchBundleWalletsBalances,
  bundleWallets,
  launchBundleWallets,
  mainWalletBalance,
  setMainWalletBalance,
  updateVolumePriorityFee,
  transactionSettings,
  connection
}) => {
  const [balance, setBalance] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [mainWallet, setMainWallet] = useState(null);
  const [refreshingBalance, setRefreshingBalance] = useState(false);
  const { toast, showToast, clearToast } = useToast();
  const [isAirdropping, setIsAirdropping] = useState(false);
  const [isAirdroppingLaunch, setIsAirdroppingLaunch] = useState(false);
  const [isAirdroppingDev, setIsAirdroppingDev] = useState(false);
  const [isAirdroppingBump, setIsAirdroppingBump] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isWithdrawingLaunch, setIsWithdrawingLaunch] = useState(false);
  const [isWithdrawingDev, setIsWithdrawingDev] = useState(false);
  const [isWithdrawingBump, setIsWithdrawingBump] = useState(false);
  const [devPublicKey, setDevPublicKey] = useState(null);
  const [showAirdropOverlay, setShowAirdropOverlay] = useState(false);
  const [showBumpAirdropOverlay, setShowBumpAirdropOverlay] = useState(false);
  const [airdropAmount, setAirdropAmount] = useState('0');
  const [bumpAirdropAmount, setBumpAirdropAmount] = useState('0');
  const [showBundleAirdropOverlay, setShowBundleAirdropOverlay] = useState(false);
  const [showLaunchBundleAirdropOverlay, setShowLaunchBundleAirdropOverlay] = useState(false);
  const [bundleAirdropPercentage, setBundleAirdropPercentage] = useState('0');
  const [launchBundleAirdropPercentage, setLaunchBundleAirdropPercentage] = useState('0');
  const [totalBundleAirdropAmount, setTotalBundleAirdropAmount] = useState(0);
  const [totalLaunchBundleAirdropAmount, setTotalLaunchBundleAirdropAmount] = useState(0);
  const [showManualAirdropModal, setShowManualAirdropModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedWalletBalance, setSelectedWalletBalance] = useState(null);
  const [manualAirdropAmount, setManualAirdropAmount] = useState('0');
  const [isManualAirdropping, setIsManualAirdropping] = useState(false);
  const [isWithdrawingAll, setIsWithdrawingAll] = useState(false);
  const [withdrawingWallets, setWithdrawingWallets] = useState({});
  const [withdrawingLaunchWallets, setWithdrawingLaunchWallets] = useState({});
  const [priorityFee, setPriorityFee] = useState(0.0001);
  const [includeBundleFeeCover, setIncludeBundleFeeCover] = useState(false);
  const [includeLaunchFeeCover, setIncludeLaunchFeeCover] = useState(false);
  const { jitoTip, slippagePercent } = transactionSettings;

  useEffect(() => {
    const fetchBalance = async () => {
      const savedKeys = JSON.parse(localStorage.getItem('walletKeys') || '{}');
      const mainWalletSecretKey = savedKeys.mainWallet;
      const devWalletSecretKey = savedKeys.devWallet;
    
      if (!mainWalletSecretKey) {
        console.error('Main wallet secret key not found in localStorage');
        showToast('Main wallet not configured', 'error');
        return;
      }
    
      try {
        const account = Keypair.fromSecretKey(bs58.decode(mainWalletSecretKey));
        setMainWallet(account);
        setPublicKey(account.publicKey.toString());
    
        if (devWalletSecretKey) {
          const devAccount = Keypair.fromSecretKey(bs58.decode(devWalletSecretKey));
          setDevPublicKey(devAccount.publicKey.toString());
        }
    
        // Use account instead of mainWallet
        const solBalance = await connection.getBalance(account.publicKey);
        setBalance(solBalance / LAMPORTS_PER_SOL);
      } catch (error) {
        console.error('Error fetching main wallet balance:', error);
        handleError(error, showToast);
      }
    };
    fetchBalance();
}, [showToast, connection]);

  useEffect(() => {
    if (mainWalletBalance !== null) {
      setBalance(mainWalletBalance);
    }
  }, [mainWalletBalance]);

  const handleCopyToClipboard = (text) => {
    copyToClipboard(
      text,
      (message) => showToast(message, 'success', 3000),
      (message) => showToast(message, 'error', 3000)
    );
  };

  useEffect(() => {
    const calculateTotalAirdropAmount = (amount, percentage) => {
      const multiplier = percentageToMultiplier(percentage);
      return amount * multiplier;
    };

    setTotalBundleAirdropAmount(calculateTotalAirdropAmount(bundleAirdropAmount, bundleAirdropPercentage));
    setTotalLaunchBundleAirdropAmount(calculateTotalAirdropAmount(launchBundleAirdropAmount, launchBundleAirdropPercentage));
  }, [bundleAirdropAmount, launchBundleAirdropAmount, bundleAirdropPercentage, launchBundleAirdropPercentage]);

  const calculateTotalAirdropAmount = (wallets, percentage, includeFeeCover = false) => {
    const multiplier = percentageToMultiplier(percentage);
    return wallets.reduce((total, wallet) => {
      const baseAmount = wallet.airdropAmount * multiplier;
      const feeCoverAmount = includeFeeCover ? calculateFeeCoverAmount(wallet.airdropAmount) : 0;
      return total + baseAmount + feeCoverAmount;
    }, 0);
  };

  useEffect(() => {
    const bundleWallets = JSON.parse(localStorage.getItem('bundleWallets') || '[]');
    const launchBundleWallets = JSON.parse(localStorage.getItem('launchBundleWallets') || '[]');

    const totalBundle = calculateTotalAirdropAmount(bundleWallets, bundleAirdropPercentage, includeBundleFeeCover);
    const totalLaunchBundle = calculateTotalAirdropAmount(launchBundleWallets, launchBundleAirdropPercentage, includeLaunchFeeCover);

    setTotalBundleAirdropAmount(totalBundle);
    setTotalLaunchBundleAirdropAmount(totalLaunchBundle);
  }, [bundleAirdropPercentage, launchBundleAirdropPercentage, includeBundleFeeCover, includeLaunchFeeCover]);

  const handlePriorityFeeChange = (e) => {
    const newFee = Math.max(0, Number(e.target.value));
    setPriorityFee(newFee);
    updateVolumePriorityFee(newFee); // Update VolumeBot's priority fee
  };

  const calculatePriorityFee = (solAmount, computeUnits) => {
    const lamports = solAmount * LAMPORTS_PER_SOL;
    const microLamports = Math.floor((lamports * 1_000_000) / computeUnits);
    return microLamports;
  };

  const airdropToBundleWallets = async () => {
    setIsAirdropping(true);
    const bundleWallets = JSON.parse(localStorage.getItem('bundleWallets') || '[]');
  
    // Calculate total airdrop amount including fee coverage if enabled
    const multiplier = percentageToMultiplier(bundleAirdropPercentage);
    const totalAirdropAmount = bundleWallets.reduce((total, wallet) => {
      const baseAmount = wallet.airdropAmount * multiplier;
      const feeCoverAmount = includeBundleFeeCover ? calculateFeeCoverAmount(wallet.airdropAmount) : 0;
      return total + baseAmount + feeCoverAmount;
    }, 0);
  
    // Check if main wallet has sufficient balance
    const mainWalletBalance = await connection.getBalance(mainWallet.publicKey);
    if (mainWalletBalance < totalAirdropAmount * LAMPORTS_PER_SOL) {
      showToast(`Insufficient balance. Required: ${totalAirdropAmount} SOL, Available: ${mainWalletBalance / LAMPORTS_PER_SOL} SOL`, 'error');
      setIsAirdropping(false);
      return;
    }

    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
    const airdropResults = await Promise.all(bundleWallets.map(async (wallet) => {
      try {
        const destinationPublicKey = new PublicKey(wallet.publicKey);
        const baseAmount = wallet.airdropAmount * multiplier;
        const feeCoverAmount = includeBundleFeeCover ? calculateFeeCoverAmount(wallet.airdropAmount) : 0;
        const totalWalletAirdropAmount = baseAmount + feeCoverAmount;
        const airdropAmount = Math.floor(totalWalletAirdropAmount * LAMPORTS_PER_SOL);
  
        const transaction = new Transaction();
      
        transaction.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: computeUnits,
          }),
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: microLamports,
          }),
          SystemProgram.transfer({
            fromPubkey: mainWallet.publicKey,
            toPubkey: destinationPublicKey,
            lamports: airdropAmount,
          })
        );
  
        const signature = await connection.sendTransaction(transaction, [mainWallet]);
        await connection.confirmTransaction(signature, 'confirmed');
  
        console.log(`Airdropped ${totalWalletAirdropAmount} SOL to ${wallet.publicKey}`);
        return true;
      } catch (error) {
        console.error(`Failed to airdrop to ${wallet.publicKey}:`, error);
        return false;
      }
    }));
  
    const successCount = airdropResults.filter(result => result).length;
    const failCount = airdropResults.length - successCount;

    if (successCount > 0) {
      await updateBundleWalletsBalances();
    }
  
    if (failCount > 0) {
      showToast(`Airdrop completed. ${successCount} successful, ${failCount} failed. Check console for details.`, 'warning');
    } else {
      showToast('Airdrop to all bundle wallets completed successfully!', 'success');
    }
  
    const newBalance = await connection.getBalance(mainWallet.publicKey);
    setBalance(newBalance / LAMPORTS_PER_SOL);
    setIsAirdropping(false);
  };

  const airdropToLaunchBundleWallets = async () => {
    setIsAirdroppingLaunch(true);
    const launchBundleWallets = JSON.parse(localStorage.getItem('launchBundleWallets') || '[]');
  
    // Calculate total airdrop amount including fee coverage if enabled
    const multiplier = percentageToMultiplier(launchBundleAirdropPercentage);
    const totalAirdropAmount = launchBundleWallets.reduce((total, wallet) => {
      const baseAmount = wallet.airdropAmount * multiplier;
      const feeCoverAmount = includeLaunchFeeCover ? calculateFeeCoverAmount(wallet.airdropAmount) : 0;
      return total + baseAmount + feeCoverAmount;
    }, 0);
  
    // Check if main wallet has sufficient balance
    const mainWalletBalance = await connection.getBalance(mainWallet.publicKey);
    if (mainWalletBalance < totalAirdropAmount * LAMPORTS_PER_SOL) {
      showToast(`Insufficient balance. Required: ${totalAirdropAmount} SOL, Available: ${mainWalletBalance / LAMPORTS_PER_SOL} SOL`, 'error');
      setIsAirdroppingLaunch(false);
      return;
    }

    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
    const airdropResults = await Promise.all(launchBundleWallets.map(async (wallet) => {
      try {
        const destinationPublicKey = new PublicKey(wallet.publicKey);
        const baseAmount = wallet.airdropAmount * multiplier;
        const feeCoverAmount = includeLaunchFeeCover ? calculateFeeCoverAmount(wallet.airdropAmount) : 0;
        const totalWalletAirdropAmount = baseAmount + feeCoverAmount;
        const airdropAmount = Math.floor(totalWalletAirdropAmount * LAMPORTS_PER_SOL);
  
        const transaction = new Transaction();
      
        transaction.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: computeUnits,
          }),
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: microLamports,
          }),
          SystemProgram.transfer({
            fromPubkey: mainWallet.publicKey,
            toPubkey: destinationPublicKey,
            lamports: airdropAmount,
          })
        );
  
        const signature = await connection.sendTransaction(transaction, [mainWallet]);
        await connection.confirmTransaction(signature, 'confirmed');
  
        console.log(`Airdropped ${totalWalletAirdropAmount} SOL to ${wallet.publicKey}`);
        return true;
      } catch (error) {
        console.error(`Failed to airdrop to ${wallet.publicKey}:`, error);
        return false;
      }
    }));
  
    const successCount = airdropResults.filter(result => result).length;
    const failCount = airdropResults.length - successCount;

    if (successCount > 0) {
      await updateLaunchBundleWalletsBalances();
    }
  
    if (failCount > 0) {
      showToast(`Launch wallet airdrop completed. ${successCount} successful, ${failCount} failed. Check console for details.`, 'warning');
    } else {
      showToast('Airdrop to all launch bundle wallets completed successfully!', 'success');
    }
  
    const newBalance = await connection.getBalance(mainWallet.publicKey);
    setBalance(newBalance / LAMPORTS_PER_SOL);
    setIsAirdroppingLaunch(false);
  };

  const airdropToDevWallet = async () => {
    setIsAirdroppingDev(true);
    
    if (!devPublicKey) {
      showToast("Dev wallet public key is not set.", 'error', 5000);
      setIsAirdroppingDev(false);
      return;
    }
  
    const devWalletPublicKey = new PublicKey(devPublicKey);
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
    try {
      const airdropAmountLamports = parseFloat(airdropAmount) * LAMPORTS_PER_SOL;
      
      const transaction = new Transaction();
    
    // Add compute budget instructions first
    transaction.add(
      ComputeBudgetProgram.setComputeUnitLimit({
        units: computeUnits,
      }),
      ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: microLamports,
      }),
      SystemProgram.transfer({
        fromPubkey: mainWallet.publicKey,
        toPubkey: devWalletPublicKey,
        lamports: airdropAmountLamports,
      })
    );
  
      const signature = await connection.sendTransaction(transaction, [mainWallet]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Airdropped ${airdropAmount} SOL to dev wallet`);
      showToast(`Successfully airdropped ${airdropAmount} SOL to dev wallet`, 'success');
  
      // Update main wallet balance after airdrop
      const newBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newBalance / LAMPORTS_PER_SOL);
  
      // Update dev wallet balance
      const newDevBalance = await connection.getBalance(devWalletPublicKey);
      updateDevWalletBalance(newDevBalance / LAMPORTS_PER_SOL);
    } catch (error) {
      console.error('Error during dev wallet airdrop:', error);
      handleError(error, showToast);
    } finally {
      setIsAirdroppingDev(false);
      setShowAirdropOverlay(false);
      setAirdropAmount('0');
    }
  };

  const airdropToBumpWallet = async () => {
    setIsAirdroppingBump(true);
    const bumpWallet = JSON.parse(localStorage.getItem('bumpWallet'));
    
    if (!bumpWallet) {
      showToast('Bump wallet not found in localStorage', 'error');
      setIsAirdroppingBump(false);
      return;
    }
  
    const bumpWalletPublicKey = new PublicKey(bumpWallet.publicKey);
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
    try {
      const airdropAmountLamports = parseFloat(bumpAirdropAmount) * LAMPORTS_PER_SOL;

      // Create the transaction first
    const transaction = new Transaction();
    
    // Add all instructions in the correct order
    transaction.add(
      ComputeBudgetProgram.setComputeUnitLimit({
        units: computeUnits,
      }),
      ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: microLamports,
      }),
      SystemProgram.transfer({
        fromPubkey: mainWallet.publicKey,
        toPubkey: bumpWalletPublicKey,
        lamports: airdropAmountLamports,
      })
    );
  
      const signature = await connection.sendTransaction(transaction, [mainWallet]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Airdropped ${bumpAirdropAmount} SOL to bump wallet`);
      showToast(`Successfully airdropped ${bumpAirdropAmount} SOL to bump wallet`, 'success');
      
      // Update main wallet balance after airdrop
      const newMainBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newMainBalance / LAMPORTS_PER_SOL);
      
      // Update bump wallet balance
      const newBumpBalance = await connection.getBalance(bumpWalletPublicKey);
      updateBumpWalletBalance(newBumpBalance / LAMPORTS_PER_SOL);
    } catch (error) {
      console.error('Error during bump wallet airdrop:', error);
      handleError(error, showToast);
    } finally {
      setIsAirdroppingBump(false);
      setShowBumpAirdropOverlay(false);
      setBumpAirdropAmount('0');
    }
  };

  const withdrawFromBundleWallets = async () => {
    setIsWithdrawing(true);
    const bundleWallets = JSON.parse(localStorage.getItem('bundleWallets') || '[]');
  
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000); // Ensure integer
    const rentExemptBalance = 5000; // Minimum balance for rent exemption
  
    const withdrawResults = await Promise.all(bundleWallets.map(async (wallet) => {
      try {
        const bundleWalletKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
        const bundleWalletBalance = await connection.getBalance(bundleWalletKeypair.publicKey);
        
        if (bundleWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
          console.log(`Skipping ${wallet.publicKey} - insufficient balance for fees`);
          return true;
        }
  
        // Calculate maximum withdrawal amount and ensure it's an integer
        const withdrawalAmount = Math.floor(bundleWalletBalance - rentExemptBalance - priorityFeeCost);
  
        const transaction = new Transaction();
        
        transaction.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: computeUnits,
          }),
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: microLamports,
          }),
          SystemProgram.transfer({
            fromPubkey: bundleWalletKeypair.publicKey,
            toPubkey: mainWallet.publicKey,
            lamports: withdrawalAmount,
          })
        );
  
        const signature = await connection.sendTransaction(transaction, [bundleWalletKeypair]);
        await connection.confirmTransaction(signature, 'confirmed');
  
        console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from ${wallet.publicKey}`);
        return true;
      } catch (error) {
        console.error(`Failed to withdraw from ${wallet.publicKey}:`, error);
        if (error instanceof SendTransactionError) {
          const logs = await error.getLogs(connection);
          console.log('Transaction logs:', logs);
        }
        return false;
      }
    }));
  
    const successCount = withdrawResults.filter(result => result).length;
    const failCount = withdrawResults.length - successCount;
  
    // After successful withdrawal
    if (successCount > 0) {
      await updateBundleWalletsBalances();
    }
  
    if (failCount > 0) {
      showToast(`Withdrawal completed. ${successCount} successful, ${failCount} failed. Check console for details.`, 'warning');
    } else {
      showToast('Withdrawal from all bundle wallets completed successfully!', 'success');
    }
  
    // Update main wallet balance after withdrawal
    const newBalance = await connection.getBalance(mainWallet.publicKey);
    setBalance(newBalance / LAMPORTS_PER_SOL);
    setIsWithdrawing(false);
  };

  const withdrawFromLaunchBundleWallets = async () => {
    setIsWithdrawingLaunch(true);
    const launchBundleWallets = JSON.parse(localStorage.getItem('launchBundleWallets') || '[]');
  
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000);
    const rentExemptBalance = 5000;
  
    const withdrawResults = await Promise.all(launchBundleWallets.map(async (wallet) => {
      try {
        const bundleWalletKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
        const bundleWalletBalance = await connection.getBalance(bundleWalletKeypair.publicKey);
        
        if (bundleWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
          console.log(`Skipping ${wallet.publicKey} - insufficient balance for fees`);
          return true;
        }
  
        const withdrawalAmount = Math.floor(bundleWalletBalance - rentExemptBalance - priorityFeeCost);
  
        const transaction = new Transaction();
        
        transaction.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: computeUnits,
          }),
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: microLamports,
          }),
          SystemProgram.transfer({
            fromPubkey: bundleWalletKeypair.publicKey,
            toPubkey: mainWallet.publicKey,
            lamports: withdrawalAmount,
          })
        );
  
        const signature = await connection.sendTransaction(transaction, [bundleWalletKeypair]);
        await connection.confirmTransaction(signature, 'confirmed');
  
        console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from ${wallet.publicKey}`);
        return true;
      } catch (error) {
        console.error(`Failed to withdraw from ${wallet.publicKey}:`, error);
        if (error instanceof SendTransactionError) {
          const logs = await error.getLogs(connection);
          console.log('Transaction logs:', logs);
        }
        return false;
      }
    }));
  
    const successCount = withdrawResults.filter(result => result).length;
    const failCount = withdrawResults.length - successCount;
  
    if (successCount > 0) {
      await updateLaunchBundleWalletsBalances();
    }
  
    if (failCount > 0) {
      showToast(`Launch wallet withdrawal completed. ${successCount} successful, ${failCount} failed. Check console for details.`, 'warning');
    } else {
      showToast('Withdrawal from all launch bundle wallets completed successfully!', 'success');
    }
  
    const newBalance = await connection.getBalance(mainWallet.publicKey);
    setBalance(newBalance / LAMPORTS_PER_SOL);
    setIsWithdrawingLaunch(false);
  };

  const withdrawFromDevWallet = async () => {
    setIsWithdrawingDev(true);
    
    const savedKeys = JSON.parse(localStorage.getItem('walletKeys') || '{}');
    const devWalletSecretKey = savedKeys.devWallet;
  
    if (!devWalletSecretKey) {
      showToast('Dev wallet secret key not found in localStorage', 'error');
      setIsWithdrawingDev(false);
      return;
    }
  
    if (!devPublicKey) {
      showToast("Dev wallet public key is not set.", 'error', 5000);
      setIsWithdrawingDev(false);
      return;
    }
  
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000);
    const rentExemptBalance = 5000;
  
    const devWalletPublicKey = new PublicKey(devPublicKey);
  
    try {
      const devWalletKeypair = Keypair.fromSecretKey(bs58.decode(devWalletSecretKey));
      const devWalletBalance = await connection.getBalance(devWalletKeypair.publicKey);
      
      if (devWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
        showToast('Dev wallet has insufficient balance for fees', 'warning');
        return;
      }  
  
      const withdrawalAmount = Math.floor(devWalletBalance - rentExemptBalance - priorityFeeCost);
  
      const transaction = new Transaction();
      
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: computeUnits,
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: microLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: devWalletKeypair.publicKey,
          toPubkey: mainWallet.publicKey,
          lamports: withdrawalAmount,
        })
      );
  
      const signature = await connection.sendTransaction(transaction, [devWalletKeypair]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from dev wallet`);
      showToast('Successfully withdrawn from dev wallet', 'success');
  
      const newBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newBalance / LAMPORTS_PER_SOL);
  
      const newDevBalance = await connection.getBalance(devWalletPublicKey);
      updateDevWalletBalance(newDevBalance / LAMPORTS_PER_SOL);
    } catch (error) {
      console.error('Error during dev wallet withdrawal:', error);
      if (error instanceof SendTransactionError) {
        const logs = await error.getLogs(connection);
        console.log('Transaction logs:', logs);
      }
      handleError(error, showToast);
    } finally {
      setShowAirdropOverlay(false);
      setIsWithdrawingDev(false);
    }
  };

  const withdrawFromBumpWallet = async () => {
    setIsWithdrawingBump(true);
    const bumpWallet = JSON.parse(localStorage.getItem('bumpWallet'));
    
    if (!bumpWallet) {
      showToast('Bump wallet not found in localStorage', 'error');
      setIsWithdrawingBump(false);
      return;
    }
  
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000);
    const rentExemptBalance = 5000;
  
    const bumpWalletPublicKey = new PublicKey(bumpWallet.publicKey);
  
    // Update balance before withdrawal
    const initialBumpBalance = await connection.getBalance(bumpWalletPublicKey);
    updateBumpWalletBalance(initialBumpBalance / LAMPORTS_PER_SOL);
  
    try {
      const bumpWalletKeypair = Keypair.fromSecretKey(bs58.decode(bumpWallet.secretKey));
      const bumpWalletBalance = await connection.getBalance(bumpWalletKeypair.publicKey);
      
      if (bumpWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
        showToast('Bump wallet has insufficient balance for fees', 'warning');
        return;
      }
  
      const withdrawalAmount = Math.floor(bumpWalletBalance - rentExemptBalance - priorityFeeCost);
  
      const transaction = new Transaction();
      
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: computeUnits,
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: microLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: bumpWalletKeypair.publicKey,
          toPubkey: mainWallet.publicKey,
          lamports: withdrawalAmount,
        })
      );
  
      const signature = await connection.sendTransaction(transaction, [bumpWalletKeypair]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from bump wallet`);
      showToast('Successfully withdrawn from bump wallet', 'success');
  
      const newMainBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newMainBalance / LAMPORTS_PER_SOL);
      
      const newBumpBalance = await connection.getBalance(bumpWalletPublicKey);
      updateBumpWalletBalance(newBumpBalance / LAMPORTS_PER_SOL);
    } catch (error) {
      console.error('Error during bump wallet withdrawal:', error);
      if (error instanceof SendTransactionError) {
        const logs = await error.getLogs(connection);
        console.log('Transaction logs:', logs);
      }
      handleError(error, showToast);
    } finally {
      setShowBumpAirdropOverlay(false);
      setIsWithdrawingBump(false);
    }
  };

  const withdrawFromAllWallets = async () => {
    setIsWithdrawingAll(true);
    try {
      // Withdraw from bundle wallets
      await withdrawFromBundleWallets();
      
      // Withdraw from launch bundle wallets
      await withdrawFromLaunchBundleWallets();
      
      // Withdraw from dev wallet
      await withdrawFromDevWallet();
      
      // Withdraw from bump wallet
      await withdrawFromBumpWallet();

      showToast('Successfully withdrawn from all wallets', 'success');
    } catch (error) {
      console.error('Error during withdrawal from all wallets:', error);
      handleError(error, showToast);
    } finally {
      setIsWithdrawingAll(false);
    }
  };

  const withdrawFromSingleWallet = async (wallet) => {
    
    setWithdrawingWallets(prev => ({ ...prev, [wallet.publicKey]: true }));
    
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000);
    const rentExemptBalance = 5000;
  
    try {
      const bundleWalletKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
      const bundleWalletBalance = await connection.getBalance(bundleWalletKeypair.publicKey);
      
      if (bundleWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
        showToast(`Insufficient balance for fees in ${truncateWalletAddress(wallet.publicKey)}`, 'warning');
        setWithdrawingWallets(prev => ({ ...prev, [wallet.publicKey]: false }));
        return;
      }
  
      const withdrawalAmount = Math.floor(bundleWalletBalance - rentExemptBalance - priorityFeeCost);
  
      const transaction = new Transaction();
      
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: computeUnits,
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: microLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: bundleWalletKeypair.publicKey,
          toPubkey: mainWallet.publicKey,
          lamports: withdrawalAmount,
        })
      );
  
      const signature = await connection.sendTransaction(transaction, [bundleWalletKeypair]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from ${wallet.publicKey}`);
      showToast(`Successfully withdrawn from ${truncateWalletAddress(wallet.publicKey)}`, 'success');
  
      // Update balances
      const newMainBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newMainBalance / LAMPORTS_PER_SOL);
      await updateBundleWalletsBalances();
  
    } catch (error) {
      console.error(`Failed to withdraw from ${wallet.publicKey}:`, error);
      if (error instanceof SendTransactionError) {
        const logs = await error.getLogs(connection);
        console.log('Transaction logs:', logs);
      }
      handleError(error, showToast);
    } finally {
      setWithdrawingWallets(prev => ({ ...prev, [wallet.publicKey]: false }));
    }
  };

  const withdrawFromSingleLaunchWallet = async (wallet) => {
    
    setWithdrawingLaunchWallets(prev => ({ ...prev, [wallet.publicKey]: true }));
    
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000);
    const rentExemptBalance = 5000;
  
    try {
      const bundleWalletKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
      const bundleWalletBalance = await connection.getBalance(bundleWalletKeypair.publicKey);
      
      if (bundleWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
        showToast(`Insufficient balance for fees in ${truncateWalletAddress(wallet.publicKey)}`, 'warning');
        setWithdrawingLaunchWallets(prev => ({ ...prev, [wallet.publicKey]: false }));
        return;
      }
  
      const withdrawalAmount = Math.floor(bundleWalletBalance - rentExemptBalance - priorityFeeCost);
  
      const transaction = new Transaction();
      
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: computeUnits,
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: microLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: bundleWalletKeypair.publicKey,
          toPubkey: mainWallet.publicKey,
          lamports: withdrawalAmount,
        })
      );
  
      const signature = await connection.sendTransaction(transaction, [bundleWalletKeypair]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from ${wallet.publicKey}`);
      showToast(`Successfully withdrawn from ${truncateWalletAddress(wallet.publicKey)}`, 'success');
  
      // Update balances
      const newMainBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newMainBalance / LAMPORTS_PER_SOL);
      await updateLaunchBundleWalletsBalances();
  
    } catch (error) {
      console.error(`Failed to withdraw from ${wallet.publicKey}:`, error);
      if (error instanceof SendTransactionError) {
        const logs = await error.getLogs(connection);
        console.log('Transaction logs:', logs);
      }
      handleError(error, showToast);
    } finally {
      setWithdrawingLaunchWallets(prev => ({ ...prev, [wallet.publicKey]: false }));
    }
  };

  const percentageToMultiplier = (percentage) => {
    return 1 + (parseFloat(percentage) / 100);
  };

  const truncateWalletAddress = (address) => {
    if (address.length <= 8) return address;
    return `${address.slice(0, 3)}...${address.slice(-4)}`;
  };

  const groupWallets = (wallets, size) => {
    return wallets.reduce((acc, wallet, index) => {
      const groupIndex = Math.floor(index / size);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(wallet);
      return acc;
    }, []);
  };

  const handleManualAirdrop = async (wallet) => {
    setSelectedWallet(wallet);
    setShowManualAirdropModal(true);
    
    // Fetch the current balance of the selected wallet
    try {
      const balance = await connection.getBalance(new PublicKey(wallet.publicKey));
      setSelectedWalletBalance(balance / LAMPORTS_PER_SOL);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      setSelectedWalletBalance(null);
    }
  };
  
  const performManualAirdrop = async () => {
    if (!selectedWallet || !manualAirdropAmount) return;
  
    const destinationPublicKey = new PublicKey(selectedWallet.publicKey);
    const airdropAmountLamports = parseFloat(manualAirdropAmount) * LAMPORTS_PER_SOL;
    
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    
    setIsManualAirdropping(true);
  
    try {
      const transaction = new Transaction();
      
      // Add compute budget instructions first
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: computeUnits,
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: microLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: mainWallet.publicKey,
          toPubkey: destinationPublicKey,
          lamports: airdropAmountLamports,
        })
      );
  
      const signature = await connection.sendTransaction(transaction, [mainWallet]);
      await connection.confirmTransaction(signature, 'confirmed');
  
      console.log(`Manually airdropped ${manualAirdropAmount} SOL to ${selectedWallet.publicKey}`);
      showToast(`Successfully airdropped ${manualAirdropAmount} SOL to wallet`, 'success');
  
      // Update balances
      const newMainBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(newMainBalance / LAMPORTS_PER_SOL);
      
      // Update the selected wallet's balance
      const newSelectedWalletBalance = await connection.getBalance(destinationPublicKey);
      setSelectedWalletBalance(newSelectedWalletBalance / LAMPORTS_PER_SOL);
  
      await updateBundleWalletsBalances();
      await updateLaunchBundleWalletsBalances();
  
      setManualAirdropAmount('');
    } catch (error) {
      console.error('Error during manual airdrop:', error);
      handleError(error, showToast);
    } finally {
      setIsManualAirdropping(false);
    }
  };

  const refreshMainBalance = async () => {
    
    if (!connection) {
      showToast('No connection available, please check your RPC URL', 'error');
      return;
    }

    if (!mainWallet) return;
    
    setRefreshingBalance(true);
    try {
      const solBalance = await connection.getBalance(mainWallet.publicKey);
      setBalance(solBalance / LAMPORTS_PER_SOL);
      showToast('Balance updated successfully!', 'success', 2000);
    } catch (error) {
      console.error('Error refreshing balance:', error);
      showToast('Failed to refresh balance', 'error');
    } finally {
      setTimeout(() => {
        setRefreshingBalance(false);
      }, 1000);
    }
  };

  const calculateFeeCoverAmount = (airdropAmount) => {
    return (jitoTip * 2) + (airdropAmount * (0.03 + (slippagePercent / 100))) + 0.005;
  };

  const calculateTotalExtra = (wallet, isLaunchBundle = false) => {
    const percentage = isLaunchBundle ? launchBundleAirdropPercentage : bundleAirdropPercentage;
    const includeFeeCover = isLaunchBundle ? includeLaunchFeeCover : includeBundleFeeCover;
    
    const extraFromPercentage = wallet.airdropAmount * (percentageToMultiplier(percentage) - 1);
    const feeCoverAmount = includeFeeCover ? calculateFeeCoverAmount(wallet.airdropAmount) : 0;
    
    return extraFromPercentage + feeCoverAmount;
  };

  return (
    <div className="main-wallet-panel">
      <h1>Main</h1>
      <div className="info-section">
        <p>
          <span className="label">Wallet:</span>
          <span className="value">
            <span
              className="truncated-address"
              onClick={() => handleCopyToClipboard(publicKey)}
              title={publicKey}
            >
              {truncateAddress(publicKey)}
            </span>
            <i className="fas fa-copy copy-icon"></i>
          </span>
        </p>
        <div className="main-balance-refresh">
        <div className='main-balance'>
          <span className="sol-balance">
            <span className="label">Balance:</span>
            <span className="value">{balance !== null ? `${balance.toFixed(3)} SOL` : '0.000 SOL'}</span>
          </span>
        </div>
        <div className="refresh-balance">
            <button
              className="refresh-button"
              onClick={refreshMainBalance}
              disabled={refreshingBalance}
            >
              <FontAwesomeIcon 
                icon={faRotate} 
                spin={refreshingBalance}
                className="refresh-icon"
              />
            </button>
          </div>
          </div>
      </div>
      <p className="main-actions-title"><FontAwesomeIcon icon={faCoins}/> Manage funds</p>
      <div className="airdrop-section">
      <button 
      onClick={() => {
        setShowBundleAirdropOverlay(true);
        setIncludeBundleFeeCover(true); // Enable fee coverage when opening
      }} 
      disabled={isAirdropping || isAirdroppingLaunch}
      className="airdrop-button"
      >
      Bundle
    </button>
    <button 
        onClick={() => {
            setShowLaunchBundleAirdropOverlay(true);
          setIncludeLaunchFeeCover(true); // Enable fee coverage when opening
          }} 
          disabled={isAirdropping || isAirdroppingLaunch}
        className="airdrop-button"
      >
        Launch Bundle
      </button>
      <button 
          onClick={() => setShowAirdropOverlay(true)} 
          disabled={isAirdropping || isAirdroppingLaunch || isAirdroppingDev}
          className="airdrop-button"
        >
          Dev
        </button>
        <button 
          onClick={() => setShowBumpAirdropOverlay(true)} 
          disabled={isAirdropping || isAirdroppingLaunch || isAirdroppingDev || isAirdroppingBump}
          className="airdrop-button"
        >
          Bump
        </button>
      </div>
      <div className="withdraw-section">
        <button 
          onClick={withdrawFromAllWallets} 
          disabled={isAirdropping || isWithdrawing || isAirdroppingLaunch || isWithdrawingLaunch || 
                    isWithdrawingDev || isWithdrawingBump || isWithdrawingAll}
          className="withdraw-button withdraw-all-button"
        >
          {isWithdrawingAll ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Reclaim All'}
        </button>  
      </div>
      <div className="priority-fee-section">
        <div className="main-actions-title">
          <FontAwesomeIcon icon={faBoltLightning} />
        <span>Priority Fee (SOL):</span>
        </div>
        <input
          type="number"
          value={priorityFee}
          onChange={handlePriorityFeeChange}
          min="0"
          step="0.0001"
          className="priority-fee-input"
        />
        <label>(for airdrops and withdrawals only)</label>
      </div>

      {showBundleAirdropOverlay && (
        <div className="airdrop-overlay">
          <h1>Bundle Wallets</h1>
          <div className="wallet-list">
            {bundleWallets.length === 0 ? (
              <div className="no-wallets-message">
                No wallets found
              </div>
            ) : (
              groupWallets(bundleWallets, 5).map((group, groupIndex) => (
                <div key={groupIndex} className="wallet-group">
                  <h1>Bundle {groupIndex + 1}</h1>
                  {group.map((wallet, index) => (
                    <div key={index} className="wallet-item">
                      <div className="wallet-info">
                      <span className="wallet-info-label">
                        <p>Wallet:</p>
                        <p className="balance-value">{truncateWalletAddress(wallet.publicKey)}</p>
                      </span>
                      <span className="wallet-info-label">
                        <p>Balance:</p>
                        <p className="balance-value">{wallet.balance !== undefined ? `${wallet.balance.toFixed(3)} SOL` : 'Loading...'}</p>
                      </span>
                      <span className="wallet-info-label">
                        <p>Airdrop:</p>
                        <p className="balance-value">{wallet.airdropAmount.toFixed(3)} SOL</p>
                      </span>
                      <span className="wallet-info-label">
                        <p>Extra:</p>
                        <p className="balance-value">+{calculateTotalExtra(wallet).toFixed(3)} SOL</p>
                      </span>
                      </div>
                      <div className="manual-manage-funds">
                      <div 
                        className="manual-airdrop"
                        onClick={() => handleManualAirdrop(wallet)}
                      >
                        <FontAwesomeIcon icon={faPaperPlane}/>
                      </div>
                      <div 
                        className="manual-withdraw"
                        onClick={() => withdrawFromSingleWallet(wallet)}
                      >
                        {withdrawingWallets[wallet.publicKey] ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          <FontAwesomeIcon icon={faWallet}/>
                        )}
                      </div>
                      </div>
                    </div>
                  ))}
                </div>
              )))}
            </div>
          <div className="airdrop-modal">
          <div className="fee-cover-checkbox">
              <input
                type="checkbox"
                checked={includeBundleFeeCover}
                onChange={(e) => setIncludeBundleFeeCover(e.target.checked)}
                id="bundleFeeCover"
              />
              <label htmlFor="bundleFeeCover">Include extra balance for slippage and fees</label>
            </div>
            <label>Add extra balance (e.g. 50 for 50% more):</label>
            <input
              type="number"
              value={bundleAirdropPercentage}
              onChange={(e) => setBundleAirdropPercentage(Math.max(0, Number(e.target.value)))}
              min="0"
            />
            <div className="airdrop-module-balances">
              <span className="airdrop-module-balances-info">
                <p className="airdrop-module-balances-label">Available:</p>
                <p className="airdrop-module-balances-value">{balance !== null ? balance.toFixed(3) : '0.000'} SOL</p>
              </span>
              <span className="airdrop-module-balances-info">
                <p className="airdrop-module-balances-label">Total Airdrop:</p>
                <p className="airdrop-module-balances-value">{totalBundleAirdropAmount.toFixed(3)} SOL</p>
              </span>
            </div>
            <div className="airdrop-modal-buttons">
              <button onClick={airdropToBundleWallets} disabled={isAirdropping}>
                {isAirdropping ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                  <>
                    <FontAwesomeIcon icon={faPaperPlane} /> Airdrop
                  </>
                )}
              </button>
              <button onClick={withdrawFromBundleWallets} disabled={isAirdropping || isWithdrawing}>
                {isWithdrawing ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faWallet} /> Withdraw
                  </>
                )}
              </button>
            </div>
          </div>
          <button 
            className="close-button" 
            onClick={() => {
              setShowBundleAirdropOverlay(false);
              setIncludeBundleFeeCover(false); // Disable fee coverage when closing
            }}
          >
              <FontAwesomeIcon icon={faTimes}/>
          </button>
        </div>
      )}

      {showLaunchBundleAirdropOverlay && (
        <div className="airdrop-overlay">
          <h1>Launch Bundle Wallets</h1>
          <div className="launch-bundle-wallet-list">
            {launchBundleWallets.length === 0 ? (
              <div className="no-wallets-message">
                No wallets found
              </div>
            ) : (
              launchBundleWallets.map((wallet, index) => (
              <div key={index} className="launch-bundle-wallet-item">
                <h1>Wallet {index + 1}</h1>
                <div className="launch-bundle-wallet-wrapper">
                <div className="launch-bundle-wallet-info">
                  <span className="wallet-info-label">
                    <p>Wallet:</p>
                    <p className="balance-value">{truncateWalletAddress(wallet.publicKey)}</p>
                  </span>
                  <span className="wallet-info-label">
                    <p>Balance:</p>
                    <p className="balance-value">{wallet.balance !== undefined ? `${wallet.balance.toFixed(3)} SOL` : 'Loading...'}</p>
                  </span>
                  <span className="wallet-info-label">
                    <p>Airdrop:</p>
                    <p className="balance-value">{wallet.airdropAmount.toFixed(3)} SOL</p>
                  </span>
                  <span className="wallet-info-label">
                    <p>Extra:</p>
                    <p className="balance-value">+{calculateTotalExtra(wallet, true).toFixed(3)} SOL</p>
                  </span>
                </div>
                <div className="manual-manage-funds">
                  <div 
                    className="manual-airdrop"
                    onClick={() => handleManualAirdrop(wallet)}
                  >
                    <FontAwesomeIcon icon={faPaperPlane}/>
                  </div>
                  <div 
                    className="manual-withdraw"
                    onClick={() => withdrawFromSingleLaunchWallet(wallet)}
                  >
                    {withdrawingLaunchWallets[wallet.publicKey] ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <FontAwesomeIcon icon={faWallet}/>
                    )}
                  </div>
                </div>
                </div>
              </div>
            )))}
          </div>
          <div className="airdrop-modal">
          <div className="fee-cover-checkbox">
              <input
                type="checkbox"
                checked={includeLaunchFeeCover}
                onChange={(e) => setIncludeLaunchFeeCover(e.target.checked)}
                id="launchFeeCover"
              />
              <label htmlFor="launchFeeCover">Include extra balance for slippage and fees</label>
            </div>
            <label>Add extra balance (e.g. 50 for 50% more):</label>
            <input
              type="number"
              value={launchBundleAirdropPercentage}
              onChange={(e) => setLaunchBundleAirdropPercentage(Math.max(0, Number(e.target.value)))}
              min="0"
            />
            <div className="airdrop-module-balances">
              <span className="airdrop-module-balances-info">
                <p className="airdrop-module-balances-label">Available:</p>
                <p className="airdrop-module-balances-value">{balance !== null ? balance.toFixed(3) : '0.000'} SOL</p>
              </span>
              <span className="airdrop-module-balances-info">
                <p className="airdrop-module-balances-label">Total Airdrop:</p>
                <p className="airdrop-module-balances-value">{totalLaunchBundleAirdropAmount.toFixed(3)} SOL</p>
              </span>
            </div>
            <div className="airdrop-modal-buttons">
              <button onClick={airdropToLaunchBundleWallets} disabled={isAirdroppingLaunch}>
                {isAirdroppingLaunch ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                  <FontAwesomeIcon icon={faPaperPlane} /> Airdrop
                  </>
                )}
              </button>
              <button onClick={withdrawFromLaunchBundleWallets} disabled={isAirdroppingLaunch || isWithdrawingLaunch}>
                {isWithdrawingLaunch ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faWallet} /> Withdraw
                  </>
                )}
              </button>
            </div>
          </div>
          <button 
            className="close-button" 
            onClick={() => {
              setShowLaunchBundleAirdropOverlay(false);
                setIncludeLaunchFeeCover(false); // Disable fee coverage when closing
            }}
          >
              <FontAwesomeIcon icon={faTimes}/>
              </button> 
        </div>
      )}

      {showManualAirdropModal && selectedWallet && (
        <div className="airdrop-overlay">
          <div className="airdrop-modal">
            <h1>Manual Airdrop</h1>
            <div className="manual-airdrop-info">
            <span className="wallet-info-label">
            <p>Wallet:</p>
            <p className="balance-value">{truncateWalletAddress(selectedWallet.publicKey)}</p>
            </span>
            <div className="balance-divider"></div>
            <span className="wallet-info-label">
            <p>Balance:</p>
            <p className="balance-value">{selectedWalletBalance !== null ? `${selectedWalletBalance.toFixed(3)} SOL` : 'Loading...'}</p>
            </span>
            </div>
            <label>Airdrop amount (SOL):</label>
            <input
              type="number"
              value={manualAirdropAmount}
              onChange={(e) => setManualAirdropAmount(Math.max(0, Number(e.target.value)))}
              min="0"
            />
            <div className="manual-airdrop-module-balances">
              <span className="airdrop-module-balances-info">
                <p className="airdrop-module-balances-label">Available:</p>
                <p className="airdrop-module-balances-value">{balance !== null ? balance.toFixed(3) : '0.000'} SOL</p>
              </span>
            </div>
            <div className="airdrop-manual-buttons">
            <button onClick={performManualAirdrop} disabled={isManualAirdropping}>
              {isManualAirdropping ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>
                <FontAwesomeIcon icon={faPaperPlane} /> Airdrop
                </>
              )}
            </button>
              <button onClick={() => setShowManualAirdropModal(false)}>Back</button>
            </div>
          </div>
        </div>
      )}

      {showAirdropOverlay && (
        <div className="airdrop-overlay">
          <h1>Dev Wallet</h1>
          <div className="airdrop-modal">
            <label>Airdrop amount (SOL):</label>
            <input
              type="number"
              value={airdropAmount}
              onChange={(e) => setAirdropAmount(Math.max(0, Number(e.target.value)))}
              min="0"
            />
            <div className="airdrop-modal-buttons">
            <button onClick={airdropToDevWallet} disabled={isAirdroppingDev}>
              {isAirdroppingDev ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>
                  <FontAwesomeIcon icon={faPaperPlane} /> Airdrop
                </>
              )}
            </button>
            <button onClick={withdrawFromDevWallet} disabled={isAirdroppingDev || isWithdrawingDev}>
              {isWithdrawingDev ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>
                  <FontAwesomeIcon icon={faWallet} /> Withdraw
                </>
              )}
            </button>
            </div>
          </div>
          <button className="close-button" onClick={() => setShowAirdropOverlay(false)}>
          <FontAwesomeIcon icon={faTimes}/>
          </button>
        </div>
      )}

      {showBumpAirdropOverlay && (
        <div className="airdrop-overlay">
          <h1>Bump Wallet</h1>
          <div className="airdrop-modal">
            <label>Airdrop amount (SOL):</label>
            <input
              type="number"
              value={bumpAirdropAmount}
              onChange={(e) => setBumpAirdropAmount(Math.max(0, Number(e.target.value)))}
              min="0"
            />
            <div className="airdrop-modal-buttons">
            <button onClick={airdropToBumpWallet} disabled={isAirdroppingBump}>
              {isAirdroppingBump ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>
                <FontAwesomeIcon icon={faPaperPlane} /> Airdrop
                </>
              )}
            </button>
            <button onClick={withdrawFromBumpWallet} disabled={isAirdroppingBump || isWithdrawingBump}>
              {isWithdrawingBump ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>
                  <FontAwesomeIcon icon={faWallet} /> Withdraw
                </>
              )}
            </button>
            </div>
          </div>
          <button className="close-button" onClick={() => setShowBumpAirdropOverlay(false)}>
          <FontAwesomeIcon icon={faTimes}/>
          </button>
        </div>
      )}

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={clearToast}
        />
      )}

    </div>
  );
};

export default MainWalletPanel;