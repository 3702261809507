// src/utils/formatUtils.js

export const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 21)}...${address.slice(-4)}`;
  };
  
  export const copyToClipboard = (text, onSuccess, onError) => {
    navigator.clipboard.writeText(text).then(() => {
      if (onSuccess) onSuccess('Address copied to clipboard!');
    }).catch((error) => {
      console.error('Failed to copy address:', error);
      if (onError) onError('Failed to copy address');
    });
  };