import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSave, faTimes, faCog, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { Keypair } from '@solana/web3.js';
import bs58 from 'bs58';
import './UserSettings.css';

const UserSettings = () => {
  const [walletKeys, setWalletKeys] = useState({
    mainWallet: '',
    devWallet: '',
    relayWallet: ''
  });

  const [showSecrets, setShowSecrets] = useState({
    mainWallet: false,
    devWallet: false,
    relayWallet: false,
    captchaKey: false
  });

  const [captchaApiKey, setCaptchaApiKey] = useState('');
  const [rpcUrl, setRpcUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const savedKeys = localStorage.getItem('walletKeys');
    if (savedKeys) {
      setWalletKeys(JSON.parse(savedKeys));
    }

    const savedCaptchaKey = localStorage.getItem('TWOCAPTCHA_API_KEY');
    if (savedCaptchaKey) {
      setCaptchaApiKey(savedCaptchaKey);
    }

    const savedRpcUrl = localStorage.getItem('RPC_URL');
    if (savedRpcUrl) {
      setRpcUrl(savedRpcUrl);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'captchaKey') {
      setCaptchaApiKey(value);
    } else if (name === 'rpcUrl') {
      setRpcUrl(value);
    } else {
      setWalletKeys(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const generateWallet = (walletType) => {
    const newKeypair = Keypair.generate();
    const secretKey = bs58.encode(newKeypair.secretKey);
    
    setWalletKeys(prev => ({
      ...prev,
      [walletType]: secretKey
    }));
    
    // Show the generated wallet
    setShowSecrets(prev => ({
      ...prev,
      [walletType]: true
    }));
  };

  const toggleSecret = (field) => {
    setShowSecrets(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const saveSettings = () => {
    localStorage.setItem('walletKeys', JSON.stringify(walletKeys));
    localStorage.setItem('TWOCAPTCHA_API_KEY', captchaApiKey);
    localStorage.setItem('RPC_URL', rpcUrl);
    window.location.reload();
  };

  return (
    <div className="settings-container">
      <button 
        onClick={() => setIsOpen(true)}
        className="settings-button"
      >
        <FontAwesomeIcon icon={faCog} />
      </button>

      {isOpen && (
        <div className="overlay">
          <h1>User Settings</h1>
          <div className="overlay-content">
            <button 
              onClick={() => setIsOpen(false)}
              className="close-button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <div className="input-group">
              <label>
                RPC URL
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="rpcUrl"
                  value={rpcUrl}
                  onChange={handleInputChange}
                  placeholder="Enter custom RPC URL"
                />
              </div>
            </div>

            <div className="input-group">
              <label>
                2captcha.com API Key (for comment bot)
              </label>
              <div className="input-wrapper">
                <input
                  type={showSecrets.captchaKey ? "text" : "password"}
                  name="captchaKey"
                  value={captchaApiKey}
                  onChange={handleInputChange}
                  placeholder="Enter 2captcha API key"
                />
                <button
                  type="button"
                  onClick={() => toggleSecret('captchaKey')}
                  className="toggle-visibility"
                >
                  <FontAwesomeIcon icon={showSecrets.captchaKey ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            <div className="input-group">
            <div className="generate-label-user">
              <label>
                Main Wallet Secret Key
              </label>
              <button
                  type="button"
                  onClick={() => generateWallet('mainWallet')}
                  className="generate-new-wallet"
                  title="Generate new wallet"
                >
                  [Generate]
                </button>
                </div>
              <div className="input-wrapper">
                <input
                  type={showSecrets.mainWallet ? "text" : "password"}
                  name="mainWallet"
                  value={walletKeys.mainWallet}
                  onChange={handleInputChange}
                  placeholder="Enter main wallet secret key"
                />
                <button
                  type="button"
                  onClick={() => toggleSecret('mainWallet')}
                  className="toggle-visibility"
                >
                  <FontAwesomeIcon icon={showSecrets.mainWallet ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            <div className="input-group">
            <div className="generate-label-user">
              <label>
                Dev Wallet Secret Key
              </label>
              <button
                  type="button"
                  onClick={() => generateWallet('devWallet')}
                  className="generate-new-wallet"
                  title="Generate new wallet"
                >
                  [Generate]
                </button>
                </div>
              <div className="input-wrapper">
                <input
                  type={showSecrets.devWallet ? "text" : "password"}
                  name="devWallet"
                  value={walletKeys.devWallet}
                  onChange={handleInputChange}
                  placeholder="Enter dev wallet secret key"
                />
                <button
                  type="button"
                  onClick={() => toggleSecret('devWallet')}
                  className="toggle-visibility"
                >
                  <FontAwesomeIcon icon={showSecrets.devWallet ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            <div className="input-group">
              <div className="generate-label-user">
              <label>
                Relay Wallet Secret Key
              </label>
              <button
                  type="button"
                  onClick={() => generateWallet('relayWallet')}
                  className="generate-new-wallet"
                  title="Generate new wallet"
                >
                  [Generate]
                </button>
                </div>
              <div className="input-wrapper">
                <input
                  type={showSecrets.relayWallet ? "text" : "password"}
                  name="relayWallet"
                  value={walletKeys.relayWallet}
                  onChange={handleInputChange}
                  placeholder="Enter relay wallet secret key"
                />
                <button
                  type="button"
                  onClick={() => toggleSecret('relayWallet')}
                  className="toggle-visibility"
                >
                  <FontAwesomeIcon icon={showSecrets.relayWallet ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            <div className="settings-action-buttons">
              <button
                onClick={saveSettings}
                className="settings-save-button"
              >
                <FontAwesomeIcon icon={faSave} />
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSettings;