import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Keypair, VersionedTransaction, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import bs58 from 'bs58';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlay, faStop, faRotate } from '@fortawesome/free-solid-svg-icons';
import { truncateAddress, copyToClipboard } from '../utils/formatUtils';
import Toast from './Toast';
import './BumpBot.css';

const JITO_API_URL = process.env.REACT_APP_JITO_API_URL || 'https://mainnet.block-engine.jito.wtf/api/v1/bundles';

const BumpBot = ({ connection, slippagePercent, mintAddress, bumpWalletBalance, pool }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [isBuyTransaction, setIsBuyTransaction] = useState(true);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [refreshingBalance, setRefreshingBalance] = useState(false);
  const lastTransactionTime = useRef(0);
  const [toast, setToast] = useState(null);
  const [buyAmount, setBuyAmount] = useState(0.011);
  const [priorityFee, setPriorityFee] = useState(0.000025);
  const [bumpInterval, setBumpInterval] = useState(5);

  useEffect(() => {
    const loadWallet = async () => {
      const storedWallet = localStorage.getItem('bumpWallet');
      if (storedWallet) {
        const parsedWallet = JSON.parse(storedWallet);
        const balance = await fetchBalance(parsedWallet.publicKey);
        const tokenBal = await fetchTokenBalance(parsedWallet.publicKey);
        setWallet({ ...parsedWallet, balance });
        setTokenBalance(tokenBal);
      } else {
        const newWallet = Keypair.generate();
        const walletData = {
          publicKey: newWallet.publicKey.toBase58(),
          secretKey: bs58.encode(newWallet.secretKey),
          balance: 0
        };
        localStorage.setItem('bumpWallet', JSON.stringify(walletData));
        setWallet(walletData);
      }
    };
    loadWallet();
  }, []);

  useEffect(() => {
    if (wallet) {
      setWallet(prev => ({ ...prev, balance: bumpWalletBalance }));
    }
  }, [bumpWalletBalance]);

  useEffect(() => {
    const updateTokenBalance = async () => {
      if (wallet && mintAddress) {
        const tokenBal = await fetchTokenBalance(wallet.publicKey);
        setTokenBalance(tokenBal);
      } else {
        // Reset token balance when mintAddress is empty
        setTokenBalance(0);
      }
    };
    updateTokenBalance();
  }, [mintAddress, wallet]);

  const fetchBalance = async (publicKey) => {
    try {
      const balance = await connection.getBalance(new PublicKey(publicKey));
      return balance / LAMPORTS_PER_SOL;
    } catch (error) {
      console.error('Error fetching balance:', error);
      return 0;
    }
  };

  const fetchTokenBalance = async (publicKey) => {
    if (!mintAddress) return 0;
    
    try {
      // First get token decimals from mint info
      const mintInfo = await connection.getParsedAccountInfo(new PublicKey(mintAddress));
      if (!mintInfo.value?.data?.parsed) {
        console.debug('Unable to parse mint info');
        return 0;
      }
      const tokenDecimals = mintInfo.value.data.parsed.info.decimals;
      
      // Then get token account balance
      const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(mintAddress),
        new PublicKey(publicKey)
      );
      
      try {
        const accountInfo = await getAccount(connection, tokenAccount);
        return Number(accountInfo.amount) / Math.pow(10, tokenDecimals);
      } catch (error) {
        // Better error handling
        if (
          error.name === 'TokenAccountNotFoundError' || 
          error.message?.includes('TokenAccountNotFound') ||
          error.message?.includes('Account does not exist') ||
          error.message?.includes('Failed to find account')
        ) {
          console.log('No token account found yet for this wallet');
          return 0;
        }
        console.debug('Error fetching token account:', error);
        return 0;
      }
    } catch (error) {
      console.debug('Error getting token info:', {
        error: error.message,
        publicKey,
        mintAddress
      });
      return 0;
    }
  };

  const updateBalances = useCallback(async () => {
    if (wallet) {
      const solBalance = await fetchBalance(wallet.publicKey);
      const tokenBal = await fetchTokenBalance(wallet.publicKey);
      setWallet(prev => ({ ...prev, balance: solBalance }));
      setTokenBalance(tokenBal);
    }
  }, [wallet, mintAddress]);

  const showToast = (message, type = 'info', duration = 3000) => {
    setToast({ message, type, duration });
  };

  const clearToast = () => {
    setToast(null);
  };

  const handleCopyToClipboard = (text, label) => {
    copyToClipboard(
      text,
      () => showToast(`${label} copied to clipboard!`, 'success', 3000),
      (message) => showToast(message, 'error', 3000)
    );
  };

  const sendTransactions = useCallback(async () => {
    if (!wallet) return;

    const now = Date.now();
    const timeSinceLastTransaction = now - lastTransactionTime.current;
    
    if (timeSinceLastTransaction < bumpInterval * 1000) {
      console.log(`Waiting ${bumpInterval * 1000 - timeSinceLastTransaction}ms before next transaction`);
      return;
    }

    const bundledTxArgs = [
      {
        publicKey: wallet.publicKey,
        action: isBuyTransaction ? "buy" : "sell",
        mint: mintAddress,
        denominatedInSol: isBuyTransaction ? "true" : "false",
        amount: isBuyTransaction ? buyAmount : "99.99%",
        slippage: slippagePercent,
        priorityFee: priorityFee,
        pool: pool
      }
    ];

    try {
      const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bundledTxArgs)
      });

      if (response.status === 200) {
        const transactions = await response.json();
        let encodedSignedTransactions = [];
        let signatures = [];

        for (let i = 0; i < bundledTxArgs.length; i++) {
          const tx = VersionedTransaction.deserialize(new Uint8Array(bs58.decode(transactions[i])));
          const signerKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
          tx.sign([signerKeypair]);
          encodedSignedTransactions.push(bs58.encode(tx.serialize()));
          signatures.push(bs58.encode(tx.signatures[0]));
        }

        const jitoResponse = await fetch(JITO_API_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [encodedSignedTransactions]
          })
        });

        console.log(`Jito Response:`, jitoResponse);
        
        signatures.forEach((sig, i) => {
          console.log(`${isBuyTransaction ? 'Buy' : 'Sell'} Transaction: https://solscan.io/tx/${sig}`);
        });

        setIsBuyTransaction(prev => !prev);
        lastTransactionTime.current = Date.now();

        // Update balances after transaction
        await updateBalances();
      } else {
        console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  }, [wallet, isBuyTransaction, buyAmount, priorityFee, bumpInterval, slippagePercent, mintAddress, updateBalances]);

  useEffect(() => {
    let interval;
    if (isRunning && wallet) {
      sendTransactions();
      interval = setInterval(sendTransactions, bumpInterval * 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, wallet, sendTransactions, bumpInterval]);

  const toggleBot = () => {
    setIsRunning(prev => !prev);
    if (!isRunning) {
      setIsBuyTransaction(true);
      lastTransactionTime.current = 0;
    }
  };

  const refreshBalances = async () => {

    if (!connection) {
      showToast('No connection available, please check your RPC URL', 'error');
      return;
    }
    
    if (!wallet) return;
    
    setRefreshingBalance(true);
    try {
      // Fetch SOL balance
      const solBalance = await fetchBalance(wallet.publicKey);
      setWallet(prev => ({ ...prev, balance: solBalance }));
      
      // Fetch token balance
      const tokenBal = await fetchTokenBalance(wallet.publicKey);
      setTokenBalance(tokenBal);
      
      showToast('Balances updated successfully!', 'success', 2000);
    } catch (error) {
      console.error('Error refreshing balances:', error);
      showToast('Failed to refresh balances', 'error');
    } finally {
      setTimeout(() => {
        setRefreshingBalance(false);
      }, 1000);
    }
  };

  const formatTokenBalance = (balance) => {
    if (!balance || balance === 0) return '0';
    
    if (balance >= 1_000_000) {
      return `${(balance / 1_000_000).toFixed(2)}M`;
    }
    
    if (balance >= 1_000) {
      return `${(balance / 1_000).toFixed(2)}k`;
    }
    
    return Math.floor(balance).toString();
  };

  return (
    <div className="bump-bot">
      <h1>Bump Bot</h1>
      <div className="wallet-info">
        <div className="info-section">
          <p>
            <span className="label">Wallet:</span>
            <span className="value">
              <span
                className="truncated-address"
                onClick={() => handleCopyToClipboard(wallet?.publicKey, 'Public Key')}
                title={wallet?.publicKey}
              >
                {truncateAddress(wallet?.publicKey)}
              </span>
              <FontAwesomeIcon icon={faCopy} className="copy-icon" />
            </span>
          </p>
          <div className="bump-balance-refresh">
          <div className="bump-balance">
          <span className="sol-balance">
            <span className="label">Balance:</span>
            <span className="value">{wallet?.balance.toFixed(3)} SOL</span>
          </span>
          <div className='balance-divider'></div>
          <span className="token-balance">
            <span className="label">Tokens:</span>
            <span className="value">{formatTokenBalance(tokenBalance)}</span>
          </span>
          </div>
          <div className="refresh-balance">
              <button
                className="refresh-button"
                onClick={refreshBalances}
                disabled={refreshingBalance}
              >
                <FontAwesomeIcon 
                  icon={faRotate} 
                  spin={refreshingBalance}
                  className="refresh-icon"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bump-settings">
        <div className="setting">
          <label htmlFor="buyAmount">Buy Amount:</label>
          <input
            type="number"
            id="buyAmount"
            value={buyAmount}
            onChange={(e) => setBuyAmount(parseFloat(e.target.value))}
            min="0.001"
            step="0.001"
          />
        </div>
        <div className="setting">
          <label htmlFor="priorityFee">Jito Tip:</label>
          <input
            type="number"
            id="priorityFee"
            value={priorityFee}
            onChange={(e) => setPriorityFee(parseFloat(e.target.value))}
            min="0.000001"
            step="0.000001"
          />
        </div>
        <div className="setting">
          <label htmlFor="bumpInterval">Interval(s):</label>
          <input
            type="number"
            id="bumpInterval"
            value={bumpInterval}
            onChange={(e) => setBumpInterval(parseInt(e.target.value))}
            min="1"
            step="1"
          />
        </div>
      </div>
      <button 
        onClick={toggleBot}
        className={`start-stop-button ${isRunning ? 'running' : ''}`}
      >
        <FontAwesomeIcon icon={isRunning ? faStop : faPlay} />
        <span>{isRunning ? 'Stop' : 'Start'}</span>
      </button>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={clearToast}
        />
      )}
    </div>
  );
};

export default BumpBot;