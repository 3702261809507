import React, { useState, useEffect, useCallback, useRef } from 'react';
import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEraser } from '@fortawesome/free-solid-svg-icons';
import usePumpFunTokenSDK from '../hooks/usePumpFunTokenSDK';
import ModeSwitcher from './ModeSwitcher';
import './TokenMonitor.css';

const POLLING_INTERVAL = 1000;
const PUMP_CURVE_TOKEN_DECIMALS = 6;
const RAYDIUM_POLLING_INTERVAL = 1000;

const calculatePumpCurvePrice = (virtualSolReserves, virtualTokenReserves) => {
  if (virtualTokenReserves <= 0n || virtualSolReserves <= 0n) {
    throw new RangeError("curve state contains invalid reserve data");
  }
  return (Number(virtualSolReserves) / LAMPORTS_PER_SOL) / (Number(virtualTokenReserves) / 10 ** PUMP_CURVE_TOKEN_DECIMALS);
};

const TokenMonitor = ({ 
  connection,
  mintAddress, 
  tokenSupply,
  setMintAddress, 
  bundleSupplyPercentages,
  launchBundleSupplyPercentages,
  devSupplyPercentage,
  volumeWalletSupplyPercentages,
  isRaydiumMode,
  setIsRaydiumMode
}) => {
  // State
  const [localMintAddress, setLocalMintAddress] = useState(mintAddress);
  const [error, setError] = useState('');
  const [tokenExists, setTokenExists] = useState(null);
  const [realSolReserves, setRealSolReserves] = useState(null);
  const [virtualSolReserves, setVirtualSolReserves] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [tokenPriceUSD, setTokenPriceUSD] = useState(null);
  const [marketCapUSD, setMarketCapUSD] = useState(null);
  const [solPrice, setSolPrice] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  // Refs
  const currentMintAddressRef = useRef(mintAddress);
  const inputRef = useRef(null);
  const pollingTimeoutRef = useRef(null);
  const eventListenerIds = useRef(new Set());

  // Raydium-specific state
  const [raydiumPriceData, setRaydiumPriceData] = useState(null);
  const raydiumPollingRef = useRef(null);
  const [raydiumPoolInfo, setRaydiumPoolInfo] = useState(null);

  const abortControllerRef = useRef(null);
  const currentModeRef = useRef(isRaydiumMode);

  const { sdkRef, initializeSDK } = usePumpFunTokenSDK(connection);

  // Utility functions
  const formatSol = (lamports) => {
    try {
      const sol = Number(BigInt(Math.floor(Number(lamports))) * BigInt(1000000) / BigInt(1e9)) / 1000000;
      
      if (sol >= 1000000) {
        return `${(sol / 1000000).toFixed(2)}M`;
      } else if (sol >= 1000) {
        return `${(sol / 1000).toFixed(2)}K`;
      } else {
        return sol.toFixed(2);
      }
    } catch (error) {
      console.error('Error formatting SOL value:', error);
      return '0.00';
    }
  };

  const formatMarketCap = (num) => {
    if (num === null || isNaN(num)) return '------';
    
    if (num >= 1000000000) {
      return `${(num / 1000000000).toFixed(2)}B`;
    } else if (num >= 1000000) {
      return `${(num / 1000000).toFixed(2)}M`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(2)}K`;
    } else {
      return num.toFixed(2);
    }
  };

  const formatTokenPrice = (price) => {
    if (price === null) return '------';
    
    const priceStr = price.toFixed(10);
    const [whole, decimal] = priceStr.split('.');
    
    if (!decimal) return whole;
  
    let leadingZeros = 0;
    for (let i = 0; i < decimal.length; i++) {
      if (decimal[i] === '0') {
        leadingZeros++;
      } else {
        break;
      }
    }
  
    if (leadingZeros >= 3) {
      const remainingDecimals = decimal.slice(leadingZeros, leadingZeros + 4);
      const subscriptMap = { 
        '3': '₃', 
        '4': '₄', 
        '5': '₅',
        '6': '₆',
        '7': '₇',
        '8': '₈'
      };
      return `0.0${subscriptMap[leadingZeros]}${remainingDecimals}`;
    }
    
    return Number(priceStr).toFixed(6);
  };

  const validateMintAddress = (address) => {
    try {
      new PublicKey(address);
      return true;
    } catch (err) {
      return false;
    }
  };

  // Event management
  const cleanupEventListeners = useCallback(() => {
    const sdk = sdkRef.current;
    if (sdk) {
      eventListenerIds.current.forEach(id => {
        sdk.removeEventListener(id);
      });
      eventListenerIds.current.clear();
    }
  }, []);

  const setupEventListeners = useCallback((sdk) => {
    cleanupEventListeners();

    ['tradeEvent', 'setParamsEvent'].forEach(eventType => {
      const listenerId = sdk.addEventListener(eventType, (event, slot, signature) => {
        if (event.mint && event.mint.toString() === currentMintAddressRef.current) {
          if (eventType === 'tradeEvent') {
            setRealSolReserves(event.realSolReserves);
            setVirtualSolReserves(event.virtualSolReserves);
            try {
              const price = calculatePumpCurvePrice(event.virtualSolReserves, event.virtualTokenReserves);
              setTokenPrice(price);
              if (solPrice !== null) {
                const priceUSD = price * solPrice;
                setTokenPriceUSD(priceUSD);
                setMarketCapUSD(priceUSD * (tokenSupply || 0));
              }
            } catch (error) {
              console.error("Error calculating price:", error);
            }
          }
        }
      });
      eventListenerIds.current.add(listenerId);
    });
  }, [solPrice, cleanupEventListeners]);

  // Token data fetching and polling
  const checkTokenExistence = useCallback(async (address) => {
    if (!validateMintAddress(address)) {
      setTokenExists(false);
      setIsPolling(false);
      return;
    }
    try {
      const sdk = sdkRef.current;
      if (!sdk) return;
  
      const bondingCurveAccount = await sdk.getBondingCurveAccount(new PublicKey(address));
      const exists = bondingCurveAccount !== null;
      setTokenExists(exists);
      
      if (!exists && !isPolling) {
        setIsPolling(true);
        pollBondingCurve();
      }
    } catch (error) {
      console.error("Error checking token existence:", error);
      setTokenExists(false);
      if (!isPolling) {
        setIsPolling(true);
        pollBondingCurve();
      }
    }
  }, [isPolling]);

  const fetchInitialTokenData = useCallback(async (address) => {
    if (!address) return;
  
    try {
      const sdk = sdkRef.current;
      if (!sdk) return;
  
      const bondingCurveAccount = await sdk.getBondingCurveAccount(new PublicKey(address));
      if (bondingCurveAccount) {
        setRealSolReserves(bondingCurveAccount.realSolReserves);
        setVirtualSolReserves(bondingCurveAccount.virtualSolReserves);
        const price = calculatePumpCurvePrice(bondingCurveAccount.virtualSolReserves, bondingCurveAccount.virtualTokenReserves);
        setTokenPrice(price);
        if (solPrice !== null) {
          const priceUSD = price * solPrice;
          setTokenPriceUSD(priceUSD);
          setMarketCapUSD(priceUSD * (tokenSupply || 0));
        }
      }
    } catch (error) {
      console.log("Can't find this token in the bonding curve, maybe it has migrated to Raydium?");
      setTokenPrice(null);
      setTokenPriceUSD(null);
      setMarketCapUSD(null);
      setRealSolReserves(null);
      setVirtualSolReserves(null);
      setTokenExists(false);
      setIsPolling(false);
    }
  }, [solPrice]);

  const pollBondingCurve = useCallback(async () => {
    if (!currentMintAddressRef.current || !sdkRef.current || !validateMintAddress(currentMintAddressRef.current)) {
      setIsPolling(false);
      return;
    }

    try {
      const bondingCurveAccount = await sdkRef.current.getBondingCurveAccount(new PublicKey(currentMintAddressRef.current));
      const exists = bondingCurveAccount !== null;
      setTokenExists(exists);

      if (exists) {
        setIsPolling(false);
        setRealSolReserves(bondingCurveAccount.realSolReserves);
        const price = calculatePumpCurvePrice(bondingCurveAccount.virtualSolReserves, bondingCurveAccount.virtualTokenReserves);
        setTokenPrice(price);
        if (solPrice !== null) {
          const priceUSD = price * solPrice;
          setTokenPriceUSD(priceUSD);
          setMarketCapUSD(priceUSD * (tokenSupply || 0));
        }
      } else {
        pollingTimeoutRef.current = setTimeout(() => pollBondingCurve(), POLLING_INTERVAL);
      }
    } catch (error) {
      console.error("Error polling bonding curve:", error);
      pollingTimeoutRef.current = setTimeout(() => pollBondingCurve(), POLLING_INTERVAL);
    }
  }, [solPrice]);

  // Event handlers
  const handleReset = useCallback(() => {
    if (raydiumPollingRef.current) {
      clearTimeout(raydiumPollingRef.current);
      raydiumPollingRef.current = null;
    }
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current);
      pollingTimeoutRef.current = null;
    }

    currentMintAddressRef.current = '';
    
    setLocalMintAddress('');
    setMintAddress('');
    localStorage.removeItem('mintAddress');
    setError('');
    setTokenExists(null);
    setRealSolReserves(null);
    setVirtualSolReserves(null);
    setTokenPrice(null);
    setTokenPriceUSD(null);
    setMarketCapUSD(null);
    setRaydiumPriceData(null);
    setRaydiumPoolInfo(null);

    cleanupEventListeners();
    sdkRef.current = null;
  }, [setMintAddress, cleanupEventListeners]);

  const handleInputChange = (e) => {
    currentMintAddressRef.current = '';
    const newValue = e.target.value;
    setLocalMintAddress(newValue);
    setError('');
    
    cleanupEventListeners();
    sdkRef.current = null;
    setRaydiumPoolInfo(null);
    setTokenExists(null);
    setRealSolReserves(null);
    setVirtualSolReserves(null);
    setTokenPrice(null);
    setTokenPriceUSD(null);
    setMarketCapUSD(null);
    setRaydiumPriceData(null);
    setRaydiumPoolInfo(null);
    
    try {
      new PublicKey(newValue);
      setMintAddress(newValue);
      localStorage.setItem('mintAddress', newValue);
      checkTokenExistence(newValue);
    } catch (err) {
      setTokenExists(false);
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 400);
    }
  };

  // Calculate total supply percentage
  const calculateTotalSupplyPercentage = useCallback(() => {
    const bundleTotal = Object.values(bundleSupplyPercentages)
      .reduce((sum, percentage) => sum + (percentage || 0), 0);
    
    const launchBundleTotal = Object.values(launchBundleSupplyPercentages)
      .reduce((sum, percentage) => sum + (percentage || 0), 0);
    
    const volumeWalletTotal = volumeWalletSupplyPercentages.total || 0;
    
    const total = bundleTotal + launchBundleTotal + (devSupplyPercentage || 0) + volumeWalletTotal;
    
    return total.toFixed(2);
  }, [bundleSupplyPercentages, launchBundleSupplyPercentages, devSupplyPercentage, volumeWalletSupplyPercentages]);

  // UI helper functions
  const getTokenStatusColor = () => {
    if (tokenExists === null) return '#030';
    if (tokenExists === false && validateMintAddress(localMintAddress)) return 'yellow';
    return tokenExists ? '#0f0' : 'red';
  };

  const getBlinkClass = () => tokenExists ? 'blink' : '';

  // Effects
  useEffect(() => {
    const storedMintAddress = localStorage.getItem('mintAddress');
    if (storedMintAddress) {
      setLocalMintAddress(storedMintAddress);
      setMintAddress(storedMintAddress);
      currentMintAddressRef.current = storedMintAddress;
      setTokenExists(validateMintAddress(storedMintAddress));
    }
  }, []);

  useEffect(() => {
    const fetchSolPrice = async () => {
      try {
          const customRpcUrl = localStorage.getItem('RPC_URL');
          if (!customRpcUrl) {
              throw new Error('RPC URL is required');
          }
  
          const response = await fetch('http://localhost:5000/api/solana-price', {
              headers: {
                  'x-rpc-url': customRpcUrl
              }
          });
          const data = await response.json();
          setSolPrice(data.price);
          if (tokenPrice !== null) {
              const priceUSD = tokenPrice * data.price;
              setTokenPriceUSD(priceUSD);
              setMarketCapUSD(priceUSD * (tokenSupply || 0));
          }
      } catch (error) {
          console.error('Error fetching SOL price:', error);
      }
  };

    fetchSolPrice();
    const intervalId = setInterval(fetchSolPrice, 300000);
    return () => clearInterval(intervalId);
  }, [tokenPrice]);

  useEffect(() => {
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current);
    }
    
    localStorage.setItem('mintAddress', mintAddress);
    setLocalMintAddress(mintAddress);
    currentMintAddressRef.current = mintAddress;
  
    if (!mintAddress) {
      cleanupEventListeners();
      setRealSolReserves(null);
      setVirtualSolReserves(null);
    setTokenPrice(null);
    setTokenPriceUSD(null);
    setMarketCapUSD(null);
    setTokenExists(null);
    return;
  }

  if (isRaydiumMode) {
    return;
  }

  const setupSDKAndListeners = async () => {
    const sdk = await initializeSDK();
    setupEventListeners(sdk);
    await checkTokenExistence(mintAddress);
    await fetchInitialTokenData(mintAddress);
  };

  setupSDKAndListeners();

}, [mintAddress, initializeSDK, setupEventListeners, checkTokenExistence, fetchInitialTokenData, cleanupEventListeners]);

useEffect(() => {
  return () => {
    cleanupEventListeners();
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current);
    }
  };
}, [cleanupEventListeners]);

const findRaydiumPool = useCallback(async (mintAddress) => {
  if (!mintAddress || !isRaydiumMode) return null;
  
  try {
      const customRpcUrl = localStorage.getItem('RPC_URL');
      if (!customRpcUrl) {
          throw new Error('RPC URL is required');
      }

      const response = await fetch(`http://localhost:5000/api/raydium/pool/${mintAddress}`, {
          headers: {
              'x-rpc-url': customRpcUrl
          }
      });
      if (!response.ok) {
          throw new Error('Failed to find Raydium pool');
      }
      const poolInfo = await response.json();
      setRaydiumPoolInfo(poolInfo);
      return poolInfo;
  } catch (error) {
      console.log('Can not find this token on Raydium!');
      setTokenExists(false);
      setTokenPrice(null);
      setTokenPriceUSD(null);
      setMarketCapUSD(null);
      setRealSolReserves(null);
      setVirtualSolReserves(null);
      setError('No Raydium pool found for this token');
      return null;
  }
}, [isRaydiumMode]);

// Updated Raydium data fetching
const fetchRaydiumData = useCallback(async () => {
  const currentMint = currentMintAddressRef.current;
  
  if (!currentMint || !isRaydiumMode || !raydiumPoolInfo) {
      return;
  }

  if (abortControllerRef.current) {
      abortControllerRef.current.abort();
  }
  abortControllerRef.current = new AbortController();

  try {
      const customRpcUrl = localStorage.getItem('RPC_URL');
      if (!customRpcUrl) {
          throw new Error('RPC URL is required');
      }

      const response = await fetch(
          `http://localhost:5000/api/raydium/price/${currentMint}?poolInfo=${encodeURIComponent(JSON.stringify(raydiumPoolInfo))}`,
          { 
              signal: abortControllerRef.current.signal,
              headers: {
                  'x-rpc-url': customRpcUrl
              }
          }
      );
    
    if (!currentModeRef.current || currentMint !== currentMintAddressRef.current) {
      return;
    }

    if (!response.ok) {
      throw new Error('Failed to fetch Raydium data');
    }
    
    const data = await response.json();
    setRaydiumPriceData(data);
    setTokenPrice(data.priceInSol);
    setTokenPriceUSD(data.priceInUsd);
    setRealSolReserves(data.baseReserves * LAMPORTS_PER_SOL);
    setMarketCapUSD(data.priceInUsd * (tokenSupply || 0));
    setTokenExists(true);
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.error('Error fetching Raydium price data:', error);
    setTokenExists(false);
    setTokenPrice(null);
    setTokenPriceUSD(null);
    setMarketCapUSD(null);
    setRealSolReserves(null);
    setVirtualSolReserves(null);
  }
}, [isRaydiumMode, raydiumPoolInfo, tokenSupply]);

useEffect(() => {
  currentMintAddressRef.current = mintAddress;
}, [mintAddress]);

useEffect(() => {
  let isSubscribed = true;

  const cleanup = () => {
    isSubscribed = false;
    if (raydiumPollingRef.current) {
      clearTimeout(raydiumPollingRef.current);
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  if (isRaydiumMode && mintAddress && mintAddress.trim() !== '' && raydiumPoolInfo) {
    const pollRaydium = async () => {
      if (!isSubscribed || !currentModeRef.current) return;
      
      await fetchRaydiumData();
      
      if (isSubscribed && currentModeRef.current) {
        raydiumPollingRef.current = setTimeout(pollRaydium, RAYDIUM_POLLING_INTERVAL);
      }
    };

    pollRaydium();
  }
  
  return cleanup;
}, [isRaydiumMode, mintAddress, raydiumPoolInfo, fetchRaydiumData]);

useEffect(() => {
  if (isRaydiumMode && mintAddress && mintAddress.trim() !== '') {
    findRaydiumPool(mintAddress);
  }
}, [isRaydiumMode, mintAddress, findRaydiumPool]);

const handleModeChange = useCallback(async (newMode) => {
  currentModeRef.current = newMode;
  
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }

  if (raydiumPollingRef.current) {
    clearTimeout(raydiumPollingRef.current);
  }
  if (pollingTimeoutRef.current) {
    clearTimeout(pollingTimeoutRef.current);
  }

  cleanupEventListeners();
  sdkRef.current = null;
  
  setIsRaydiumMode(newMode);
  setTokenExists(null);
  setRaydiumPriceData(null);
  setVirtualSolReserves(null);
  setRealSolReserves(null);
  setTokenPrice(null);
  setTokenPriceUSD(null);
  setMarketCapUSD(null);
  setRaydiumPoolInfo(null);

  if (mintAddress) {
    if (newMode) {
      const poolInfo = await findRaydiumPool(mintAddress);
      if (poolInfo) {
        await fetchRaydiumData();
      }
    } else {
      const sdk = await initializeSDK();
      await setupEventListeners(sdk);
      await checkTokenExistence(mintAddress);
      await fetchInitialTokenData(mintAddress);
    }
  }
}, [cleanupEventListeners, initializeSDK, setupEventListeners, checkTokenExistence, 
    fetchInitialTokenData, mintAddress, fetchRaydiumData, findRaydiumPool]);

useEffect(() => {
  return () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };
}, []);

const calculatePositionWorth = useCallback(() => {
  const totalSupplyPercentage = Number(calculateTotalSupplyPercentage()) / 100;
  
  if (isRaydiumMode) {
    if (!raydiumPriceData?.priceInSol || !tokenSupply) {
      return null;
    }
    
    const worth = tokenSupply * totalSupplyPercentage * raydiumPriceData.priceInSol;
    return worth * LAMPORTS_PER_SOL;
  } else {
    if (virtualSolReserves === null) {
      return null;
    }
    return Number(virtualSolReserves) * totalSupplyPercentage;
  }
}, [isRaydiumMode, raydiumPriceData, virtualSolReserves, calculateTotalSupplyPercentage, tokenSupply]);

return (
  <div className="token-monitor">
    <div className="token-monitor-header">
      <h1>Info</h1>
    </div>
    <div className="over-mint-address">
      <div className="token-status-label">
        <div className="status-icon-container">
          <div className={`status-icon ${getBlinkClass()}`} style={{ color: getTokenStatusColor() }}>
            <FontAwesomeIcon icon={faCircle} />
          </div>
        </div>
        <div>
          <p className="token-address-label">Enter token address:</p>
        </div>
      </div>
      <ModeSwitcher 
        isRaydiumMode={isRaydiumMode} 
        onModeChange={handleModeChange} 
      />
    </div>
    <div className="mint-input-container">
      <input
        ref={inputRef}
        type="text"
        value={localMintAddress}
        onChange={handleInputChange}
        placeholder="Enter mint address"
        className={isShaking ? 'shake' : ''}
      />
        {localMintAddress && (
        <button className="reset-button" type="button" onClick={handleReset}>
          <FontAwesomeIcon icon={faEraser} /> Clear
        </button>
      )}
    </div>
    <div className="token-info">
      <div className="token-price">
        <p className="token-price-usd-label">Token Price</p>
        {tokenPriceUSD !== null ? (
          <p className="token-price-usd">${formatTokenPrice(tokenPriceUSD)}</p>
        ) : (
          <p className="token-price-usd placeholder">---- USD</p>
        )}
      </div>
      <div className="market-cap">
        <p className="market-cap-label">Market Cap</p>
        {marketCapUSD !== null ? (
          <p className="market-cap-usd">${formatMarketCap(marketCapUSD)}</p>
        ) : (
          <p className="market-cap-usd placeholder">---- USD</p>
        )}
      </div>
      <div className="sol-reserves">
        <p className="sol-reserves-label">SOL Reserves</p>
        {realSolReserves !== null ? (
          <p className="real-sol-reserves">{formatSol(realSolReserves)}</p>
        ) : (
          <p className="real-sol-reserves placeholder">---- SOL</p>
        )}
      </div>
      <div className="position-worth">
        <p className="position-worth-label">Position Worth</p>
        {(() => {
          const worth = calculatePositionWorth();
          return worth !== null ? (
            <p className="position-worth-value">{formatSol(worth)}</p>
          ) : (
            <p className="position-worth-value placeholder">---- SOL</p>
          );
        })()}
      </div>
    </div>
  </div>
);
};

export default React.memo(TokenMonitor);