import { useState, useCallback } from 'react';
import { 
  ComputeBudgetProgram, 
  PublicKey, 
  TransactionMessage, 
  VersionedTransaction, 
  Keypair,
  LAMPORTS_PER_SOL
} from '@solana/web3.js';
import { createCloseAccountInstruction, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import bs58 from 'bs58';

const BLACKLIST = [
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",  // USDC
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"   // USDT
];

const MAX_RETRIES = 3;
const RETRY_DELAY = 5000;
const BATCH_SIZE = 5;  // Process 5 wallets at a time

export const useCloseAccounts = (connection) => {
  const [isClosing, setIsClosing] = useState(false);
  const [closedCount, setClosedCount] = useState(0);

  const fetchBalances = useCallback(async (wallet) => {
    try {
      // Get SOL balance
      const solBalance = await connection.getBalance(new PublicKey(wallet.publicKey));
      
      return {
        publicKey: wallet.publicKey,
        balance: solBalance / LAMPORTS_PER_SOL
      };
    } catch (error) {
      console.error(`Error fetching balances for ${wallet.publicKey}:`, error);
      return null;
    }
  }, [connection]);

  const processBatch = useCallback(async (wallets, onBalanceUpdate, attempt = 1) => {
    try {
      const batchInstructions = await Promise.all(wallets.map(async (wallet) => {
        const atas = await connection.getParsedTokenAccountsByOwner(
          new PublicKey(wallet.publicKey),
          { programId: TOKEN_PROGRAM_ID }
        );

        const closeInstructions = [];
        for (const ata of atas.value) {
          const data = ata.account.data.parsed.info;

          if (BLACKLIST.includes(data.mint) || data.tokenAmount.amount !== "0") {
            continue;
          }

          closeInstructions.push({
            instruction: createCloseAccountInstruction(
              new PublicKey(ata.pubkey),
              new PublicKey(wallet.publicKey),
              new PublicKey(wallet.publicKey)
            ),
            wallet
          });
        }
        return closeInstructions;
      }));

      // Flatten all instructions and remove empty entries
      const allInstructions = batchInstructions.flat();
      
      if (allInstructions.length === 0) {
        return 0;
      }

      // Group instructions by wallet (max 15 instructions per transaction)
      const groupedInstructions = {};
      allInstructions.forEach(({ instruction, wallet }) => {
        if (!groupedInstructions[wallet.publicKey]) {
          groupedInstructions[wallet.publicKey] = {
            instructions: [],
            wallet
          };
        }
        if (groupedInstructions[wallet.publicKey].instructions.length < 15) {
          groupedInstructions[wallet.publicKey].instructions.push(instruction);
        }
      });

      // Process each wallet's group of instructions
      const transactions = [];
      for (const group of Object.values(groupedInstructions)) {
        if (group.instructions.length === 0) continue;

        const computeBudgetInstructions = [
          ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 2222222 }),
          ComputeBudgetProgram.setComputeUnitLimit({ units: 45000 })
        ];

        const block = await connection.getLatestBlockhash("processed");
        const message = new TransactionMessage({
          instructions: [...computeBudgetInstructions, ...group.instructions],
          payerKey: new PublicKey(group.wallet.publicKey),
          recentBlockhash: block.blockhash
        }).compileToV0Message();

        const transaction = new VersionedTransaction(message);
        const keypair = Keypair.fromSecretKey(bs58.decode(group.wallet.secretKey));
        transaction.sign([keypair]);

        transactions.push({
          transaction,
          wallet: group.wallet,
          instructionCount: group.instructions.length,
          blockhash: block
        });
      }

      // Send all transactions with a small delay between each
      let totalClosed = 0;
      for (const tx of transactions) {
        try {
          await connection.sendTransaction(tx.transaction, {
            preflightCommitment: "processed",
            skipPreflight: false,
            maxRetries: 10
          });

          const signature = bs58.encode(tx.transaction.signatures[0]);
          await connection.confirmTransaction({
            signature,
            ...tx.blockhash
          }, "processed");

          console.log(`Successfully closed ${tx.instructionCount} accounts for wallet ${tx.wallet.publicKey}`);
          totalClosed += tx.instructionCount;

          // Small delay between transactions
          await new Promise(resolve => setTimeout(resolve, 500));
        } catch (error) {
          console.error(`Error processing wallet ${tx.wallet.publicKey}:`, error);
          
          // If this is a timeout or blockhash error and we haven't hit max retries, retry the batch
          if (attempt < MAX_RETRIES && (
            error.message?.includes("blockhash") || 
            error.message?.includes("expired") ||
            error.message?.includes("timeout")
          )) {
            console.log(`Retrying batch (attempt ${attempt + 1})`);
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            return processBatch(wallets, onBalanceUpdate, attempt + 1);
          }
        }
      }

      // After processing transactions, update balances
      try {
        const updatedBalances = await Promise.all(
          wallets.map(wallet => fetchBalances(wallet))
        );
        
        // Filter out any null results from failed balance fetches
        const validBalances = updatedBalances.filter(balance => balance !== null);
        
        // Call the callback with updated balances
        if (validBalances.length > 0 && onBalanceUpdate) {
          onBalanceUpdate(validBalances);
        }
        
        return totalClosed;
      } catch (error) {
        console.error('Error updating balances:', error);
        return totalClosed;
      }
    } catch (error) {
      console.error('Batch processing error:', error);
      if (attempt < MAX_RETRIES) {
        console.log(`Retrying batch (attempt ${attempt + 1})`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return processBatch(wallets, onBalanceUpdate, attempt + 1);
      }
      return 0;
    }
  }, [connection, fetchBalances]);

  const processAllWallets = useCallback(async (wallets, onBalanceUpdate) => {
    if (isClosing) return;
    
    setIsClosing(true);
    setClosedCount(0);
    let totalClosed = 0;

    try {
      // Process wallets in batches
      for (let i = 0; i < wallets.length; i += BATCH_SIZE) {
        const batch = wallets.slice(i, i + BATCH_SIZE);
        const closed = await processBatch(batch, onBalanceUpdate);
        totalClosed += closed;
        setClosedCount(totalClosed);
      }

      return totalClosed;
    } catch (error) {
      console.error('Error processing wallets:', error);
      throw error;
    } finally {
      setIsClosing(false);
    }
  }, [processBatch]);

  return {
    processAllWallets,
    isClosing,
    closedCount
  };
};