import { Keypair } from "@solana/web3.js";
import ModifiedPumpFunSDK from '../ModifiedPumpFunSDK';
import { AnchorProvider } from "@coral-xyz/anchor";

class BrowserWallet {
  constructor(keypair) {
    this.keypair = keypair;
  }

  async signTransaction(tx) {
    tx.partialSign(this.keypair);
    return tx;
  }

  async signAllTransactions(txs) {
    return txs.map((tx) => {
      tx.partialSign(this.keypair);
      return tx;
    });
  }

  get publicKey() {
    return this.keypair.publicKey;
  }
}

export const getProvider = (connection) => {
  const wallet = new BrowserWallet(Keypair.generate());
  return new AnchorProvider(connection, wallet, { commitment: "processed" });
};

export const getSdk = (connection) => {
  const provider = getProvider(connection);
  return new ModifiedPumpFunSDK(provider);
};