import React from 'react';
import './ModeSwitcher.css';

const ModeSwitcher = ({ isRaydiumMode, onModeChange }) => {
  return (
    <div className="mode-switcher">
      <button
        onClick={() => onModeChange(!isRaydiumMode)}
        className={`mode-toggle ${isRaydiumMode ? 'active' : ''}`}
      >
        <span className="mode-toggle-sr">Toggle Raydium mode</span>
        <span className="mode-toggle-slider" />
      </button>
      <span className="mode-label">
        {isRaydiumMode ? 'Raydium' : 'Pump.fun'}
      </span>
    </div>
  );
};

export default ModeSwitcher;