import React, { useState, useEffect, useCallback } from 'react';
import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import DevPanel from './components/DevPanel';
import MainWalletPanel from './components/MainWalletPanel';
import BundleWalletPanel from './components/BundleWalletPanel';
import TradePanel from './components/TradePanel';
import LaunchBundleWalletPanel from './components/LaunchBundleWalletPanel';
import TransactionSettings from './components/TransactionSettings';
import TokenMonitor from './components/TokenMonitor';
import BumpBot from './components/BumpBot'
import LoadingScreen from './components/LoadingScreen';
import CommentBot from './components/CommentBot';
import VolumeBot from './components/VolumeBot';
import useTokenSupply from './hooks/useTokenSupply';
import UserSettings from './components/UserSettings';
import LicenseManager from './components/LicenseManager';
import LicenseAdmin from './components/LicenseAdmin';
import { Buffer } from 'buffer';
import './App.css';

global.Buffer = Buffer;

function App() {

  const isAdminRoute = window.location.pathname === '/admin';

  const [hasValidLicense, setHasValidLicense] = useState(false);

  const [connection, setConnection] = useState(() => {
    const customRpcUrl = localStorage.getItem('RPC_URL');
    if (!customRpcUrl) {
      return null;  // Or throw error if you want to require RPC URL
    }
    return new Connection(customRpcUrl, "confirmed");
  });

  const [mintAddress, setMintAddress] = useState(() => {
    return localStorage.getItem('mintAddress') || '';
  });

  const { tokenSupply } = useTokenSupply(mintAddress, connection);
  const [launchBundle, setLaunchBundle] = useState(false);
  const [slippagePercent, setSlippagePercent] = useState(5);
  const [priorityFee, setPriorityFee] = useState(0.0001);
  const [jitoTip, setJitoTip] = useState(0.0003);
  const [bundleAirdropAmount, setBundleAirdropAmount] = useState(0);
  const [launchBundleAirdropAmount, setLaunchBundleAirdropAmount] = useState(0);
  const [availableBundleCount, setAvailableBundleCount] = useState(0);
  const [availableLaunchBundleCount, setAvailableLaunchBundleCount] = useState(0);
  const [bundleWallets, setBundleWallets] = useState([]);
  const [bumpWalletBalance, setBumpWalletBalance] = useState(0);
  const [devWalletBalance, setDevWalletBalance] = useState(0);
  const [launchBundleWallets, setLaunchBundleWallets] = useState([]);
  const [bundleSupplyPercentages, setBundleSupplyPercentages] = useState({});
  const [launchBundleSupplyPercentages, setLaunchBundleSupplyPercentages] = useState({});
  const [devSupplyPercentage, setDevSupplyPercentage] = useState(0);
  const [mainWalletBalance, setMainWalletBalance] = useState(null);
  const [volumePriorityFee, setVolumePriorityFee] = useState(0.0001);
  const [volumeWalletSupplyPercentages, setVolumeWalletSupplyPercentages] = useState({});
  const [isRaydiumMode, setIsRaydiumMode] = useState(false);

  useEffect(() => {
    localStorage.setItem('mintAddress', mintAddress);
  }, [mintAddress]);

  const transactionSettings = {
    slippagePercent,
    setSlippagePercent,
    priorityFee,
    setPriorityFee,
    jitoTip,
    setJitoTip
  };

  const updateVolumePriorityFee = (fee) => {
    setVolumePriorityFee(fee);
  };

  const updateBumpWalletBalance = useCallback((newBalance) => {
    setBumpWalletBalance(newBalance);
  }, []);

  const updateDevWalletBalance = useCallback((newBalance) => {
    setDevWalletBalance(newBalance);
  }, []);

  const updateBundleAirdropAmount = useCallback((amount) => {
    setBundleAirdropAmount(amount);
  }, []);

  const updateLaunchBundleAirdropAmount = useCallback((amount) => {
    setLaunchBundleAirdropAmount(amount);
  }, []);

  const handleSetMintAddress = (address) => {
    setMintAddress(address);
  };

  const updateBundleWallets = useCallback((newWallets) => {
    setBundleWallets(newWallets);
  }, []);

  const updateLaunchBundleWallets = useCallback((newWallets) => {
    setLaunchBundleWallets(newWallets);
  }, []);

  // Add this function to fetch balance
  const fetchBalance = async (publicKey) => {
    if (!connection) return 0;
    try {
      const balance = await connection.getBalance(new PublicKey(publicKey));
      return balance / LAMPORTS_PER_SOL;
    } catch (error) {
      console.error('Error fetching balance:', error);
      return 0;
    }
  };

  const fetchTokenBalance = async (publicKey) => {
    if (!mintAddress) return 0;
    
    try {
      const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(mintAddress),
        new PublicKey(publicKey)
      );
      
      try {
        const accountInfo = await getAccount(connection, tokenAccount);
        return Number(accountInfo.amount) / Math.pow(10, 6); // Assuming 6 decimals
      } catch (error) {
        // Silently handle expected errors for non-existent token accounts
        if (
          error.name === 'TokenAccountNotFoundError' || 
          error.message?.includes('TokenAccountNotFound') ||
          error.message?.includes('Account does not exist') ||
          error.message?.includes('Failed to find account')
        ) {
          return 0;
        }
        
        // For unexpected token account errors, log without error object
        console.debug('Token account error:', {
          message: error.message,
          publicKey,
          mintAddress
        });
        return 0;
      }
    } catch (error) {
      // For unexpected get associated token address errors, log without error object
      console.debug('Associated token address error:', {
        message: error.message,
        publicKey,
        mintAddress
      });
      return 0;
    }
  };

  const updateBundleWalletsBalances = useCallback(async () => {
    const updatedWallets = await Promise.all(bundleWallets.map(async (wallet) => ({
      ...wallet,
      balance: await fetchBalance(wallet.publicKey),
      tokenBalance: await fetchTokenBalance(wallet.publicKey)
    })));
    setBundleWallets(updatedWallets);
  }, [bundleWallets, mintAddress]); // Add mintAddress to dependencies

  const updateLaunchBundleWalletsBalances = useCallback(async () => {
    const updatedWallets = await Promise.all(launchBundleWallets.map(async (wallet) => ({
      ...wallet,
      balance: await fetchBalance(wallet.publicKey),
      tokenBalance: await fetchTokenBalance(wallet.publicKey)
    })));
    setLaunchBundleWallets(updatedWallets);
  }, [launchBundleWallets, mintAddress]);

  const handleBundleSupplyUpdate = (percentages) => {
    setBundleSupplyPercentages(percentages);
  };

const handleLaunchBundleSupplyUpdate = (percentages) => {
  setLaunchBundleSupplyPercentages(percentages);
};

const handleDevSupplyUpdate = (percentage) => {
  setDevSupplyPercentage(percentage);
};

if (isAdminRoute) {
  return <LicenseAdmin />;
}

if (!hasValidLicense) {
  return <LicenseManager onValidLicense={setHasValidLicense} />;
}

  return (
    <LoadingScreen>
    <div className="App">
      <UserSettings />
      <DevPanel
        connection={connection}
        setMintAddress={handleSetMintAddress}
        mintAddress={mintAddress}
        tokenSupply={tokenSupply}
        setLaunchBundle={setLaunchBundle}
        transactionSettings={transactionSettings}
        devWalletBalance={devWalletBalance}
        setDevWalletBalance={setDevWalletBalance}
        onDevSupplyUpdate={handleDevSupplyUpdate}
        pool={isRaydiumMode ? "raydium" : "pump"}
      />
      <VolumeBot
        connection={connection} 
        mintAddress={mintAddress}
        tokenSupply={tokenSupply}
        transactionSettings={transactionSettings}
        setMainWalletBalance={setMainWalletBalance}
        priorityFee={volumePriorityFee}
        onVolumeSupplyUpdate={setVolumeWalletSupplyPercentages}
        pool={isRaydiumMode ? "raydium" : "pump"}  
        />  
      <div className='token-main'>
        <TokenMonitor
          connection={connection} 
          mintAddress={mintAddress}
          tokenSupply={tokenSupply} 
          setMintAddress={handleSetMintAddress}
          bundleSupplyPercentages={bundleSupplyPercentages}
          launchBundleSupplyPercentages={launchBundleSupplyPercentages}
          devSupplyPercentage={devSupplyPercentage}
          volumeWalletSupplyPercentages={volumeWalletSupplyPercentages}
          isRaydiumMode={isRaydiumMode}
          setIsRaydiumMode={setIsRaydiumMode}
        />
        <div className='main-settings'>
          <div className='settings-bumpbot'>
          <MainWalletPanel
            connection={connection}
            bundleAirdropAmount={bundleAirdropAmount}
            launchBundleAirdropAmount={launchBundleAirdropAmount}
            updateBumpWalletBalance={updateBumpWalletBalance}
            updateDevWalletBalance={updateDevWalletBalance}
            updateBundleWalletsBalances={updateBundleWalletsBalances}
            updateLaunchBundleWalletsBalances={updateLaunchBundleWalletsBalances}
            bundleWallets={bundleWallets}
            launchBundleWallets={launchBundleWallets}
            mainWalletBalance={mainWalletBalance}
            setMainWalletBalance={setMainWalletBalance}
            updateVolumePriorityFee={updateVolumePriorityFee}
            transactionSettings={transactionSettings}
            mintAddress={mintAddress}
          />
          <BumpBot
            connection={connection}
            mintAddress={mintAddress}
            bumpWalletBalance={bumpWalletBalance}
            pool={isRaydiumMode ? "raydium" : "pump"}
            />
          </div>
          <div className="settings-bumpbot">
          <TransactionSettings {...transactionSettings} />
          <TradePanel
          connection={connection}
          mintAddress={mintAddress}
          transactionSettings={transactionSettings}
          bundleAirdropAmount={bundleAirdropAmount}
          launchBundleAirdropAmount={launchBundleAirdropAmount}
          availableBundleCount={availableBundleCount}
          availableLaunchBundleCount={availableLaunchBundleCount}
          bundleWallets={bundleWallets}
          setBundleWallets={setBundleWallets}
          launchBundleWallets={launchBundleWallets}
          setLaunchBundleWallets={setLaunchBundleWallets}
          updateBundleWalletsBalances={updateBundleWalletsBalances}
          updateLaunchBundleWalletsBalances={updateLaunchBundleWalletsBalances}
          bundleSupplyPercentages={bundleSupplyPercentages}
          launchBundleSupplyPercentages={launchBundleSupplyPercentages}
          pool={isRaydiumMode ? "raydium" : "pump"}
          />
          <CommentBot mintAddress={mintAddress} />
          </div>
        </div>
      </div>
      <div className='bundle-bundlewallets'>
        <BundleWalletPanel
          connection={connection} 
          onAirdropAmountChange={setBundleAirdropAmount}
          onBundleCountChange={setAvailableBundleCount}
          onBundleSupplyUpdate={handleBundleSupplyUpdate}
          updateBundleWallets={updateBundleWallets}
          bundleWallets={bundleWallets}
          mintAddress={mintAddress}
          tokenSupply={tokenSupply}
        />
          <LaunchBundleWalletPanel
            connection={connection} 
            onAirdropAmountChange={setLaunchBundleAirdropAmount}
            onBundleCountChange={setAvailableLaunchBundleCount}
            onBundleSupplyUpdate={handleLaunchBundleSupplyUpdate}
            launchBundleWallets={launchBundleWallets}
            updateLaunchBundleWallets={updateLaunchBundleWallets}
            mintAddress={mintAddress}
            tokenSupply={tokenSupply}
          />
      </div>
    </div>
    </LoadingScreen>
  );
}

export default App;