import { Buffer } from 'buffer';
import { PumpFunSDK } from 'pumpdotfun-sdk';

class ModifiedPumpFunSDK extends PumpFunSDK {
  constructor(provider) {
    super(provider);
    this.Buffer = Buffer;
  }
  async createTokenMetadata(create) {
    let formData = new FormData();
    formData.append("file", create.file);
    formData.append("name", create.name);
    formData.append("symbol", create.symbol);
    formData.append("description", create.description);
    formData.append("twitter", create.twitter || "");
    formData.append("telegram", create.telegram || "");
    formData.append("website", create.website || "");
    formData.append("showName", "true");
    let request = await fetch("http://localhost:5000/api/ipfs", {
      method: "POST",
      body: formData,
    });
    return request.json();
  }
}

export default ModifiedPumpFunSDK;