import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';

const LoadingScreen = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const asciiArt = `
██╗     ███████╗████████╗███████╗   
██║     ██╔════╝╚══██╔══╝██╔════╝   
██║     █████╗     ██║   ███████╗   
██║     ██╔══╝     ██║   ╚════██║   
███████╗███████╗   ██║   ███████║   
╚══════╝╚══════╝   ╚═╝   ╚══════╝   
                                    
██████╗ ██╗   ██╗███╗   ███╗██████╗ 
██╔══██╗██║   ██║████╗ ████║██╔══██╗
██████╔╝██║   ██║██╔████╔██║██████╔╝
██╔═══╝ ██║   ██║██║╚██╔╝██║██╔═══╝ 
██║     ╚██████╔╝██║ ╚═╝ ██║██║     
╚═╝      ╚═════╝ ╚═╝     ╚═╝╚═╝     
                                    
   ███████╗██╗   ██╗███╗   ██╗      
   ██╔════╝██║   ██║████╗  ██║      
   █████╗  ██║   ██║██╔██╗ ██║      
   ██╔══╝  ██║   ██║██║╚██╗██║      
██╗██║     ╚██████╔╝██║ ╚████║      
╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝      
`;

  useEffect(() => {
    const messages = ['Starting program...'];
    let currentMessageIndex = 0;
   
    const typeMessage = () => {
      const currentMessage = messages[currentMessageIndex];
      let charIndex = 0;
     
      const typeChar = () => {
        if (charIndex <= currentMessage.length) {
          setLoadingText(currentMessage.substring(0, charIndex));
          charIndex++;
          setTimeout(typeChar, 50);
        } else if (currentMessageIndex < messages.length - 1) {
          currentMessageIndex++;
          setTimeout(typeMessage, 500);
        }
      };
     
      typeChar();
    };
    typeMessage();

    const checkIfLoaded = () => {
      if (document.readyState === 'complete') {
        setTimeout(() => setIsLoading(false), 2000);
      } else {
        window.requestAnimationFrame(checkIfLoaded);
      }
    };
    checkIfLoaded();
  }, []);

  if (isLoading) {
    return (
      <div className="terminal-loading-screen">
        <div className="loading-content">
          <pre className="ascii-art">{asciiArt}</pre>
          <div className="loading-line">
            <span className="bracket">[</span>
            <div className="progress-bar">
              <div className="progress-fill"></div>
            </div>
            <span className="bracket">]</span>
          </div>
          <div className="terminal-text">
            <span className="cursor">&gt;</span>
            {loadingText}
            <span className="blink">_</span>
          </div>
        </div>
      </div>
    );
  }
  return children;
};

export default LoadingScreen;