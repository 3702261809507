import React, { useState, useEffect, useRef } from 'react';
import { Keypair } from '@solana/web3.js';
import nacl from 'tweetnacl';
import bs58 from 'bs58';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faPlay, faStop, faTimes, faNetworkWired, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import './CommentBot.css'

const API_BASE = 'http://localhost:5000';
const MIN_DELAY = 1500;
const DEFAULT_ACCOUNT_COUNT = 10;

const STORAGE_KEYS = {
    PROXIES: 'commentbot_proxies',
    COMMENTS: 'commentbot_comments',
};

const CommentBot = ({ mintAddress }) => {
    const [accounts, setAccounts] = useState([]);
    const [comments, setComments] = useState([]);
    const [logs, setLogs] = useState(['']);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [accountCount, setAccountCount] = useState(DEFAULT_ACCOUNT_COUNT);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showCommentsOverlay, setShowCommentsOverlay] = useState(false);
    const [showProxyOverlay, setShowProxyOverlay] = useState(false);
    const [proxies, setProxies] = useState([]);
    const [useProxies] = useState(true);
    const initialized = useRef(false);
    const stopRequested = useRef(false);
    const [randomizeComments, setRandomizeComments] = useState(true);
    const [newComment, setNewComment] = useState('');
    const [newProxy, setNewProxy] = useState('');

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            generateAccounts(accountCount);
            loadDataFromStorage();
        }
    }, [accountCount]);

    useEffect(() => {
        if (!mintAddress) {
            setError('Please set mint address.');
        } else {
            setError(null);
        }
    }, [mintAddress]);

    const loadDataFromStorage = () => {
        try {
            const savedComments = localStorage.getItem(STORAGE_KEYS.COMMENTS);
            if (savedComments) {
                setComments(JSON.parse(savedComments));
                addLog(`Loaded ${JSON.parse(savedComments).length} comments from storage`);
            }

            const savedProxies = localStorage.getItem(STORAGE_KEYS.PROXIES);
            if (savedProxies) {
                setProxies(JSON.parse(savedProxies));
                addLog(`Loaded ${JSON.parse(savedProxies).length} proxies from storage`);
            }
        } catch (error) {
            setError('Failed to load data from storage: ' + error.message);
            addLog('Failed to load data from storage', true);
        }
    };

    const saveToStorage = (key, data) => {
        try {
            localStorage.setItem(key, JSON.stringify(data));
            addLog(`Saved ${data.length} items to ${key}`);
        } catch (error) {
            setError(`Failed to save to ${key}: ` + error.message);
            addLog(`Failed to save to ${key}`, true);
        }
    };

    const addComments = () => {
        if (!newComment.trim()) return;
        const commentLines = newComment.split('\n').filter(line => line.trim());
        const updatedComments = [...comments, ...commentLines];
        setComments(updatedComments);
        saveToStorage(STORAGE_KEYS.COMMENTS, updatedComments);
        setNewComment('');
        addLog(`Added ${commentLines.length} new comments`);
    };

    const deleteAllComments = () => {
        if (isProcessing) return;
        setComments([]);
        saveToStorage(STORAGE_KEYS.COMMENTS, []);
        addLog('All comments deleted');
    };

    const addProxies = () => {
        if (!newProxy.trim()) return;
        const proxyLines = newProxy.split('\n').filter(line => line.trim());
        
        // Validate each proxy
        const invalidProxies = proxyLines.filter(proxy => 
            !proxy.includes(':') || !proxy.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}:\d+$/)
        );

        if (invalidProxies.length > 0) {
            setError(`Invalid proxy format found. Use IP:PORT format (e.g., 192.168.1.1:8080)`);
            return;
        }

        const updatedProxies = [...proxies, ...proxyLines];
        setProxies(updatedProxies);
        saveToStorage(STORAGE_KEYS.PROXIES, updatedProxies);
        setNewProxy('');
        addLog(`Added ${proxyLines.length} new proxies`);
    };

    const deleteAllProxies = () => {
        if (isProcessing) return;
        setProxies([]);
        saveToStorage(STORAGE_KEYS.PROXIES, []);
        addLog('All proxies deleted');
    };

    const addLog = (message, isError = false) => {
        const timestamp = new Date().toLocaleTimeString();
        const logMessage = `[${timestamp}] ${isError ? 'ERROR: ' : ''}${message}`;
        setLogs(prev => [logMessage, ...prev]);
    };

    const generateAccounts = (count) => {
        const newAccounts = Array(count).fill(null).map(() => ({
            keypair: Keypair.generate(),
            status: 'Ready',
            authToken: null
        }));
        setAccounts(newAccounts);
        addLog(`Generated ${count} new accounts`);
    };

    const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const signMessage = (account) => {
        const timestamp = Date.now();
        const message = `Sign in to pump.fun: ${timestamp}`;
        const messageBytes = new TextEncoder().encode(message);
        const signature = nacl.sign.detached(messageBytes, account.secretKey);
        const encodedSignature = bs58.encode(signature);
        return { timestamp, signature: encodedSignature, message };
    };

    const loginAccount = async (account, index) => {
        try {
            const signedData = signMessage(account);
            const accountPreview = account.publicKey.toString().slice(0, 8);
            addLog(`Account ${index + 1} (${accountPreview}...) initiating login...`);
            
            let proxyConfig = null;
            if (useProxies && proxies.length > 0) {
                const proxy = proxies[index % proxies.length];
                const [host, port] = proxy.split(':');
                proxyConfig = {
                    host,
                    port: parseInt(port),
                    protocol: 'http'
                };
                addLog(`Using proxy: ${host}:${port}`);
            }
        
            const response = await fetch(`${API_BASE}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    address: account.publicKey.toString(),
                    signature: signedData.signature,
                    timestamp: signedData.timestamp,
                    message: signedData.message,
                    proxy: proxyConfig
                })
            });
        
            const data = await response.json();
        
            if (!response.ok) {
                throw new Error(data.error || 'Login failed');
            }
        
            if (!data.authToken) {
                throw new Error('No auth token in response');
            }
        
            addLog(`Account ${index + 1} (${accountPreview}...) login successful`);
            return data.authToken;
        } catch (error) {
            addLog(`Account ${index + 1} login failed: ${error.message}`);
            throw error;
        }
    };

    const postComment = async (authToken, comment, accountIndex) => {
        if (stopRequested.current) {
            throw new Error('Operation stopped by user');
        }
    
        try {
            const accountPreview = accounts[accountIndex].keypair.publicKey.toString().slice(0, 8);
            let proxyConfig = null;
            if (useProxies && proxies.length > 0) {
                const proxy = proxies[accountIndex % proxies.length];
                const [host, port] = proxy.split(':');
                proxyConfig = {
                    host,
                    port: parseInt(port),
                    protocol: 'http'
                };
            }
    
            const captchaApiKey = localStorage.getItem('TWOCAPTCHA_API_KEY');
            if (!captchaApiKey) {
                throw new Error('2captcha API key not configured in settings');
            }
            
            addLog(`Account ${accountIndex + 1} (${accountPreview}...) posting comment...`);
            if (proxyConfig) {
                addLog(`Using proxy: ${proxyConfig.host}:${proxyConfig.port}`);
            }
            
            const response = await fetch(`${API_BASE}/api/post-comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-2Captcha-Key': captchaApiKey
                },
                body: JSON.stringify({
                    mint: mintAddress,
                    text: comment,
                    authToken,
                    proxy: proxyConfig
                })
            });
        
            const data = await response.json();
        
            if (!response.ok) {
                throw new Error(data.error || `Comment failed: ${response.status}`);
            }
        
            addLog(`Account ${accountIndex + 1} (${accountPreview}...) comment posted successfully`);
            return data;
        } catch (error) {
            addLog(`Account ${accountIndex + 1} comment failed: ${error.message}`);
            throw error;
        }
    };

    const handlePostComments = async () => {
        if (!mintAddress) {
            const errorMsg = 'Please set mint address.';
            setError(errorMsg);
            addLog(errorMsg, true);
            return;
        }

        if (comments.length === 0) {
            setError('Please add at least one comment before posting.');
            return;
        }

        if (proxies.length === 0) {
            setError('Please add at least one proxy before posting.');
            return;
        }

        if (isProcessing) {
            stopRequested.current = true;
            addLog('Stopping comment process...');
            return;
        }

        setIsProcessing(true);
        stopRequested.current = false;
        setError(null);
        addLog('Starting comment process...');
        addLog(`Using proxies: ${proxies.length} available`);

        try {
            addLog('Logging in all accounts...');
            const loginPromises = accounts.map(async (account, index) => {
                if (stopRequested.current) return { ...account, authToken: null };
                try {
                    const authToken = await loginAccount(account.keypair, index);
                    return { ...account, authToken };
                } catch (error) {
                    console.error(`Account ${index} login failed:`, error);
                    return { ...account, authToken: null };
                }
            });

            const loggedInAccounts = await Promise.all(loginPromises);
            setAccounts(loggedInAccounts);
            await wait(MIN_DELAY);

            let commentsToPost = [...comments];
            if (randomizeComments) {
                commentsToPost = commentsToPost.sort(() => Math.random() - 0.5);
            }

            let currentAccountIndex = 0;
            for (const comment of commentsToPost) {
                if (stopRequested.current) {
                    addLog('Comment process stopped by user');
                    break;
                }

                const account = loggedInAccounts[currentAccountIndex];
                if (account.authToken) {
                    try {
                        await postComment(account.authToken, comment, currentAccountIndex);
                        await wait(MIN_DELAY);
                    } catch (error) {
                        if (error.message === 'Operation stopped by user') {
                            break;
                        }
                        console.error(`Comment failed for account ${currentAccountIndex}:`, error);
                    }
                }
                currentAccountIndex = (currentAccountIndex + 1) % accounts.length;
            }

            if (!stopRequested.current) {
                addLog('All actions finished.');
            }
        } catch (error) {
            addLog(`Process failed: ${error.message}`);
            setError(`Process failed: ${error.message}`);
        } finally {
            setIsProcessing(false);
            stopRequested.current = false;
        }
    };

    return (
        <div className="comment-bot">
            <h1>Comment Bot</h1>

            <div className="comment-settings">  
                <div className="settings-info">
                    <div className="settings-row">
                        <div className="account-info">
                            <span className="account-count-label">Accounts:</span>
                        </div>
                        <div className="label-button">
                            <span className="account-count-value">{accountCount}</span>
                            <button 
                                className="edit-accounts-button"
                                onClick={() => setShowOverlay(true)}
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </button>
                        </div>
                    </div>
                    
                    <div className="settings-row">
                        <div className="comments-info">
                            <span className="comments-count-label">Comments:</span>
                        </div>
                        <div className="label-button">
                            <span className="comments-count-value">{comments.length}</span>
                            <button 
                                className="edit-accounts-button"
                                onClick={() => setShowCommentsOverlay(true)}
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </button>
                        </div>
                    </div>

                    <div className="settings-row">
                        <div className="proxy-info">
                            <span className="proxy-count-label">Proxies:</span>
                        </div>
                        <div className="label-button">
                            <span className="proxy-count-value">{proxies.length}</span>
                            <button 
                                className="edit-accounts-button"
                                onClick={() => setShowProxyOverlay(true)}
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={faNetworkWired} />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="terminal">
                    <div className="terminal-content">
                        {logs.map((log, index) => (
                            <div 
                                key={index} 
                                className={`terminal-line ${log.includes('ERROR:') ? 'error-log' : ''}`}
                            >
                                {log}
                            </div>
                        ))}
                    </div>
                </div>

                <button
                    className={`comment-start-stop-button ${isProcessing ? 'running' : ''} ${!mintAddress ? 'disabled' : ''}`}
                    onClick={handlePostComments}
                    disabled={comments.length === 0 || accounts.length === 0}
                >
                    <FontAwesomeIcon icon={isProcessing ? faStop : faPlay} />
                    <span>{isProcessing ? 'Stop' : 'Start'}</span>
                </button>
            </div>

            {showOverlay && (
                <div className="overlay">
                    <div className="comment-settings-overlay">
                        <h1>Account Settings</h1>
                        <div className="overlay-content">
                            <div className="settings-content">
                            <label>Set Number of Comment Accounts:</label>
                                <input
                                    type="number"
                                    min="1"
                                    max="10"
                                    value={accountCount}
                                    onChange={(e) => setAccountCount(parseInt(e.target.value))}
                                    className="account-input"
                                    disabled={isProcessing}
                                />
                            </div>
                            <div className="settings-actions">
                                <button
                                    className="save-button"
                                    onClick={() => {
                                        generateAccounts(accountCount);
                                        setShowOverlay(false);
                                    }}
                                    disabled={isProcessing}
                                >
                                    Update
                                </button>
                            </div>
                            <button 
                                className="close-button"
                                onClick={() => setShowOverlay(false)}
                            >
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>
                        </div>
                    </div>
                </div>
            )}

{showCommentsOverlay && (
    <div className="overlay">
        <div className="comment-settings-overlay">
            <h1>Comments List</h1>
            <div className="overlay-content">
                <div className="comments-list-view">
                    <div className="list-header">
                        <label className="randomize-toggle">
                            <input
                                type="checkbox"
                                checked={randomizeComments}
                                onChange={(e) => setRandomizeComments(e.target.checked)}
                                disabled={isProcessing}
                            />
                            <span className="randomize-toggle-label">Randomize comment order</span>
                        </label>
                    </div>
                    
                    <div className="add-item-form">
                        <textarea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Enter comments (one per line)"
                            className="item-input multiline"
                            rows={5}
                        />
                        <button 
                            onClick={addComments} 
                            className="add-item-button"
                            disabled={isProcessing}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>

                    <div className="items-container">
                        {comments.length > 0 && (
                        <div className="main-actions-title">Comments:</div>
                        )}
                        <div className="comment-items">
                        {comments.map((comment, index) => (
                            <div key={index} className="comment-item">
                                <span className="comment-number">{index + 1}.</span>
                                <span className="comment-text">{comment}</span>
                            </div>
                        ))}
                        </div>
                    </div>
                    {comments.length > 0 && (
                            <button 
                                onClick={deleteAllComments}
                                className="delete-all-button"
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                                <span></span>
                            </button>
                        )}
                </div>
                <button 
                    className="close-button"
                    onClick={() => setShowCommentsOverlay(false)}
                >
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </div>
        </div>
    </div>
)}

{showProxyOverlay && (
    <div className="overlay">
        <div className="proxy-settings-overlay">
            <h1>Proxy Settings</h1>
            <div className="overlay-content">
                <div className="settings-content">
                    <div className="proxy-list">
                        <div className="add-item-form">
                            <textarea
                                value={newProxy}
                                onChange={(e) => setNewProxy(e.target.value)}
                                placeholder="Enter proxies (one per line, IP:PORT format)"
                                className="item-input multiline"
                                rows={5}
                            />
                            <button 
                                onClick={addProxies} 
                                className="add-item-button"
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>

                        {proxies.length > 0 && (
                        <div className="main-actions-title">Loaded Proxies ({proxies.length})</div>
                        )}
                        <div className="proxy-items">
                            {proxies.map((proxy, index) => (
                                <div key={index} className="proxy-item">
                                    <span className="proxy-number">{index + 1}.</span>
                                    <span className="proxy-text">{proxy}</span>
                                </div>
                            ))}
                        </div>
                        {proxies.length > 0 && (
                                <button 
                                    onClick={deleteAllProxies}
                                    className="delete-all-button"
                                    disabled={isProcessing}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                    <span></span>
                                </button>
                            )}
                    </div>
                </div>
                <button 
                    className="close-button"
                    onClick={() => setShowProxyOverlay(false)}
                >
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </div>
        </div>
    </div>
)}
        </div>
    );
};

export default CommentBot;