import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  VersionedTransaction,
  Keypair,
  PublicKey,
  LAMPORTS_PER_SOL
} from '@solana/web3.js';
import * as bs58 from 'bs58';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../hooks/useToast';
import { handleError } from '../utils/errorUtils';
import { useCloseAccounts } from '../hooks/useCloseAccounts';
import Toast from './Toast';
import './TradePanel.css'

const JITO_API_URL = process.env.REACT_APP_JITO_API_URL || 'https://mainnet.block-engine.jito.wtf/api/v1/bundles';
const BUNDLE_SIZE = 5;
const LAUNCH_BUNDLE_SIZE = 4;

const TradePanel = ({ 
  connection,
  mintAddress, 
  transactionSettings, 
  bundleAirdropAmount, 
  launchBundleAirdropAmount, 
  availableBundleCount, 
  availableLaunchBundleCount,
  bundleWallets,
  setBundleWallets,
  launchBundleWallets,
  setLaunchBundleWallets,
  updateBundleWalletsBalances,
  updateLaunchBundleWalletsBalances,
  bundleSupplyPercentages,
  launchBundleSupplyPercentages,
  pool
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedWalletType, setSelectedWalletType] = useState('bundle');
  const [sellPercentage, setSellPercentage] = useState(100);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [selectedBundlesBuyAmount, setSelectedBundlesBuyAmount] = useState(0);
  const { toast, showToast, clearToast } = useToast();

  const { slippagePercent, jitoTip } = transactionSettings;

  const { processAllWallets } = useCloseAccounts(connection);

  useEffect(() => {
    const savedBundleWallets = JSON.parse(localStorage.getItem('bundleWallets') || '[]');
    const savedLaunchBundleWallets = JSON.parse(localStorage.getItem('launchBundleWallets') || '[]');
    setBundleWallets(savedBundleWallets);
    setLaunchBundleWallets(savedLaunchBundleWallets);
  }, []);

  useEffect(() => {
    const count = selectedWalletType === 'bundle' ? availableBundleCount : 1;
    setSelectedBundles(new Array(count).fill(selectedWalletType === 'launch'));
  }, [selectedWalletType, availableBundleCount]);

  const totalAirdropAmount = useMemo(() => {
    return selectedWalletType === 'bundle' ? bundleAirdropAmount : launchBundleAirdropAmount;
  }, [selectedWalletType, bundleAirdropAmount, launchBundleAirdropAmount]);

  const formatAirdropAmount = (amount) => {
    if (Number.isInteger(amount)) {
      return amount.toString();
    } else {
      return amount.toFixed(3);
    }
  };

  const calculateSelectedBundlesBuyAmount = useCallback(() => {
    if (selectedWalletType === 'bundle') {
      return selectedBundles.reduce((total, isSelected, index) => {
        if (isSelected) {
          const start = index * BUNDLE_SIZE;
          const end = Math.min(start + BUNDLE_SIZE, bundleWallets.length);
          const bundleAmount = bundleWallets.slice(start, end).reduce((sum, wallet) => sum + parseFloat(wallet.airdropAmount || 0), 0);
          return total + bundleAmount;
        }
        return total;
      }, 0);
    } else {
      return totalAirdropAmount;
    }
  }, [selectedWalletType, selectedBundles, bundleWallets, totalAirdropAmount]);

  useEffect(() => {
    setSelectedBundlesBuyAmount(calculateSelectedBundlesBuyAmount());
  }, [selectedWalletType, selectedBundles, bundleWallets, totalAirdropAmount, calculateSelectedBundlesBuyAmount]);

  const handleBundleSelection = (index) => {
    const newSelectedBundles = [...selectedBundles];
    newSelectedBundles[index] = !newSelectedBundles[index];
    setSelectedBundles(newSelectedBundles);
  };

  const handleSelectAllBundles = (event) => {
    const isChecked = event.target.checked;
    setSelectedBundles(new Array(selectedBundles.length).fill(isChecked));
  };

  const processBundle = async (action) => {
    setLoading(true);
  
    try {
      const selectedWallets = selectedWalletType === 'bundle' ? bundleWallets : launchBundleWallets;
      const bundleSize = selectedWalletType === 'bundle' ? BUNDLE_SIZE : LAUNCH_BUNDLE_SIZE;
      
      const bundlesToProcess = selectedWalletType === 'bundle'
        ? selectedBundles.reduce((acc, isSelected, index) => {
            if (isSelected) {
              const start = index * bundleSize;
              const end = Math.min(start + bundleSize, selectedWallets.length);
              acc.push(selectedWallets.slice(start, end));
            }
            return acc;
          }, [])
        : [selectedWallets];

      for (let i = 0; i < bundlesToProcess.length; i++) {
        const bundleWallets = bundlesToProcess[i];
        const bundledTxArgs = bundleWallets.map((wallet, index) => ({
          publicKey: wallet.publicKey,
          action: action,
          mint: mintAddress,
          denominatedInSol: action === 'buy' ? "true" : "false",
          amount: action === 'buy' ? wallet.airdropAmount : `${sellPercentage}%`,
          slippage: slippagePercent,
          priorityFee: jitoTip,
          pool: pool
        }));

        console.log(`Sending bundle ${i + 1} request to PumpPortal API`);
        const response = await fetch('https://pumpportal.fun/api/trade-local', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(bundledTxArgs)
        });

        if (!response.ok) {
          throw new Error(`Failed to generate transactions: ${response.statusText}`);
        }

        const transactions = await response.json();
        console.log(`Received transactions for bundle ${i + 1} from PumpPortal API`);

        const encodedSignedTransactions = transactions.map((tx, j) => {
          const transaction = VersionedTransaction.deserialize(new Uint8Array(bs58.decode(tx)));
          const keypair = Keypair.fromSecretKey(bs58.decode(bundleWallets[j].secretKey));
          transaction.sign([keypair]);
          return bs58.encode(transaction.serialize());
        });

        console.log(`All transactions signed successfully for bundle ${i + 1}`);

        const jitoResponse = await fetch(JITO_API_URL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [encodedSignedTransactions]
          })
        });

        const jitoResponseData = await jitoResponse.json();
        console.log(`Jito API Response for bundle ${i + 1}:`, jitoResponseData);

        if (jitoResponseData.error) {
          throw new Error(`Jito API Error: ${JSON.stringify(jitoResponseData.error)}`);
        }

        showToast(`Bundle ${i + 1} submitted successfully!`, 'success');

        encodedSignedTransactions.forEach((tx, j) => {
          const signature = VersionedTransaction.deserialize(bs58.decode(tx)).signatures[0];
          console.log(`Transaction ${j} in bundle ${i + 1}: https://solscan.io/tx/${bs58.encode(signature)}`);
        });

        // Add a delay between bundles if there are more to process
        if (i < bundlesToProcess.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
        }
      }

      console.log('All bundles submitted, starting balance polling...');
      setLoading(false);
      
      if (selectedWalletType === 'bundle') {
        await pollBalanceChanges(selectedWallets);
      } else {
        await pollLaunchBundleBalances(selectedWallets);
      }

      await new Promise(resolve => setTimeout(resolve, 1000));

      if (action === 'sell' && pool === 'raydium') {
        try {
          console.log('Starting to close empty token accounts...');
          const closedAccounts = await processAllWallets(
            selectedWallets,
            // Add callback to handle balance updates
            (updatedBalances) => {
              if (selectedWalletType === 'bundle') {
                setBundleWallets(prev => 
                  prev.map(wallet => {
                    const updated = updatedBalances.find(b => b.publicKey === wallet.publicKey);
                    return updated ? { ...wallet, balance: updated.balance } : wallet;
                  })
                );
              } else {
                setLaunchBundleWallets(prev =>
                  prev.map(wallet => {
                    const updated = updatedBalances.find(b => b.publicKey === wallet.publicKey);
                    return updated ? { ...wallet, balance: updated.balance } : wallet;
                  })
                );
              }
            }
          );
          if (closedAccounts > 0) {
            showToast(`Closed ${closedAccounts} empty token accounts`, 'success');
          }
        } catch (error) {
          console.error('Error closing token accounts:', error);
          showToast('Error closing some token accounts', 'error');
        }
      }

    } catch (error) {
      console.error('Error in processBundle:', error);
      handleError(error, showToast);
      setLoading(false);
    }
  };

  const pollBalanceChanges = async (wallets) => {
    // Get one representative wallet per bundle and track completion status
    const bundleTracking = [];
    const bundleSize = selectedWalletType === 'bundle' ? BUNDLE_SIZE : LAUNCH_BUNDLE_SIZE;
    
    selectedBundles.forEach((isSelected, bundleIndex) => {
      if (isSelected) {
        const start = bundleIndex * bundleSize;
        const representativeWallet = wallets[start];
        if (representativeWallet) {
          bundleTracking.push({
            wallet: representativeWallet,
            initialBalance: 0, // Will be set after first fetch
            completed: false,
            bundleIndex
          });
        }
      }
    });
  
    console.log(`Monitoring ${bundleTracking.length} bundle representatives`);
  
    // Fetch initial balances
    for (const bundle of bundleTracking) {
      const balance = await connection.getBalance(new PublicKey(bundle.wallet.publicKey));
      bundle.initialBalance = balance / LAMPORTS_PER_SOL;
    }
  
    console.log('Initial balances for bundle representatives:', 
      Object.fromEntries(bundleTracking.map(b => 
        [b.wallet.publicKey, b.initialBalance]
      ))
    );
  
    const maxAttempts = 40;
    let attempts = 0;
  
    return new Promise((resolve) => {
      const checkBalances = async () => {
        if (attempts >= maxAttempts) {
          console.log('Max polling attempts reached');
          await updateBundleWalletsBalances();
          resolve();
          return;
        }
  
        // Check each uncompleted bundle
        let newCompletions = false;
        for (const bundle of bundleTracking) {
          if (!bundle.completed) {
            const currentBalance = await connection.getBalance(new PublicKey(bundle.wallet.publicKey));
            const normalizedBalance = currentBalance / LAMPORTS_PER_SOL;
            
            if (Math.abs(normalizedBalance - bundle.initialBalance) > 0.000001) {
              console.log(`Bundle ${bundle.bundleIndex + 1} completed:`, {
                wallet: bundle.wallet.publicKey,
                initial: bundle.initialBalance,
                current: normalizedBalance
              });
              bundle.completed = true;
              newCompletions = true;
            }
          }
        }
  
        // Update balances if any new completions were detected
        if (newCompletions) {
          console.log('New bundle completion(s) detected, updating balances');
          await updateBundleWalletsBalances();
        }
  
        // Check if all bundles are completed
        const allCompleted = bundleTracking.every(bundle => bundle.completed);
        if (allCompleted) {
          console.log('All bundles completed');
          resolve();
          return;
        }
  
        attempts++;
        const incompleteBundles = bundleTracking
          .filter(b => !b.completed)
          .map(b => b.bundleIndex + 1);
        console.log(`Still waiting for bundles: ${incompleteBundles.join(', ')}, attempt ${attempts}/${maxAttempts}`);
        setTimeout(checkBalances, 3000);
      };
  
      checkBalances();
    });
  };

  const pollLaunchBundleBalances = async (launchBundleWallets) => {
    // New separate polling function for launch bundles
    console.log('Starting launch bundle balance polling');
    
    // Get initial balance of the first wallet
    const initialBalance = await connection.getBalance(new PublicKey(launchBundleWallets[0].publicKey));
    const normalizedInitialBalance = initialBalance / LAMPORTS_PER_SOL;
    
    console.log('Initial launch bundle balance:', {
      wallet: launchBundleWallets[0].publicKey,
      balance: normalizedInitialBalance
    });
  
    const maxAttempts = 40;
    let attempts = 0;
  
    return new Promise((resolve) => {
      const checkBalance = async () => {
        if (attempts >= maxAttempts) {
          console.log('Max polling attempts reached for launch bundle');
          await updateLaunchBundleWalletsBalances();
          resolve();
          return;
        }
  
        const currentBalance = await connection.getBalance(new PublicKey(launchBundleWallets[0].publicKey));
        const normalizedBalance = currentBalance / LAMPORTS_PER_SOL;
  
        if (Math.abs(normalizedBalance - normalizedInitialBalance) > 0.000001) {
          console.log('Launch bundle completed:', {
            wallet: launchBundleWallets[0].publicKey,
            initial: normalizedInitialBalance,
            current: normalizedBalance
          });
          await updateLaunchBundleWalletsBalances();
          resolve();
          return;
        }
  
        attempts++;
        console.log(`Waiting for launch bundle completion, attempt ${attempts}/${maxAttempts}`);
        setTimeout(checkBalance, 3000);
      };
  
      checkBalance();
    });
  };

  const handleSellPercentageChange = (e) => {
    const value = parseInt(e.target.value);
    setSellPercentage(Math.round(value / 25) * 25);
  };

  const formatSupplyPercentage = (percentage) => {
    if (percentage === 0) return '0%';
    if (percentage < 1) return percentage.toFixed(3) + '%';
    if (percentage < 10) return percentage.toFixed(2) + '%';
    return percentage.toFixed(1) + '%';
  };

  const calculateTotalSupplyPercentage = () => {
    const total = Object.values(bundleSupplyPercentages)
      .reduce((sum, percentage) => sum + (percentage || 0), 0);
    return formatSupplyPercentage(total);
  };

  return (
    <div className="bundle-panel">
      <h1>Trade</h1>
      <div className="wallet-type-tabs">
        <button
          className={`tab-button-bundle ${selectedWalletType === 'bundle' ? 'active' : ''}`}
          onClick={() => setSelectedWalletType('bundle')}
        >
          Bundle
        </button>
        <button
          className={`tab-button-launch-bundle ${selectedWalletType === 'launch' ? 'active' : ''}`}
          onClick={() => setSelectedWalletType('launch')}
        >
          Launch Bundle
        </button>
      </div>

      {selectedWalletType === 'bundle' && (
        <div className="bundle-selection">
          {selectedBundles.length > 0 ? (
            <>
              <div className="bundle-select-all">
                <input
                  type="checkbox"
                  id="select-all-bundles"
                  checked={selectedBundles.every(Boolean)}
                  onChange={handleSelectAllBundles}
                  className="sr-only"
                />
                <label 
                  htmlFor="select-all-bundles"
                  className={`custom-checkbox ${selectedBundles.every(Boolean) ? 'checked' : ''}`}
                >
                  <div className="checkbox-content">
                    <span className="bundle-number">All</span>
                    {Object.values(bundleSupplyPercentages).some(percentage => percentage > 0) && (
                      <span className="bundle-supply">
                        {formatSupplyPercentage(
                          Object.values(bundleSupplyPercentages)
                            .reduce((sum, percentage) => sum + (percentage || 0), 0)
                        )}
                      </span>
                    )}
                  </div>
                </label>
              </div>
              <div className="bundle-list">
                {selectedBundles.map((isSelected, index) => (
                  <div key={index} className="bundle-item">
                    <input
                      type="checkbox"
                      id={`bundle-${index}`}
                      checked={isSelected}
                      onChange={() => handleBundleSelection(index)}
                    />
                    <label 
                      htmlFor={`bundle-${index}`}
                      className={`custom-checkbox ${isSelected ? 'checked' : ''}`}
                    >
                      <div className="checkbox-content">
                        <span className="bundle-number">{index + 1}</span>
                        {bundleSupplyPercentages[index] > 0 && (
                          <span className="bundle-supply">
                            {formatSupplyPercentage(bundleSupplyPercentages[index])}
                          </span>
                        )}
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="bundle-list">
              <div className="launch-bundle-supply">
                  <span className="supply-label">No bundles found</span>
                </div>
            </div>
          )}
        </div>
      )}

{selectedWalletType === 'launch' && launchBundleWallets && (
  <div className="bundle-selection">
    {launchBundleWallets.length > 0 ? (
      <div className="bundle-list launch-bundle-info">
        <div className="launch-bundle-supply">
          <span className="supply-label">Total Supply Holding: </span>
          <span className="supply-value">
            {launchBundleSupplyPercentages[0] > 0
              ? formatSupplyPercentage(launchBundleSupplyPercentages[0])
              : '0%'
            }
          </span>
        </div>
      </div>
    ) : (
      <div className="bundle-list">
        <div className="launch-bundle-supply">
          <span className="supply-label">No bundles found</span>
        </div>
      </div>
    )}
  </div>
)}
      <div className="action-buttons">
        <div className='trade-panel-buy'>
          <div className='total-airdrop-amount'>
            {formatAirdropAmount(selectedBundlesBuyAmount)} SOL
          </div>
          <div className='bundle-buy-button'>
            <button onClick={() => processBundle('buy')} disabled={loading || (selectedWalletType === 'bundle' && !selectedBundles.some(Boolean))}>
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Buy'}
            </button>
          </div>
        </div>
        <div className='trade-panel-sell'>
          <div className='dev-sell-slider'>
            <input
              type="range"
              min="0"
              max="100"
              step="25"
              value={sellPercentage}
              onChange={handleSellPercentageChange}
              className="slider"
            />
          </div>
          <div className='dev-sell-button'>
            <button onClick={() => processBundle('sell')} disabled={loading || (selectedWalletType === 'bundle' && !selectedBundles.some(Boolean))}>
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : `Sell ${sellPercentage}%`}
            </button>
          </div>
        </div>
      </div>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={clearToast}
        />
      )}
    </div>
  );
};

export default TradePanel;