// useTokenSupply.js
import { useState, useEffect } from 'react';
import { PublicKey } from '@solana/web3.js';

const useTokenSupply = (mintAddress, connection) => {
  const [tokenSupply, setTokenSupply] = useState(0);

  useEffect(() => {
    const fetchTokenSupply = async () => {
      if (!mintAddress || !connection) {
        console.log('Token supply fetch skipped - missing mint address');
        return;
      }

      try {
        console.log(`Fetching token supply for mint address: ${mintAddress}`);
        const mintPubkey = new PublicKey(mintAddress);
        const supply = await connection.getTokenSupply(mintPubkey);
        const totalSupply = parseFloat(supply.value.amount) / (10 ** supply.value.decimals);
        console.log('Token supply details:', {
          rawAmount: supply.value.amount,
          decimals: supply.value.decimals,
          calculatedSupply: totalSupply.toLocaleString(),
          previousSupply: tokenSupply.toLocaleString()
        });
        setTokenSupply(totalSupply);
      } catch (err) {
        console.error('Error fetching token supply:', err);
      }
    };

    fetchTokenSupply();
  }, [mintAddress, connection]);

  return { tokenSupply };
};

export default useTokenSupply;