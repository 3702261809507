import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export const handleError = (error, showToast, setTokenExists) => {
  let errorMessage = 'An unknown error occurred';
 
  if (error instanceof Error) {
    if (error.name === 'TransactionExpiredBlockheightExceededError' ||
        error.message.includes('TransactionExpiredBlockheightExceededError')) {
      errorMessage = "Transaction expired. Please try again.";
    } else if (error.message.includes('custom program error: 0x1')) {
      if (error.message.includes('insufficient lamports')) {
        const match = error.message.match(/insufficient lamports (\d+), need (\d+)/);
        if (match) {
          const [, available, needed] = match;
          const availableSol = Number(available) / LAMPORTS_PER_SOL;
          const neededSol = Number(needed) / LAMPORTS_PER_SOL;
          errorMessage = `Insufficient funds. Available: ${availableSol.toFixed(3)} SOL, Required: ${neededSol.toFixed(2)} SOL`;
        } else {
          errorMessage = 'Insufficient funds to complete the transaction.';
        }
      } else {
        errorMessage = 'Transaction failed due to a program error. Please try again.';
      }
    } else if (error.message.includes('custom program error: 0x0')) {
      errorMessage = 'Token creation failed: The token already exists.';
      if (setTokenExists) {
        setTokenExists(true);
      }
    } else if (error.message.includes('Transaction simulation failed')) {
      errorMessage = 'Transaction simulation failed. Please try again.';
    } else if (error.message.includes('connection')) {
      errorMessage = 'No connection available, please check your RPC URL.';
    } else {
      errorMessage = `Error: ${error.message}`;
    }
  }
  showToast(errorMessage, 'error', 10000); // Display for 10 seconds
};