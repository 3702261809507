import React, { useState, useEffect, useCallback } from 'react';
import { Keypair, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import bs58 from 'bs58';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCopy, faDownload, faTrashCan, faExclamationTriangle, faRotate, faEdit, faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../hooks/useToast';
import { handleError } from '../utils/errorUtils';
import { truncateAddress, copyToClipboard } from '../utils/formatUtils';
import Toast from './Toast';
import './BundleWalletPanel.css';

const WALLETS_PER_GROUP = 5;
const MAX_BUNDLES = 6;

const BundleWalletPanel = ({ connection, onAirdropAmountChange, onBundleCountChange, updateBundleWallets, bundleWallets, mintAddress, onBundleSupplyUpdate, tokenSupply }) => {
  const [loading, setLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showBundleDeleteConfirmation, setShowBundleDeleteConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [totalSol, setTotalSol] = useState('');
  const [bundleCount, setBundleCount] = useState('');
  const [tokenBalances, setTokenBalances] = useState({});
  const [currentGroup, setCurrentGroup] = useState(0);
  const [bundleSupplyPercentages, setBundleSupplyPercentages] = useState({});
  const [refreshingBalances, setRefreshingBalances] = useState({});
  const [showEditAirdropOverlay, setShowEditAirdropOverlay] = useState(false);
  const [newTotalSol, setNewTotalSol] = useState('');
  const [variationPercentage, setVariationPercentage] = useState(10);
  const [rebalanceVariationPercentage, setRebalanceVariationPercentage] = useState(10);
  const { toast, showToast, clearToast } = useToast();

  useEffect(() => {
    loadWalletsFromLocalStorage();
  }, []);

  useEffect(() => {
    const totalAirdropAmount = bundleWallets.reduce((sum, wallet) => sum + (parseFloat(wallet.airdropAmount) || 0), 0);
    onAirdropAmountChange(totalAirdropAmount);
    const bundleCount = Math.floor(bundleWallets.length / WALLETS_PER_GROUP);
    onBundleCountChange(bundleCount);
  }, [bundleWallets, onAirdropAmountChange, onBundleCountChange]);

  useEffect(() => {
    if (mintAddress && bundleWallets.length > 0) {
      fetchTokenBalances();
    }
  }, [mintAddress, bundleWallets]);

  const fetchTokenBalance = async (publicKey) => {
    if (!mintAddress) return 0;
    
    try {
      // Get token decimals first
      const mintInfo = await connection.getParsedAccountInfo(new PublicKey(mintAddress));
      if (!mintInfo.value || !mintInfo.value.data || !mintInfo.value.data.parsed) {
        console.debug('Unable to parse mint info');
        return 0;
      }
      const tokenDecimals = mintInfo.value.data.parsed.info.decimals;
  
      // Get token account address
      const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(mintAddress),
        new PublicKey(publicKey)
      );
      
      try {
        const accountInfo = await getAccount(connection, tokenAccount);
        return Number(accountInfo.amount) / Math.pow(10, tokenDecimals);
      } catch (error) {
        // Handle specific token account errors silently
        if (
          error.name === 'TokenAccountNotFoundError' || 
          error.message?.includes('TokenAccountNotFound') ||
          error.message?.includes('Account does not exist') ||
          error.message?.includes('Failed to find account')
        ) {
          return 0;
        }
  
        console.debug('Token account error:', {
          error: error.message,
          publicKey,
          mintAddress
        });
        return 0;
      }
    } catch (error) {
      console.debug('Error fetching token info:', {
        error: error.message,
        publicKey,
        mintAddress
      });
      return 0;
    }
  };

  const fetchTokenBalances = async () => {
    const balances = {};
    for (const wallet of bundleWallets) {
      balances[wallet.publicKey] = await fetchTokenBalance(wallet.publicKey);
    }
    setTokenBalances(balances);
  };

  const loadWalletsFromLocalStorage = async () => {
    const savedWallets = JSON.parse(localStorage.getItem('bundleWallets') || '[]');
    const walletsWithBalance = await Promise.all(savedWallets.map(async (wallet) => ({
      ...wallet,
      balance: await fetchBalance(wallet.publicKey),
    })));
    updateBundleWallets(walletsWithBalance);
  };

  const saveWalletsToLocalStorage = (updatedWallets) => {
    const walletsToSave = updatedWallets.map(({ publicKey, secretKey, airdropAmount }) => 
      ({ publicKey, secretKey, airdropAmount })
    );
    localStorage.setItem('bundleWallets', JSON.stringify(walletsToSave));
    updateBundleWallets(updatedWallets);
  };

  const fetchBalance = async (publicKey) => {
    try {
      const balance = await connection.getBalance(new PublicKey(publicKey));
      return balance / LAMPORTS_PER_SOL;
    } catch (error) {
      console.error('Error fetching balance:', error);
      return 0;
    }
  };

  const generateNewWallets = async () => {
    setLoading(true);
    try {
      const solAmount = parseFloat(totalSol);
      const bundles = parseInt(bundleCount);
      const variation = parseFloat(variationPercentage) / 100;
      
      if (isNaN(solAmount) || isNaN(bundles) || solAmount <= 0 || bundles <= 0 || bundles > MAX_BUNDLES) {
        throw new Error('Invalid input values');
      }
  
      if (isNaN(variation) || variation < 0 || variation > 1) {
        throw new Error('Invalid variation percentage');
      }
  
      const walletCount = bundles * WALLETS_PER_GROUP;
      const newWallets = [];
      let remainingSol = solAmount;
      const baseAmount = solAmount / walletCount;
      // Set a reasonable minimum amount (e.g., 20% of base amount)
      const minAmount = baseAmount * Math.max(0.2, 1 - variation);
  
      // First pass: calculate initial distribution
      const initialAmounts = [];
      for (let i = 0; i < walletCount - 1; i++) {
        const maxAmount = Math.min(
          baseAmount * (1 + variation),
          remainingSol - minAmount * (walletCount - i - 1)
        );
        const amount = Number((minAmount + Math.random() * (maxAmount - minAmount)).toFixed(3));
        initialAmounts.push(amount);
        remainingSol -= amount;
      }
      // Last wallet gets remaining amount
      initialAmounts.push(Number(remainingSol.toFixed(3)));
  
      // Create wallets with the calculated amounts
      for (let i = 0; i < walletCount; i++) {
        const newKeypair = Keypair.generate();
        const publicKey = newKeypair.publicKey.toBase58();
        const secretKey = bs58.encode(newKeypair.secretKey);
        
        newWallets.push({
          publicKey,
          secretKey,
          airdropAmount: initialAmounts[i],
          balance: 0
        });
      }
  
      const updatedWallets = [...bundleWallets, ...newWallets];
      updateBundleWallets(updatedWallets);
      saveWalletsToLocalStorage(updatedWallets);
      showToast(`${bundles} new bundle${bundles > 1 ? 's' : ''} generated successfully!`, 'success');
      setShowOverlay(false);
    } catch (error) {
      handleError(error, showToast);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = (text) => {
    copyToClipboard(
      text,
      (message) => showToast(message, 'success', 3000),
      (message) => showToast(message, 'error', 3000)
    );
  };

  const exportWallets = () => {
    const walletsData = bundleWallets.map(({ publicKey, secretKey, airdropAmount }) => 
      ({ publicKey, secretKey, airdropAmount })
    );
    const jsonData = JSON.stringify(walletsData, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'bundle_wallets.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    showToast('Wallets exported successfully!', 'success');
  };

  const handleDeleteConfirmation = () => {
    if (deleteConfirmation.toLowerCase() === 'confirm') {
      deleteAllWallets();
      setShowDeleteConfirmation(false);
      setDeleteConfirmation('');
      setDeleteError('');
    } else {
      setDeleteError('Please type "confirm" to delete all wallets.');
    }
  };

  const deleteAllWallets = () => {
    updateBundleWallets([]);
    localStorage.removeItem('bundleWallets');
    showToast('All wallets deleted successfully!', 'success');
  };

  const groupCount = Math.ceil(bundleWallets.length / WALLETS_PER_GROUP);
  const currentGroupWallets = bundleWallets.slice(
    currentGroup * WALLETS_PER_GROUP,
    (currentGroup + 1) * WALLETS_PER_GROUP
  );

  const handleBundleCountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= MAX_BUNDLES)) {
      setBundleCount(value);
    }
  };

  const calculateSupplyPercentage = (tokenBalance) => {
    if (!tokenBalance || tokenBalance === 0 || !tokenSupply) return 0;
    return ((tokenBalance / tokenSupply) * 100).toFixed(3);
  };

  const formatTokenBalance = (balance) => {
    if (!balance || balance === 0) return '0';
    
    if (balance >= 1_000_000) {
      return `${(balance / 1_000_000).toFixed(2)}M`;
    }
    
    if (balance >= 1_000) {
      return `${(balance / 1_000).toFixed(2)}k`;
    }
    
    return Math.floor(balance).toString();
  };

  const calculateBundleSupplyPercentages = useCallback(() => {
    if (!mintAddress) {
      const emptyPercentages = {};
      for (let i = 0; i < Math.ceil(bundleWallets.length / WALLETS_PER_GROUP); i++) {
        emptyPercentages[i] = 0;
      }
      return emptyPercentages;
    }
  
    const newBundlePercentages = {};
    
    for (let i = 0; i < Math.ceil(bundleWallets.length / WALLETS_PER_GROUP); i++) {
      const bundleWalletsSlice = bundleWallets.slice(i * WALLETS_PER_GROUP, (i + 1) * WALLETS_PER_GROUP);
      const totalPercentage = bundleWalletsSlice.reduce((sum, wallet) => {
        const tokenBalance = tokenBalances[wallet.publicKey] || 0;
        // Now using proper decimal-adjusted values
        return sum + ((tokenBalance / tokenSupply) * 100);
      }, 0);
      
      newBundlePercentages[i] = parseFloat(totalPercentage.toFixed(10));
    }
    
    return newBundlePercentages;
  }, [bundleWallets, tokenBalances, mintAddress, tokenSupply]);

  // Separate useEffect for updating local state
useEffect(() => {
  const percentages = calculateBundleSupplyPercentages();
  setBundleSupplyPercentages(percentages);
}, [calculateBundleSupplyPercentages]);

// Separate useEffect for notifying parent
useEffect(() => {
  onBundleSupplyUpdate(bundleSupplyPercentages);
}, [bundleSupplyPercentages, onBundleSupplyUpdate]);

const refreshWalletBalances = async (walletPublicKey) => {

  if (!connection) {
    showToast('No connection available, please check your RPC URL', 'error');
    return;
  }
  
  setRefreshingBalances(prev => ({ ...prev, [walletPublicKey]: true }));
  try {
    const newBalance = await fetchBalance(walletPublicKey);
    const newTokenBalance = await fetchTokenBalance(walletPublicKey);
    
    const updatedWallets = bundleWallets.map(wallet => {
      if (wallet.publicKey === walletPublicKey) {
        return {
          ...wallet,
          balance: newBalance,
          tokenBalance: newTokenBalance
        };
      }
      return wallet;
    });
    
    updateBundleWallets(updatedWallets);
    showToast('Balances updated successfully!', 'success', 2000);
  } catch (error) {
    console.error('Error refreshing balances:', error);
    showToast('Failed to refresh balances', 'error');
  } finally {
    setTimeout(() => {
      setRefreshingBalances(prev => ({ ...prev, [walletPublicKey]: false }));
    }, 1000); // Adjust the delay time as needed (1000ms)
  }
};

const redistributeAirdropAmounts = async () => {
  setLoading(true);
  try {
    const solAmount = parseFloat(newTotalSol);
    const variation = parseFloat(rebalanceVariationPercentage) / 100;
    
    if (isNaN(solAmount) || solAmount <= 0) {
      throw new Error('Please enter a valid SOL amount greater than 0.');
    }

    if (isNaN(variation) || variation < 0 || variation > 1) {
      throw new Error('Invalid variation percentage');
    }

    const walletCount = bundleWallets.length;
    let remainingSol = solAmount;
    const baseAmount = solAmount / walletCount;
    // Set a reasonable minimum amount (e.g., 20% of base amount)
    const minAmount = baseAmount * Math.max(0.2, 1 - variation);

    // First pass: calculate initial distribution
    const initialAmounts = [];
    for (let i = 0; i < walletCount - 1; i++) {
      const maxAmount = Math.min(
        baseAmount * (1 + variation),
        remainingSol - minAmount * (walletCount - i - 1)
      );
      const amount = Number((minAmount + Math.random() * (maxAmount - minAmount)).toFixed(3));
      initialAmounts.push(amount);
      remainingSol -= amount;
    }
    // Last wallet gets remaining amount
    initialAmounts.push(Number(remainingSol.toFixed(3)));

    // Update wallets with new amounts
    const updatedWallets = bundleWallets.map((wallet, index) => ({
      ...wallet,
      airdropAmount: initialAmounts[index]
    }));

    updateBundleWallets(updatedWallets);
    saveWalletsToLocalStorage(updatedWallets);
    showToast('Airdrop amounts rebalanced successfully!', 'success');
    setShowEditAirdropOverlay(false);
    setNewTotalSol('');
  } catch (error) {
    handleError(error, showToast);
  } finally {
    setLoading(false);
  }
};


const handleImportWallets = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
    const text = await file.text();
    const importedWallets = JSON.parse(text);
    
    const validWallets = importedWallets.filter(wallet => 
      wallet.publicKey && 
      wallet.secretKey && 
      typeof wallet.airdropAmount === 'number'
    );

    if (validWallets.length === 0) {
      throw new Error('No valid wallets found in import file');
    }

    const maxWallets = 30; // 6 bundles * 5 wallets

    if (validWallets.length > maxWallets) {
      throw new Error(`Cannot import more than ${maxWallets} wallets`);
    }

    const walletsWithBalance = await Promise.all(validWallets.map(async (wallet) => ({
      ...wallet,
      balance: await fetchBalance(wallet.publicKey),
    })));

    const updatedWallets = [...bundleWallets, ...walletsWithBalance];

    if (updatedWallets.length > maxWallets) {
      throw new Error(`Total number of wallets cannot exceed ${maxWallets}`);
    }

    updateBundleWallets(updatedWallets);

    saveWalletsToLocalStorage(updatedWallets);
    showToast(`Successfully imported ${validWallets.length} wallets`, 'success');
  } catch (error) {
    handleError(error, showToast);
  }

  event.target.value = '';
};

const quickAddBundle = async () => {
  if (bundleWallets.length >= 30) { // 6 bundles * 5 wallets = 30 max wallets
    showToast('Maximum number of bundles (6) reached', 'error');
    return;
  }

  setLoading(true);
  try {
    // Calculate total current SOL
    const currentTotalSol = bundleWallets.reduce((sum, wallet) => sum + wallet.airdropAmount, 0);
    
    // Generate 5 new wallets for the new bundle
    const newWallets = [];
    for (let i = 0; i < WALLETS_PER_GROUP; i++) {
      const newKeypair = Keypair.generate();
      newWallets.push({
        publicKey: newKeypair.publicKey.toBase58(),
        secretKey: bs58.encode(newKeypair.secretKey),
        airdropAmount: 0, // Will be set during redistribution
        balance: 0
      });
    }

    const updatedWallets = [...bundleWallets, ...newWallets];
    
    // Redistribute SOL amounts across all wallets
    await redistributeAmount(updatedWallets, currentTotalSol);
    
    showToast('New bundle added and SOL rebalanced successfully!', 'success');
  } catch (error) {
    handleError(error, showToast);
  } finally {
    setLoading(false);
  }
};

const deleteCurrentBundle = async () => {
  if (bundleWallets.length === 0) return;

  const startIndex = currentGroup * WALLETS_PER_GROUP;
  const endIndex = startIndex + WALLETS_PER_GROUP;
  
  // Calculate total SOL before deletion
  const currentTotalSol = bundleWallets.reduce((sum, wallet) => sum + wallet.airdropAmount, 0);
  
  // Remove the current bundle's wallets
  const updatedWallets = [
    ...bundleWallets.slice(0, startIndex),
    ...bundleWallets.slice(endIndex)
  ];

  if (updatedWallets.length === 0) {
    // If we're deleting the last bundle, just clear everything
    updateBundleWallets([]);
    localStorage.removeItem('bundleWallets');
    showToast('Bundle deleted successfully!', 'success');
    return;
  }

  // Redistribute the total SOL among remaining wallets
  await redistributeAmount(updatedWallets, currentTotalSol);
  
  // Update current group if necessary
  const newGroupCount = Math.ceil(updatedWallets.length / WALLETS_PER_GROUP);
  if (currentGroup >= newGroupCount) {
    setCurrentGroup(newGroupCount - 1);
  }

  showToast('Bundle deleted and SOL rebalanced successfully!', 'success');
};

const redistributeAmount = async (wallets, totalSol) => {
  const variation = 0.1; // 10% variation
  const walletCount = wallets.length;
  let remainingSol = totalSol;
  const baseAmount = totalSol / walletCount;
  const minAmount = baseAmount * Math.max(0.2, 1 - variation);

  // Calculate distribution
  const amounts = [];
  for (let i = 0; i < walletCount - 1; i++) {
    const maxAmount = Math.min(
      baseAmount * (1 + variation),
      remainingSol - minAmount * (walletCount - i - 1)
    );
    const amount = Number((minAmount + Math.random() * (maxAmount - minAmount)).toFixed(3));
    amounts.push(amount);
    remainingSol -= amount;
  }
  amounts.push(Number(remainingSol.toFixed(3)));

  // Update wallets with new amounts
  const updatedWallets = wallets.map((wallet, index) => ({
    ...wallet,
    airdropAmount: amounts[index]
  }));

  updateBundleWallets(updatedWallets);
  saveWalletsToLocalStorage(updatedWallets);
};

const handleDeleteBundleConfirmation = () => {
  setShowBundleDeleteConfirmation(false);
  deleteCurrentBundle();
};

const TabNavigation = ({ groupCount, currentGroup, setCurrentGroup }) => {
  return (
    <div className="tab-navigation">
      <button 
        onClick={() => setShowBundleDeleteConfirmation(true)}
        disabled={loading || bundleWallets.length === 0}
        className="tab"
      >
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : '-'}
      </button>
      <div className="tab-controls">
        {[...Array(groupCount)].map((_, index) => (
          <button
            key={index}
            className={`tab ${currentGroup === index ? 'active' : ''}`}
            onClick={() => setCurrentGroup(index)}
          >
            {index + 1}
          </button>
        ))}
      </div>
        <button 
          onClick={quickAddBundle}
          disabled={loading}
          className="tab"
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : '+'}
        </button>
    </div>
  );
};


  return (
    <div className="bundle-wallet-panel">
      <h1>Bundle Wallets</h1>
      <div className="wallet-list">
        {currentGroupWallets.map((wallet, index) => (
          <div key={wallet.publicKey} className="info-section">
            <p>
              <span className="label">Wallet:</span>
              <span className="value">
                <span
                  className="truncated-address"
                  onClick={() => handleCopyToClipboard(wallet.publicKey)}
                  title={wallet.publicKey}
                >
                  {truncateAddress(wallet.publicKey)}
                </span>
                <FontAwesomeIcon icon={faCopy} className="copy-icon" />
              </span>
            </p>
            <div className="balance-actions-refresh">
            <div className="balances-actions">
              <div className="balance-airdrop">
                <div className="bundle-balance">
                  <span className="sol-balance">
                    <span className="label">Balance:</span>
                    <span className="value">{wallet.balance !== undefined ? 
                        `${wallet.balance.toFixed(3)} SOL` : 
                        '0.000 SOL' }</span>
                  </span>
                </div>
                <div className="airdrop-sol">
                  <span className="label">Airdrop:</span>
                  <span className="value">{wallet.airdropAmount.toFixed(3)} SOL</span>
                </div>
              </div>
              <div className='balance-divider-bundle'></div>
                <div className="tokens-percentage">
                  <div className="bundle-tokens">
                      <span className="token-balance">
                        <span className="label">Tokens:</span>
                        {mintAddress ? (
                          <span className="value">
                          {tokenBalances[wallet.publicKey] !== undefined 
                            ? formatTokenBalance(tokenBalances[wallet.publicKey])
                            : '0'
                          }
                        </span>
                        ) : (
                        <span className="value">0</span>
                        )}
                      </span>
                    </div>
                  <div className="bundle-wallet-percentage">
                    <span className="token-balance">
                    <span className="label">Supply:</span>
                    <span className="value">
                      {mintAddress && tokenBalances[wallet.publicKey] 
                        ? `${calculateSupplyPercentage(tokenBalances[wallet.publicKey])}%`
                          : '-'
                        }
                    </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="refresh-balance">
                  <button
                    className="refresh-button"
                    onClick={() => refreshWalletBalances(wallet.publicKey)}
                    disabled={refreshingBalances[wallet.publicKey]}
                  >
                  <FontAwesomeIcon 
                    icon={faRotate} 
                    spin={refreshingBalances[wallet.publicKey]}
                    className="refresh-icon"
                  />
                  </button>
              </div>
            </div>
            </div>
          ))}
      </div>
      {showOverlay && (
    <div className="overlay">
      <div className="overlay-content">
        <h1>Generate Bundles</h1>
        <label>Amount to airdrop:</label>
        <input
          type="number"
          placeholder="SOL"
          value={totalSol}
          onChange={(e) => setTotalSol(e.target.value)}
        />
        <label>Number of bundles (1-{MAX_BUNDLES}):</label>
        <input
          type="number"
          placeholder="#"
          value={bundleCount}
          onChange={handleBundleCountChange}
          min="1"
          max={MAX_BUNDLES}
        />
        <label>Distribution variation (%):</label>
        <div className="variation-input">
          <input
            type="number"
            placeholder="Variation %"
            value={variationPercentage}
            onChange={(e) => {
              const value = Math.max(0, Math.min(100, Number(e.target.value)));
              setVariationPercentage(value);
            }}
            min="0"
            max="100"
            step="1"
          />
        </div>
        <p className="variation-help">
          Higher variation = more random distribution between wallets.
          0% = equal distribution, 100% = highly varied.
        </p>
        <div className="overlay-actions">
          <button onClick={generateNewWallets} disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Generate'}
          </button>
          <button className="close-button" onClick={() => {
            setShowOverlay(false);
          }}><FontAwesomeIcon icon={faTimes} /></button>
        </div>
      </div>
    </div>
  )}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={clearToast}
        />
      )}
      {showDeleteConfirmation && (
        <div className="overlay">
          <h1>Confirm Deletion</h1>
          <div className="overlay-content">
            <p>Type "confirm" to delete all wallets.</p>
            <p className="warning-text">WARNING: This action is irreversable, make sure to back up your private keys!</p>
            <input
              type="text"
              value={deleteConfirmation}
              onChange={(e) => {
                setDeleteConfirmation(e.target.value);
                setDeleteError(''); // Clear error when user types
              }}
              placeholder="Type 'confirm'"
              className={deleteError ? 'error-input' : ''}
            />
            {deleteError && (
              <div className="error-message">
                <FontAwesomeIcon icon={faExclamationTriangle} /> {deleteError}
              </div>
            )}
            <div className="overlay-actions">
              <button onClick={handleDeleteConfirmation}>Delete</button>
              <button className="close-button" onClick={() => {
                setShowDeleteConfirmation(false);
                setDeleteConfirmation('');
                setDeleteError('');
              }}><FontAwesomeIcon icon={faTimes} /></button>
            </div>
          </div>
        </div>
      )}

  {showEditAirdropOverlay && (
    <div className="overlay">
      <h1>Edit Airdrop Amounts</h1>
      <div className="overlay-content">
        <label>New total SOL amount:</label>
        <input
          type="number"
          placeholder="SOL"
          value={newTotalSol}
          onChange={(e) => setNewTotalSol(e.target.value)}
          min="0"
          step="0.001"
        />
        <label>Distribution variation (%):</label>
        <div className="variation-input">
          <input
            type="number"
            placeholder="Variation %"
            value={rebalanceVariationPercentage}
            onChange={(e) => {
              const value = Math.max(0, Math.min(100, Number(e.target.value)));
              setRebalanceVariationPercentage(value);
            }}
            min="0"
            max="100"
            step="1"
          />
        </div>
        <p className="variation-help">
          Higher variation = more random distribution between wallets.
          0% = equal distribution, 100% = highly varied.
        </p>
        <div className="overlay-actions">
          <button onClick={redistributeAirdropAmounts} disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Rebalance'}
          </button>
          <button className="close-button" onClick={() => {
            setShowEditAirdropOverlay(false);
            setNewTotalSol('');
          }}><FontAwesomeIcon icon={faTimes} /></button>
        </div>
      </div>
    </div>
  )}

{showBundleDeleteConfirmation && (
  <div className="overlay">
    <div className="overlay-content">
      <h1>Delete Bundle {currentGroup + 1}?</h1>
      <p>This will delete all 5 wallets in the current bundle.</p>
      <p className="warning-text">WARNING: This action is irreversable, make sure to back up your private keys!</p>
      <div className="overlay-actions">
        <button 
          onClick={handleDeleteBundleConfirmation}
          disabled={loading}
          className="delete-button"
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Delete'}
        </button>
        <button onClick={() => setShowBundleDeleteConfirmation(false)}>
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      {bundleWallets.length > 0 && (
          <TabNavigation
            groupCount={groupCount}
            currentGroup={currentGroup}
            setCurrentGroup={setCurrentGroup}
          />
        )}
      {bundleWallets.length < 1 && (
        <div className="no-wallets">
          <div className="wallet-placeholder"></div>
          <div className="wallet-placeholder"></div>
          <div className="wallet-placeholder"></div>
        </div>
        )}
        {bundleWallets.length < 1 && (
        <div className="wallet-placeholder-bundle">
            <div className="tab-placeholder"></div>
            <div className="tab-placeholder"></div>
            <div className="tab-placeholder"></div>
        </div>
      )}
      {bundleWallets.length === 0 ? (
        <div className="generate-import">
        <button onClick={() => setShowOverlay(true)} className="generate-button">
          Generate Wallets
        </button>
        <label className="import-button">
        <FontAwesomeIcon icon={faUpload} />
          <input
            type="file"
            accept=".json"
            onChange={handleImportWallets}
            style={{ display: 'none' }}
          />
        </label>
        </div>
      ) : (
        <div className="export-delete-wallets">
          <button onClick={exportWallets} className="export-button">
            <FontAwesomeIcon icon={faDownload} /> Export
          </button>
          <button 
            onClick={() => setShowEditAirdropOverlay(true)} 
            className="edit-airdrop-button"
          >
            <FontAwesomeIcon icon={faEdit} /> Rebalance
          </button>
          <button onClick={() => setShowDeleteConfirmation(true)} className="delete-button">
            <FontAwesomeIcon icon={faTrashCan} /> Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default BundleWalletPanel;