import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCopy, 
    faKey, 
    faClock, 
    faBan,
    faSignOutAlt,
    faSync
} from '@fortawesome/free-solid-svg-icons';
import './LicenseAdmin.css';

const LicenseAdmin = () => {
    const [licenses, setLicenses] = useState([]);
    const [adminPassword, setAdminPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [expirationMonths, setExpirationMonths] = useState(12);
    const [isLoading, setIsLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
    const [filterStatus, setFilterStatus] = useState('all');

    const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;

    useEffect(() => {
        const storedAuth = localStorage.getItem('adminAuth');
        if (storedAuth === ADMIN_PASSWORD) {
            setIsAuthenticated(true);
            fetchLicenses();
        }
    }, []);

    const fetchLicenses = async () => {
        try {
            setIsLoading(true);
            const response = await fetch('/api/admin/licenses');
            if (!response.ok) throw new Error('Failed to fetch licenses');
            const data = await response.json();
            setLicenses(data);
            setError('');
        } catch (error) {
            setError(`Failed to fetch licenses: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (adminPassword === ADMIN_PASSWORD) {
            setIsAuthenticated(true);
            localStorage.setItem('adminAuth', ADMIN_PASSWORD);
            setError('');
            await fetchLicenses();
        } else {
            setError('Invalid password');
        }
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('adminAuth');
        setAdminPassword('');
        setLicenses([]);
    };

    const generateLicense = async () => {
        try {
            setIsLoading(true);
            const response = await fetch('/api/admin/generate-license', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ months: expirationMonths })
            });

            if (!response.ok) throw new Error('Failed to generate license');
            
            const data = await response.json();
            await fetchLicenses();
            
            setSuccessMessage(`License generated successfully: ${data.licenseKey}`);
            setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const revokeLicense = async (key) => {
        if (!window.confirm('Are you sure you want to revoke this license?')) return;
        
        try {
            setIsLoading(true);
            const response = await fetch('/api/admin/revoke-license', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ key })
            });

            if (!response.ok) throw new Error('Failed to revoke license');
            
            await fetchLicenses();
            setSuccessMessage('License revoked successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const updateExpiration = async (key) => {
        const months = parseInt(prompt('Enter number of months to extend:', '12'));
        if (!months || isNaN(months)) return;

        try {
            setIsLoading(true);
            const response = await fetch('/api/admin/update-expiration', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ key, months })
            });

            if (!response.ok) throw new Error('Failed to update expiration');
            
            await fetchLicenses();
            setSuccessMessage('Expiration updated successfully');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const copyToClipboard = async (licenseKey) => {
        try {
            await navigator.clipboard.writeText(licenseKey);
            setSuccessMessage('License key copied to clipboard');
            setTimeout(() => setSuccessMessage(''), 2000);
        } catch (error) {
            setError('Failed to copy to clipboard');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleString();
    };

    const getStatusClass = (status, expiresAt) => {
        if (status === 'revoked') return 'admin-status-revoked';
        if (expiresAt && new Date(expiresAt) < new Date()) return 'admin-status-expired';
        if (status === 'active') return 'admin-status-active';
        return 'admin-status-inactive';
    };

    const sortLicenses = (licenses) => {
        return [...licenses].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const filteredLicenses = licenses.filter(license => {
        if (filterStatus === 'all') return true;
        if (filterStatus === 'expired') {
            return license.expires_at && new Date(license.expires_at) < new Date();
        }
        return license.status === filterStatus;
    });

    const sortedLicenses = sortLicenses(filteredLicenses);

    if (!isAuthenticated) {
        return (
            <div className="admin-login">
                <h2>Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="password"
                        value={adminPassword}
                        onChange={(e) => setAdminPassword(e.target.value)}
                        placeholder="Enter password"
                        required
                    />
                    <button type="submit">Login</button>
                    {error && <div className="admin-error">{error}</div>}
                </form>
            </div>
        );
    }

    return (
        <div className="admin-panel">
            <div className="admin-header">
                <h2>License Management</h2>
                <div className="admin-controls">
                    <button 
                        onClick={fetchLicenses} 
                        className="admin-refresh-button"
                        disabled={isLoading}
                    >
                        <FontAwesomeIcon icon={faSync} spin={isLoading} />
                        Refresh
                    </button>
                    <button onClick={handleLogout} className="admin-logout-button">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        Logout
                    </button>
                </div>
            </div>

            <div className="admin-actions">
                <div className="admin-generate-controls">
                    <select
                        value={expirationMonths}
                        onChange={(e) => setExpirationMonths(Number(e.target.value))}
                        className="admin-months-select"
                    >
                        <option value={1}>1 Month</option>
                        <option value={12}>12 Months</option>
                    </select>
                    <button 
                        onClick={generateLicense}
                        disabled={isLoading}
                        className="admin-generate-button"
                    >
                        <FontAwesomeIcon icon={faKey} />
                        Generate New License
                    </button>
                </div>

                <div className="admin-filter-controls">
                    <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="admin-status-filter"
                    >
                        <option value="all">All Licenses</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="revoked">Revoked</option>
                        <option value="expired">Expired</option>
                    </select>
                </div>
            </div>

            {error && <div className="admin-error">{error}</div>}
            {successMessage && <div className="admin-success">{successMessage}</div>}

            <div className="admin-licenses-list">
                <table className="admin-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('key')}>License Key</th>
                            <th onClick={() => handleSort('status')}>Status</th>
                            <th onClick={() => handleSort('created_at')}>Created</th>
                            <th onClick={() => handleSort('activated_at')}>Activated</th>
                            <th onClick={() => handleSort('expires_at')}>Expires</th>
                            <th onClick={() => handleSort('last_used')}>Last Used</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedLicenses.map((license) => (
                            <tr key={license.id}>
                                <td className="admin-license-key">
                                    {license.key}
                                </td>
                                <td>
                                    <span className={`admin-status-badge ${getStatusClass(license.status, license.expires_at)}`}>
                                        {license.status}
                                    </span>
                                </td>
                                <td>{formatDate(license.created_at)}</td>
                                <td>{formatDate(license.activated_at)}</td>
                                <td>{formatDate(license.expires_at)}</td>
                                <td>{formatDate(license.last_used)}</td>
                                <td className="admin-actions-table">
                                    <button 
                                        onClick={() => copyToClipboard(license.key)}
                                        title="Copy license key"
                                        className="admin-action-table-button"
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                    <button 
                                        onClick={() => updateExpiration(license.key)}
                                        title="Extend expiration"
                                        className="admin-action-table-button"
                                    >
                                        <FontAwesomeIcon icon={faClock} />
                                    </button>
                                    {license.status !== 'revoked' && (
                                        <button 
                                            onClick={() => revokeLicense(license.key)}
                                            className="admin-action-table-button admin-revoke-button"
                                            title="Revoke license"
                                        >
                                            <FontAwesomeIcon icon={faBan} />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LicenseAdmin;