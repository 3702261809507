import React, { useState, useEffect, useRef } from 'react';
import { 
  Keypair,  
  PublicKey, 
  LAMPORTS_PER_SOL, 
  SystemProgram, 
  Transaction, 
  VersionedTransaction,
  ComputeBudgetProgram,
  SendTransactionError
} from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import bs58 from 'bs58';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCopy, 
  faPlay, 
  faStop, 
  faRotate, 
  faSpinner, 
  faExclamationTriangle,
  faArrowTrendDown,
  faDownload,
  faTrashCan,
  faPaperPlane,
  faTimes,
  faWallet,
  faArrowUp,
  faRightLeft,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import { truncateAddress, copyToClipboard } from '../utils/formatUtils';
import { useCloseAccounts } from '../hooks/useCloseAccounts';
import './VolumeBot.css'
import Toast from './Toast';

const JITO_API_URL = process.env.REACT_APP_JITO_API_URL || 'https://mainnet.block-engine.jito.wtf/api/v1/bundles';

const VolumeBot = ({
  connection, 
  mintAddress, 
  tokenSupply,
  transactionSettings, 
  setMainWalletBalance,
  priorityFee,
  onVolumeSupplyUpdate,
  pool
}) => {
  // Core states
  const [isRunning, setIsRunning] = useState(false);
  const [relayWallet, setRelayWallet] = useState(null);
  const [volumeWallets, setVolumeWallets] = useState([]);
  const [refreshingBalance, setRefreshingBalance] = useState({});
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tokenBalances, setTokenBalances] = useState({});
  const [isUpdatingTokenBalances, setIsUpdatingTokenBalances] = useState(false);
  
  // Configuration states
  const [targetHolders, setTargetHolders] = useState(10);
  const [buyDelay, setBuyDelay] = useState(5);
  const [sellDelay, setSellDelay] = useState(10);
  const [minBuy, setMinBuy] = useState(0.1);
  const [maxBuy, setMaxBuy] = useState(0.5);
  const [jitoTip, setJitoTip] = useState(0.0003);
  
  // UI states
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);

  // Add new state for tracking transactions
  const [lastTransactionTime, setLastTransactionTime] = useState(0);
  const [isBuyTransaction, setIsBuyTransaction] = useState(true);
  const shouldStopBot = useRef(false);
  const [processedWallets, setProcessedWallets] = useState(new Set());

  // Add a ref to track the bot's running state
  const isRunningRef = useRef(false);

  const { slippagePercent } = transactionSettings;
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [withdrawingWallets, setWithdrawingWallets] = useState({});

  const [showManualAirdropOverlay, setShowManualAirdropOverlay] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [manualAirdropAmount, setManualAirdropAmount] = useState('');
  const [isManualAirdropping, setIsManualAirdropping] = useState(false);

  const [isGenerating, setIsGenerating] = useState(false);
  const [isAirdropping, setIsAirdropping] = useState(false);
  const [isBundleSelling, setIsBundleSelling] = useState(false);

  // States for tracking active transactions
  const [activeTransactions, setActiveTransactions] = useState(new Set());
  const pendingTransactions = useRef(new Map());
  const forceStopRequested = useRef(false);

  const [showDumpAllConfirmation, setShowDumpAllConfirmation] = useState(false);
  const [showWithdrawConfirmation, setShowWithdrawConfirmation] = useState(false);

  const { processAllWallets } = useCloseAccounts(connection);

  const [selectedPreset, setSelectedPreset] = useState('UP');

  const TRADING_PRESETS = {
    UP: {
      name: 'Up',
      description: 'Buys with 20% chance to sell 50-75% of position',
      sellProbability: 0.2,
      sellPercentageRange: [50, 75],
      icon: faArrowUp
    },
    SIDEWAYS: {
      name: 'Sideways',
      description: 'Buys with 100% chance to sell 100% of position',
      sellProbability: 1,
      sellPercentageRange: [100, 100],
      icon: faRightLeft
    },
    DOWN: {
      name: 'Down',
      description: 'Only sells 100% of position based on interval',
      sellOnly: true,
      sellPercentageRange: [100, 100],
      icon: faArrowDown
    }
  };

  // Storage Utilities
  const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const loadFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };

  // Toast Utilities
  const showToast = (message, type = 'info', duration = 3000) => {
    setToast({ message, type, duration });
  };

  const clearToast = () => {
    setToast(null);
  };

  // Balance Utilities
  const fetchBalance = async (publicKey) => {
    try {
      const balance = await connection.getBalance(new PublicKey(publicKey));
      return balance / LAMPORTS_PER_SOL;
    } catch (error) {
      console.error('Error fetching balance:', error);
      return 0;
    }
  };

  // Format token balance utility function
  const formatTokenBalance = (balance) => {
    if (!balance || balance === 0) return '0';
    
    if (balance >= 1_000_000) {
      return `${(balance / 1_000_000).toFixed(2)}M`;
    }
    
    if (balance >= 1_000) {
      return `${(balance / 1_000).toFixed(2)}k`;
    }
    
    return Math.floor(balance).toString();
  };

  // Calculate supply percentage utility function
  const calculateSupplyPercentage = (tokenBalance) => {
    if (!tokenBalance || tokenBalance === 0 || !tokenSupply) return 0;
    return ((tokenBalance / tokenSupply) * 100).toFixed(3);
  };

  // In VolumeBot.js, add useEffect for supply calculation:
  useEffect(() => {
    const calculateVolumeSupplyPercentages = () => {
      if (!mintAddress) {
        return { total: 0 };
      }
  
      // Now using the actual token balances that are already decimal-adjusted
      const totalPercentage = volumeWallets.reduce((sum, wallet) => {
        const tokenBalance = tokenBalances[wallet.publicKey] || 0;
        return sum + ((tokenBalance / tokenSupply) * 100);
      }, 0);
  
      return { total: parseFloat(totalPercentage.toFixed(10)) };
    };
  
    const percentages = calculateVolumeSupplyPercentages();
    onVolumeSupplyUpdate(percentages);
  }, [mintAddress, volumeWallets, tokenBalances, tokenSupply, onVolumeSupplyUpdate]);

  // Fetch token balance for a single wallet
  const fetchTokenBalance = async (publicKey) => {
    if (!mintAddress) return 0;
    
    try {
      // Get token decimals first
      const mintInfo = await connection.getParsedAccountInfo(new PublicKey(mintAddress));
      if (!mintInfo.value || !mintInfo.value.data || !mintInfo.value.data.parsed) {
        console.debug('Unable to parse mint info');
        return 0;
      }
      const tokenDecimals = mintInfo.value.data.parsed.info.decimals;
  
      // Get token account address
      const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(mintAddress),
        new PublicKey(publicKey)
      );
      
      try {
        const accountInfo = await getAccount(connection, tokenAccount);
        return Number(accountInfo.amount) / Math.pow(10, tokenDecimals);
      } catch (error) {
        // Handle specific token account errors silently
        if (
          error.name === 'TokenAccountNotFoundError' || 
          error.message?.includes('TokenAccountNotFound') ||
          error.message?.includes('Account does not exist') ||
          error.message?.includes('Failed to find account')
        ) {
          return 0;
        }
  
        console.debug('Token account error:', {
          error: error.message,
          publicKey,
          mintAddress
        });
        return 0;
      }
    } catch (error) {
      console.debug('Error fetching token info:', {
        error: error.message,
        publicKey,
        mintAddress
      });
      return 0;
    }
  };
  
  // Optional: Add a helper function to update token balances with better error handling
  const updateTokenBalances = async () => {
    if (!mintAddress) {
      setTokenBalances({});
      return;
    }
  
    setIsUpdatingTokenBalances(true);
    try {
      const balances = {};
      const errors = [];
      
      await Promise.all(volumeWallets.map(async (wallet) => {
        try {
          balances[wallet.publicKey] = await fetchTokenBalance(wallet.publicKey);
        } catch (error) {
          errors.push({
            wallet: wallet.publicKey,
            error: error.message
          });
          balances[wallet.publicKey] = 0;
        }
      }));
  
      // Log any errors as a group if they occurred
      if (errors.length > 0) {
        console.debug('Token balance update errors:', errors);
      }
  
      setTokenBalances(balances);
    } catch (error) {
      console.warn('Error in batch token balance update:', error);
      // Don't show error toast to user since this is a background operation
    } finally {
      setIsUpdatingTokenBalances(false);
    }
  };

  // Update token balances when mint address or wallets change
  useEffect(() => {
    updateTokenBalances();
  }, [mintAddress, volumeWallets]);

  // Modify the refreshWalletBalance function to also update token balance
  const refreshWalletBalance = async (publicKey, isRelay = false) => {

    if (!connection) {
      showToast('No connection available, please check your RPC URL', 'error');
      return;
    }

    setRefreshingBalance(prev => ({ ...prev, [publicKey]: true }));
    try {
      const [solBalance, tokenBalance] = await Promise.all([
        fetchBalance(publicKey),
        fetchTokenBalance(publicKey)
      ]);
      
      if (isRelay) {
        setRelayWallet(prev => ({ ...prev, balance: solBalance }));
      } else {
        setVolumeWallets(prev => 
          prev.map(wallet => 
            wallet.publicKey === publicKey 
              ? { ...wallet, balance: solBalance } 
              : wallet
          )
        );
        setTokenBalances(prev => ({
          ...prev,
          [publicKey]: tokenBalance
        }));
      }
      showToast('Balances updated successfully!', 'success', 2000);
    } catch (error) {
      console.error('Error refreshing balances:', error);
      showToast('Failed to refresh balances', 'error');
    } finally {
      setTimeout(() => {
        setRefreshingBalance(prev => ({ ...prev, [publicKey]: false }));
      }, 1000);
    }
  };

  // Modify the executeBuy and executeSell functions to update balances after confirmation
  const updateBalancesAfterTransaction = async (wallet) => {
    const [newSolBalance, newTokenBalance] = await Promise.all([
      fetchBalance(wallet.publicKey),
      fetchTokenBalance(wallet.publicKey)
    ]);

    setVolumeWallets(prev => 
      prev.map(w => 
        w.publicKey === wallet.publicKey 
          ? { ...w, balance: newSolBalance }
          : w
      )
    );

    setTokenBalances(prev => ({
      ...prev,
      [wallet.publicKey]: newTokenBalance
    }));
  };

  const calculateWalletAmounts = (desiredBuyAmount) => {
    const jitoFees = jitoTip * 2;
    const baseFees = 0.005;
    const buffer = desiredBuyAmount * (0.03 + (slippagePercent / 100));
    const totalFees = jitoFees + baseFees + buffer;
    const airdropAmount = desiredBuyAmount + totalFees;
    
    return {
      buyAmount: Number(desiredBuyAmount.toFixed(3)),
      airdropAmount: Number((desiredBuyAmount + totalFees).toFixed(3))
    };
  };

  const loadRelayWallet = async () => {
    const savedKeys = JSON.parse(localStorage.getItem('walletKeys') || '{}');
    const relayWalletSecret = savedKeys.relayWallet;
    
    if (!relayWalletSecret) {
      console.error('Relay wallet secret key not found in localStorage');
      showToast('Relay wallet configuration missing', 'error');
      return;
    }
  
    try {
      const keypair = Keypair.fromSecretKey(bs58.decode(relayWalletSecret));
      const balance = await fetchBalance(keypair.publicKey.toBase58());
      const newWallet = {
        publicKey: keypair.publicKey.toBase58(),
        secretKey: relayWalletSecret,
        balance
      };
      setRelayWallet(newWallet);
    } catch (error) {
      console.error('Error loading relay wallet:', error);
      showToast('Failed to load relay wallet', 'error');
    }
  };

  const generateNewWallets = async () => {
    setIsGenerating(true);
    try {
      const count = parseInt(targetHolders);
      
      if (isNaN(count) || count <= 0 || count > 100) {
        throw new Error('Please enter a valid number of wallets (1-100)');
      }

      const newWallets = [];
      for (let i = 0; i < count; i++) {
        const newKeypair = Keypair.generate();
        const desiredBuyAmount = minBuy + Math.random() * (maxBuy - minBuy);
        const { buyAmount, airdropAmount } = calculateWalletAmounts(desiredBuyAmount);
        
        newWallets.push({
          publicKey: newKeypair.publicKey.toBase58(),
          secretKey: bs58.encode(newKeypair.secretKey),
          balance: 0,
          buyAmount: buyAmount,
          airdropAmount: airdropAmount
        });
      }

      setVolumeWallets(newWallets);
      saveToLocalStorage('volumeWallets', newWallets);
      showToast(`${count} new volume wallets generated successfully!`, 'success');
    } catch (error) {
      console.error('Error generating wallets:', error);
      showToast(error.message, 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  // Export Functions
  const exportWalletData = (data, filename) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const exportWallets = () => {
    exportWalletData(volumeWallets, 'volume-wallets.json');
    showToast('Volume wallets exported successfully!', 'success');
  };

  // Airdrop Function
  const airdropToWallets = async () => {
    if (!relayWallet) {
      showToast('Relay wallet not available', 'error');
      return;
    }
  
    if (!volumeWallets.length) {
      showToast('No wallets available to airdrop to', 'error');
      return;
    }
  
    setIsAirdropping(true);
  
    // Calculate total airdrop amount
    const totalAirdropAmount = volumeWallets.reduce((total, wallet) => total + wallet.airdropAmount, 0);
  
    // Check if relay wallet has sufficient balance
    const relayWalletBalance = await connection.getBalance(new PublicKey(relayWallet.publicKey));
    if (relayWalletBalance < totalAirdropAmount * LAMPORTS_PER_SOL) {
      showToast(`Insufficient balance. Required: ${totalAirdropAmount} SOL, Available: ${relayWalletBalance / LAMPORTS_PER_SOL} SOL`, 'error');
      setIsAirdropping(false);
      return;
    }
  
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
    const airdropResults = await Promise.all(volumeWallets.map(async (wallet) => {
      try {
        const destinationPublicKey = new PublicKey(wallet.publicKey);
        const airdropAmount = Math.floor(wallet.airdropAmount * LAMPORTS_PER_SOL);
  
        const transaction = new Transaction();
      
        transaction.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: computeUnits,
          }),
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: microLamports,
          }),
          SystemProgram.transfer({
            fromPubkey: new PublicKey(relayWallet.publicKey),
            toPubkey: destinationPublicKey,
            lamports: airdropAmount,
          })
        );
  
        const relayKeypair = Keypair.fromSecretKey(bs58.decode(relayWallet.secretKey));
        const signature = await connection.sendTransaction(transaction, [relayKeypair]);
        await connection.confirmTransaction(signature, 'confirmed');
  
        console.log(`Airdropped ${wallet.airdropAmount} SOL to ${wallet.publicKey}`);
        return true;
      } catch (error) {
        console.error(`Failed to airdrop to ${wallet.publicKey}:`, error);
        if (error instanceof SendTransactionError) {
          const logs = await error.getLogs(connection);
          console.log('Transaction logs:', logs);
        }
        return false;
      }
    }));
  
    const successCount = airdropResults.filter(result => result).length;
    const failCount = airdropResults.length - successCount;
  
    // Update balances after airdrops
    await Promise.all(volumeWallets.map(async (wallet) => {
      const [newSolBalance, newTokenBalance] = await Promise.all([
        fetchBalance(wallet.publicKey),
        fetchTokenBalance(wallet.publicKey)
      ]);
  
      setVolumeWallets(prev => 
        prev.map(w => 
          w.publicKey === wallet.publicKey 
            ? { ...w, balance: newSolBalance }
            : w
        )
      );
  
      setTokenBalances(prev => ({
        ...prev,
        [wallet.publicKey]: newTokenBalance
      }));
    }));
  
    // Update relay wallet balance
    const newRelayBalance = await fetchBalance(relayWallet.publicKey);
    setRelayWallet(prev => ({ ...prev, balance: newRelayBalance }));
  
    if (failCount > 0) {
      showToast(`Airdrop completed. ${successCount} successful, ${failCount} failed. Check console for details.`, 'warning');
    } else {
      showToast('Airdrop to all volume wallets completed successfully!', 'success');
    }
  
    setIsAirdropping(false);
  };

  const loadWallets = async () => {
    // Load relay wallet from env
    await loadRelayWallet();
  
    // Load volume wallets
    const storedVolumeWallets = loadFromLocalStorage('volumeWallets') || [];
    const walletsWithBalance = await Promise.all(storedVolumeWallets.map(async (wallet) => {
      const balance = await fetchBalance(wallet.publicKey);
      return {
        ...wallet,
        balance
      };
    }));
    
    setVolumeWallets(walletsWithBalance);
  };

  useEffect(() => {
    loadWallets();
  }, []);

  // UI Event Handlers
  const handleCopyToClipboard = (text, label) => {
    copyToClipboard(
      text,
      () => showToast(`${label} copied to clipboard!`, 'success', 3000),
      (message) => showToast(message, 'error', 3000)
    );
  };

  const handleDeleteConfirmation = () => {
    if (deleteConfirmation.toLowerCase() === 'confirm') {
      setVolumeWallets([]);
      localStorage.removeItem('volumeWallets');
      showToast('All volume wallets deleted successfully!', 'success');
      setShowDeleteConfirmation(false);
      setDeleteConfirmation('');
      setDeleteError('');
    } else {
      setDeleteError('Please type "confirm" to delete all wallets.');
    }
  };

  const executeBuy = async (wallet, retryCount = 0) => {
    const MAX_RETRIES = 2;
    const CONFIRMATION_TIMEOUT = 10000; // 10 seconds
    
    // Check for force stop before starting transaction
    if (forceStopRequested.current) {
      console.log(`Skipping buy for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
      throw new Error('Force stop requested');
    }
  
    if (!mintAddress || !wallet) {
      console.error('Missing required parameters for buy');
      return;
    }
  
    try {
      const bundledTxArgs = [{
        publicKey: wallet.publicKey,
        action: "buy",
        mint: mintAddress,
        denominatedInSol: "true",
        amount: wallet.buyAmount,
        slippage: slippagePercent,
        priorityFee: jitoTip,
        pool: pool
      }];
  
      // Check force stop again before sending transaction
      if (forceStopRequested.current) {
        console.log(`Cancelling buy for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
        throw new Error('Force stop requested');
      }
  
      console.log('Sending buy transaction:', bundledTxArgs);
  
      const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bundledTxArgs)
      });
  
      if (!response.ok) {
        // Check force stop before retry
        if (retryCount < MAX_RETRIES && !forceStopRequested.current) {
          console.log(`HTTP error ${response.status}, retrying... (${retryCount + 1}/${MAX_RETRIES})`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return executeBuy(wallet, retryCount + 1);
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const transactions = await response.json();
      
      if (!transactions || !transactions[0]) {
        throw new Error('No transaction data received');
      }
  
      // Check force stop before signing
      if (forceStopRequested.current) {
        console.log(`Cancelling buy for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
        throw new Error('Force stop requested');
      }
  
      const tx = VersionedTransaction.deserialize(
        new Uint8Array(bs58.decode(transactions[0]))
      );
      
      const signerKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
      tx.sign([signerKeypair]);
      const encodedTransaction = bs58.encode(tx.serialize());
      const signature = bs58.encode(tx.signatures[0]);
  
      // Final force stop check before submitting to Jito
      if (forceStopRequested.current) {
        console.log(`Cancelling buy for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
        throw new Error('Force stop requested');
      }
  
      const jitoResponse = await fetch(
        JITO_API_URL,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [[encodedTransaction]]
          })
        }
      );
  
      if (!jitoResponse.ok) {
        throw new Error(`Jito HTTP error! status: ${jitoResponse.status}`);
      }
  
      console.log(`Buy Transaction: https://solscan.io/tx/${signature}`);
  
      // Use Promise.race to implement a timeout
      const confirmationPromise = connection.confirmTransaction(signature, 'confirmed');
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Confirmation timeout')), CONFIRMATION_TIMEOUT)
      );
      const forceStopPromise = new Promise((_, reject) => {
        // Check force stop periodically during confirmation
        const interval = setInterval(() => {
          if (forceStopRequested.current) {
            clearInterval(interval);
            reject(new Error('Force stop requested'));
          }
        }, 100);
        // Clean up interval if confirmation completes
        setTimeout(() => clearInterval(interval), CONFIRMATION_TIMEOUT);
      });
  
      try {
        await Promise.race([confirmationPromise, timeoutPromise, forceStopPromise]);
        
        // Check force stop one final time before updating balances
        if (forceStopRequested.current) {
          throw new Error('Force stop requested');
        }
        
        console.log(`Buy confirmed: https://solscan.io/tx/${signature}`);
        await new Promise(resolve => setTimeout(resolve, 2000));
        await updateBalancesAfterTransaction(wallet);
        return signature;
      } catch (confirmError) {
        if (confirmError.message === 'Force stop requested') {
          throw confirmError;
        }
  
        const signatureStatus = await connection.getSignatureStatus(signature);
        
        if (signatureStatus.value?.confirmationStatus === 'confirmed') {
          console.log(`Transaction ${signature} confirmed despite timeout`);
          await updateBalancesAfterTransaction(wallet);
          return signature;
        }
        
        if (retryCount < MAX_RETRIES && !forceStopRequested.current) {
          console.log(`Retrying transaction for wallet ${truncateAddress(wallet.publicKey)} (attempt ${retryCount + 1}/${MAX_RETRIES})`);
          return executeBuy(wallet, retryCount + 1);
        }
        
        throw confirmError;
      }
    } catch (error) {
      if (error.message === 'Force stop requested') {
        throw error;
      }
  
      if (retryCount < MAX_RETRIES && 
         !forceStopRequested.current &&
         (error.message.includes('timeout') || 
          error.message.includes('expired') ||
          error.message.includes('HTTP error'))) {
        console.log(`Retrying transaction for wallet ${truncateAddress(wallet.publicKey)} (attempt ${retryCount + 1}/${MAX_RETRIES})`);
        return executeBuy(wallet, retryCount + 1);
      }
      throw error;
    }
  };

  const createSellTransaction = async (wallet, retryCount = 0) => {
    const MAX_RETRIES = 2;
    
    if (forceStopRequested.current) {
      console.log(`Skipping sell transaction creation for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
      throw new Error('Force stop requested');
    }

    const preset = TRADING_PRESETS[selectedPreset];
    let sellPercentage;
    
    if (preset.sellPercentageRange[0] === preset.sellPercentageRange[1]) {
      sellPercentage = preset.sellPercentageRange[0];
    } else {
      const [min, max] = preset.sellPercentageRange;
      sellPercentage = min + Math.random() * (max - min);
    }
    
    const bundledTxArgs = [{
      publicKey: wallet.publicKey,
      action: "sell",
      mint: mintAddress,
      denominatedInSol: "false",
      amount: `${sellPercentage}%`,
      slippage: slippagePercent,
      priorityFee: jitoTip,
      pool: pool
    }];
  
    console.log(`Creating sell transaction for ${truncateAddress(wallet.publicKey)} - ${sellPercentage.toFixed(2)}% of holdings`);
  
    const response = await fetch(`https://pumpportal.fun/api/trade-local`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bundledTxArgs)
    });
  
    if (!response.ok) {
      // Check force stop before retry
      if (retryCount < MAX_RETRIES && !forceStopRequested.current) {
        console.log(`HTTP error ${response.status}, retrying... (${retryCount + 1}/${MAX_RETRIES})`);
        await new Promise(resolve => setTimeout(resolve, 2000));
        return createSellTransaction(wallet, retryCount + 1);
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    // Check force stop before processing response
    if (forceStopRequested.current) {
      throw new Error('Force stop requested');
    }
  
    const transactions = await response.json();
    return {
      transaction: VersionedTransaction.deserialize(
        new Uint8Array(bs58.decode(transactions[0]))
      ),
      sellPercentage
    };
  };
  
  const executeSell = async (wallet, retryCount = 0) => {
    const MAX_RETRIES = 2;
    const CONFIRMATION_TIMEOUT = 10000; // 10 seconds
  
    // Check for force stop before starting
    if (forceStopRequested.current) {
      console.log(`Skipping sell for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
      throw new Error('Force stop requested');
    }
  
    if (!mintAddress || !wallet) {
      console.error('Missing required parameters for sell');
      return;
    }
  
    try {
      const { transaction, sellPercentage } = await createSellTransaction(wallet);
  
      // Check force stop before signing
      if (forceStopRequested.current) {
        console.log(`Cancelling sell for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
        throw new Error('Force stop requested');
      }
  
      const signerKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
      transaction.sign([signerKeypair]);
      const encodedTransaction = bs58.encode(transaction.serialize());
      const signature = bs58.encode(transaction.signatures[0]);
  
      // Final force stop check before submitting to Jito
      if (forceStopRequested.current) {
        console.log(`Cancelling sell for wallet ${truncateAddress(wallet.publicKey)} due to force stop`);
        throw new Error('Force stop requested');
      }
  
      const jitoResponse = await fetch(
        JITO_API_URL,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "sendBundle",
            params: [[encodedTransaction]]
          })
        }
      );
  
      if (!jitoResponse.ok) {
        throw new Error(`Jito HTTP error! status: ${jitoResponse.status}`);
      }
  
      console.log(`Sell Transaction: https://solscan.io/tx/${signature} (${sellPercentage.toFixed(2)}% sold)`);
  
      // Use Promise.race to implement a timeout with force stop monitoring
      const confirmationPromise = connection.confirmTransaction(signature, 'confirmed');
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Confirmation timeout')), CONFIRMATION_TIMEOUT)
      );
      const forceStopPromise = new Promise((_, reject) => {
        // Check force stop periodically during confirmation
        const interval = setInterval(() => {
          if (forceStopRequested.current) {
            clearInterval(interval);
            reject(new Error('Force stop requested'));
          }
        }, 100);
        // Clean up interval if confirmation completes
        setTimeout(() => clearInterval(interval), CONFIRMATION_TIMEOUT);
      });
  
      try {
        await Promise.race([confirmationPromise, timeoutPromise, forceStopPromise]);
  
        // Check force stop one final time before updating balances
        if (forceStopRequested.current) {
          throw new Error('Force stop requested');
        }
  
        console.log(`Sell confirmed: https://solscan.io/tx/${signature}`);
        
        // Add small delay before checking balances
        await new Promise(resolve => setTimeout(resolve, 2000));
        await updateBalancesAfterTransaction(wallet);
        
        return { signature, sellPercentage };
      } catch (confirmError) {
        if (confirmError.message === 'Force stop requested') {
          throw confirmError;
        }
  
        // If we get a timeout, verify the transaction status
        const signatureStatus = await connection.getSignatureStatus(signature);
        
        if (signatureStatus.value?.confirmationStatus === 'confirmed') {
          // Transaction actually succeeded despite timeout
          console.log(`Transaction ${signature} confirmed despite timeout`);
          await updateBalancesAfterTransaction(wallet);
          return { signature, sellPercentage };
        }
        
        if (retryCount < MAX_RETRIES && !forceStopRequested.current) {
          console.log(`Retrying sell for wallet ${truncateAddress(wallet.publicKey)} (attempt ${retryCount + 1}/${MAX_RETRIES})`);
          return executeSell(wallet, retryCount + 1);
        }
        
        throw confirmError;
      }
    } catch (error) {
      if (error.message === 'Force stop requested') {
        throw error;
      }
  
      if (retryCount < MAX_RETRIES && 
         !forceStopRequested.current &&
         (error.message.includes('timeout') || 
          error.message.includes('expired') ||
          error.message.includes('HTTP error'))) {
        console.log(`Retrying sell for wallet ${truncateAddress(wallet.publicKey)} (attempt ${retryCount + 1}/${MAX_RETRIES})`);
        return executeSell(wallet, retryCount + 1);
      }
      throw error;
    }
  };

  const runBotLoop = async () => {
    if (!mintAddress) {
      showToast('Mint address not set', 'error');
      return;
    }

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const pendingSells = new Map();
    let consecutiveErrors = 0;
    const MAX_CONSECUTIVE_ERRORS = 3;
    const preset = TRADING_PRESETS[selectedPreset];

    forceStopRequested.current = false;

    try {
      for (const wallet of volumeWallets) {
        if (forceStopRequested.current) {
          console.log('Force stop requested, cancelling pending operations...');
          for (const [walletKey, details] of pendingSells) {
            clearTimeout(details.timeoutId);
            console.log(`Cancelled pending sell for wallet ${truncateAddress(walletKey)}`);
          }
          pendingSells.clear();
          setActiveTransactions(new Set());
          pendingTransactions.current.clear();
          break;
        }

        try {
          setActiveTransactions(prev => new Set(prev).add(wallet.publicKey));

          // Handle different preset behaviors
          if (!preset.sellOnly) {
            // Execute buy for UP and SIDEWAYS presets
            console.log(`Executing buy for wallet ${truncateAddress(wallet.publicKey)}`);
            await executeBuy(wallet);
            consecutiveErrors = 0;

            // Schedule sell based on preset probability
            const shouldSell = Math.random() < preset.sellProbability;
            if (shouldSell && !forceStopRequested.current) {
              const actualSellDelay = (sellDelay * 1000) * (0.8 + Math.random() * 0.4);
              console.log(`Scheduling sell for ${truncateAddress(wallet.publicKey)} in ${(actualSellDelay/1000).toFixed(1)}s`);
              
              const timeoutId = setTimeout(async () => {
                if (!forceStopRequested.current) {
                  try {
                    setActiveTransactions(prev => new Set(prev).add(wallet.publicKey));
                    const { sellPercentage } = await executeSell(wallet);
                    console.log(
                      `Completed scheduled sell for ${truncateAddress(wallet.publicKey)} ` +
                      `(${sellPercentage.toFixed(2)}%)`
                    );
                    setActiveTransactions(prev => {
                      const updated = new Set(prev);
                      updated.delete(wallet.publicKey);
                      return updated;
                    });
                    pendingSells.delete(wallet.publicKey);
                  } catch (error) {
                    console.error(`Scheduled sell failed for ${wallet.publicKey}:`, error);
                    showToast(`Sell failed for wallet ${truncateAddress(wallet.publicKey)}`, 'error');
                  }
                }
              }, actualSellDelay);

              pendingSells.set(wallet.publicKey, {
                timeoutId,
                scheduledTime: Date.now() + actualSellDelay
              });
            }
          } else {
            // DOWN preset - execute sell only
            console.log(`Executing sell for wallet ${truncateAddress(wallet.publicKey)} (DOWN preset)`);
            await executeSell(wallet);
            consecutiveErrors = 0;
          }

          setActiveTransactions(prev => {
            const updated = new Set(prev);
            updated.delete(wallet.publicKey);
            return updated;
          });

          if (!forceStopRequested.current) {
            const baseDelay = preset.sellOnly ? sellDelay * 1000 : buyDelay * 1000;
            const jitterRange = baseDelay * 0.4;
            const jitter = (Math.random() * jitterRange) - (jitterRange / 2);
            const nextWalletDelay = baseDelay + jitter;
            await sleep(nextWalletDelay);
          }

        } catch (error) {
          console.error(`Error processing wallet ${wallet.publicKey}:`, error);
          showToast(`Error with wallet ${truncateAddress(wallet.publicKey)}`, 'error');
          consecutiveErrors++;

          setActiveTransactions(prev => {
            const updated = new Set(prev);
            updated.delete(wallet.publicKey);
            return updated;
          });

          if (consecutiveErrors >= MAX_CONSECUTIVE_ERRORS) {
            console.log('Too many consecutive errors, stopping bot...');
            showToast('Bot stopped due to multiple errors. Check console for details.', 'warning');
            break;
          }

          if (!forceStopRequested.current) {
            await sleep(5000);
          }
        }
      }

    } catch (error) {
      console.error('Bot loop error:', error);
      showToast(`Bot error: ${error.message}`, 'error');
    } finally {
      // Cleanup
      for (const [walletKey, details] of pendingSells) {
        clearTimeout(details.timeoutId);
      }
      pendingSells.clear();
      setActiveTransactions(new Set());
      pendingTransactions.current.clear();
      forceStopRequested.current = false;
      setIsRunning(false);
      isRunningRef.current = false;
      shouldStopBot.current = false;
      console.log('Volume bot stopped');
      showToast('Volume bot stopped', 'info');
    }
  };

  const toggleBot = () => {
    if (!mintAddress) {
      showToast('Mint address not set', 'error');
      return;
    }
  
    if (!volumeWallets.length) {
      showToast('No wallets available', 'error');
      return;
    }
  
    if (!isRunning) {
      const preset = TRADING_PRESETS[selectedPreset];
      console.log('Starting bot...', {
        preset: selectedPreset,
        description: preset.description,
        sellProbability: preset.sellProbability,
        sellPercentageRange: preset.sellPercentageRange,
      });
      
      shouldStopBot.current = false;
      forceStopRequested.current = false;
      setProcessedWallets(new Set());
      setIsBuyTransaction(true);
      setLastTransactionTime(0);
      setIsRunning(true);
      isRunningRef.current = true;
      runBotLoop();
    } else {
      console.log('Force stopping bot...');
      forceStopRequested.current = true;
      isRunningRef.current = false;
      showToast('Force stopping bot...', 'info');
      
      // Clear all active transactions immediately
      setActiveTransactions(new Set());
      pendingTransactions.current.clear();
    }
  };

  const executeBundleSell = async () => {
    if (!mintAddress || volumeWallets.length === 0) {
      showToast('No wallets available for bundle sell', 'error');
      return;
    }
  
    setIsBundleSelling(true);
    const BUNDLE_SIZE = 5;
  
    try {
      // First, get all wallets with tokens
      const walletsWithTokens = [];
      for (const wallet of volumeWallets) {
        const tokenBalance = tokenBalances[wallet.publicKey] || 0;
        if (tokenBalance > 0) {
          walletsWithTokens.push(wallet);
        }
      }
  
      if (walletsWithTokens.length === 0) {
        showToast('No wallets contain tokens to sell', 'warning');
        setIsBundleSelling(false);
        return;
      }
  
      // Split wallets with tokens into bundles of 5
      const bundles = [];
      for (let i = 0; i < walletsWithTokens.length; i += BUNDLE_SIZE) {
        bundles.push(walletsWithTokens.slice(i, i + BUNDLE_SIZE));
      }
  
      console.log(`Processing ${bundles.length} bundles with ${walletsWithTokens.length} token-holding wallets`);
  
      for (let i = 0; i < bundles.length; i++) {
        const bundleWallets = bundles[i];
        const bundledTxArgs = bundleWallets.map(wallet => ({
          publicKey: wallet.publicKey,
          action: "sell",
          mint: mintAddress,
          denominatedInSol: "false",
          amount: "100%",
          slippage: slippagePercent,
          priorityFee: jitoTip,
          pool: pool  
        }));
  
        console.log(`Processing bundle ${i + 1} of ${bundles.length} with ${bundleWallets.length} wallets`);
        
        const response = await fetch('https://pumpportal.fun/api/trade-local', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(bundledTxArgs)
        });
  
        if (!response.ok) {
          throw new Error(`Failed to generate transactions for bundle ${i + 1}`);
        }
  
        const transactions = await response.json();
        const encodedSignedTransactions = [];
        const signatures = [];
        
        // Sign transactions and collect signatures
        for (let j = 0; j < transactions.length; j++) {
          const transaction = VersionedTransaction.deserialize(new Uint8Array(bs58.decode(transactions[j])));
          const keypair = Keypair.fromSecretKey(bs58.decode(bundleWallets[j].secretKey));
          transaction.sign([keypair]);
          encodedSignedTransactions.push(bs58.encode(transaction.serialize()));
          signatures.push(bs58.encode(transaction.signatures[0]));
        }
  
        const jitoResponse = await fetch(
          JITO_API_URL,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              jsonrpc: "2.0",
              id: 1,
              method: "sendBundle",
              params: [encodedSignedTransactions]
            })
          }
        );
  
        if (!jitoResponse.ok) {
          throw new Error(`Jito API error for bundle ${i + 1}`);
        }
  
        // Wait for all transactions in the bundle to confirm
        await Promise.all(signatures.map(async (signature) => {
          try {
            await connection.confirmTransaction(signature, 'confirmed');
            console.log(`Confirmed transaction: https://solscan.io/tx/${signature}`);
          } catch (error) {
            console.error(`Failed to confirm transaction ${signature}:`, error);
          }
        }));
  
        // Update balances for wallets in this bundle
        await Promise.all(bundleWallets.map(async (wallet) => {
          try {
            const [newSolBalance, newTokenBalance] = await Promise.all([
              fetchBalance(wallet.publicKey),
              fetchTokenBalance(wallet.publicKey)
            ]);
  
            // Update wallet balances in state
            setVolumeWallets(prev => 
              prev.map(w => 
                w.publicKey === wallet.publicKey 
                  ? { ...w, balance: newSolBalance }
                  : w
              )
            );
  
            // Update token balances
            setTokenBalances(prev => ({
              ...prev,
              [wallet.publicKey]: newTokenBalance
            }));
          } catch (error) {
            console.error(`Failed to update balances for wallet ${wallet.publicKey}:`, error);
          }
        }));
  
        showToast(`Volume bundle ${i + 1} sold and balances updated!`, 'success');
  
        // Add delay between bundles
        if (i < bundles.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
  
      // After all bundles are sold, close empty token accounts
      if (pool === "raydium") {
        try {
          console.log('Starting to close empty token accounts...');
          const closedAccounts = await processAllWallets(
            volumeWallets,
            // Add callback to handle balance updates
            (updatedBalances) => {
              setVolumeWallets(prev => 
                prev.map(wallet => {
                  const updated = updatedBalances.find(b => b.publicKey === wallet.publicKey);
                  return updated ? { ...wallet, balance: updated.balance } : wallet;
                })
              );
            }
          );
          if (closedAccounts > 0) {
            showToast(`Closed ${closedAccounts} empty token accounts`, 'success');
          }
        } catch (error) {
          console.error('Error closing token accounts:', error);
          showToast('Error closing some token accounts', 'error');
        }
      }
  
    } catch (error) {
      console.error('Bundle sell error:', error);
      showToast(`Bundle sell error: ${error.message}`, 'error');
    } finally {
      setIsBundleSelling(false);
      // Final balance update for all wallets
      await loadWallets();
    }
  };

  const handleCreateClick = () => {
    if (volumeWallets.length > 0) {
      setShowCreateConfirmation(true);
    } else {
      generateNewWallets();
    }
  };

  const handleCreateConfirm = () => {
    setShowCreateConfirmation(false);
    generateNewWallets();
  };

  const calculatePriorityFee = (solAmount, computeUnits) => {
    const lamports = solAmount * LAMPORTS_PER_SOL;
    const microLamports = Math.floor((lamports * 1_000_000) / computeUnits);
    return microLamports;
  };

  const withdrawToMain = async () => {
    const savedKeys = JSON.parse(localStorage.getItem('walletKeys') || '{}');
    const mainWalletSecretKey = savedKeys.mainWallet;
    
    if (!mainWalletSecretKey) {
      showToast('Main wallet not configured', 'error');
      return;
    }
  
    setIsWithdrawing(true);
    const mainWallet = Keypair.fromSecretKey(bs58.decode(mainWalletSecretKey));
  
    const computeUnits = 300000;
    const microLamports = calculatePriorityFee(priorityFee, computeUnits);
    const priorityFeeCost = Math.ceil(computeUnits * microLamports / 1_000_000); // Ensure integer
    const rentExemptBalance = 5000; // Minimum balance for rent exemption
  
    const withdrawResults = await Promise.all(volumeWallets.map(async (wallet) => {
      try {
        setWithdrawingWallets(prev => ({ ...prev, [wallet.publicKey]: true }));
        
        const volumeWalletKeypair = Keypair.fromSecretKey(bs58.decode(wallet.secretKey));
        const volumeWalletBalance = await connection.getBalance(volumeWalletKeypair.publicKey);
        
        if (volumeWalletBalance <= (rentExemptBalance + priorityFeeCost)) {
          console.log(`Skipping ${wallet.publicKey} - insufficient balance for fees`);
          return true;
        }
  
        // Calculate maximum withdrawal amount and ensure it's an integer
        const withdrawalAmount = Math.floor(volumeWalletBalance - rentExemptBalance - priorityFeeCost);
  
        const transaction = new Transaction();
        
        transaction.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: computeUnits,
          }),
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: microLamports,
          }),
          SystemProgram.transfer({
            fromPubkey: volumeWalletKeypair.publicKey,
            toPubkey: mainWallet.publicKey,
            lamports: withdrawalAmount,
          })
        );
  
        const signature = await connection.sendTransaction(transaction, [volumeWalletKeypair]);
        await connection.confirmTransaction(signature, 'confirmed');
  
        console.log(`Withdrawn ${withdrawalAmount / LAMPORTS_PER_SOL} SOL from ${wallet.publicKey}`);
        return true;
      } catch (error) {
        console.error(`Failed to withdraw from ${wallet.publicKey}:`, error);
        if (error instanceof SendTransactionError) {
          const logs = await error.getLogs(connection);
          console.log('Transaction logs:', logs);
        }
        return false;
      } finally {
        setWithdrawingWallets(prev => ({ ...prev, [wallet.publicKey]: false }));
      }
    }));
  
    const successCount = withdrawResults.filter(result => result).length;
    const failCount = withdrawResults.length - successCount;
  
    // Update balances after withdrawals
    await Promise.all(volumeWallets.map(async (wallet) => {
      const [newSolBalance, newTokenBalance] = await Promise.all([
        fetchBalance(wallet.publicKey),
        fetchTokenBalance(wallet.publicKey)
      ]);
  
      setVolumeWallets(prev => 
        prev.map(w => 
          w.publicKey === wallet.publicKey 
            ? { ...w, balance: newSolBalance }
            : w
        )
      );
  
      setTokenBalances(prev => ({
        ...prev,
        [wallet.publicKey]: newTokenBalance
      }));
    }));
  
    // Update relay wallet balance
    const newRelayBalance = await fetchBalance(relayWallet.publicKey);
    setRelayWallet(prev => ({ ...prev, balance: newRelayBalance }));
  
    // Update main wallet balance
    const newMainBalance = await connection.getBalance(mainWallet.publicKey);
    setMainWalletBalance(newMainBalance / LAMPORTS_PER_SOL);
  
    if (failCount > 0) {
      showToast(`Withdrawal completed. ${successCount} successful, ${failCount} failed. Check console for details.`, 'warning');
    } else if (successCount > 0) {
      showToast('Successfully withdrawn from all volume wallets to main wallet!', 'success');
    } else {
      showToast('No volume wallets had sufficient balance for withdrawal', 'info');
    }
  
    setIsWithdrawing(false);
    setWithdrawingWallets({});
  };

  const handleManualAirdrop = (wallet) => {
    setSelectedWallet(wallet);
    setShowManualAirdropOverlay(true);
    setManualAirdropAmount('');
  };

  // Add new function for performing manual airdrop
  const performManualAirdrop = async () => {
    if (!relayWallet || !selectedWallet || !manualAirdropAmount) {
      showToast('Invalid airdrop parameters', 'error');
      return;
    }
  
    setIsManualAirdropping(true);
  
    try {
      const amount = parseFloat(manualAirdropAmount);
      if (isNaN(amount) || amount <= 0) {
        throw new Error('Invalid airdrop amount');
      }
  
      const relayBalance = await fetchBalance(relayWallet.publicKey);
      if (relayBalance < amount) {
        throw new Error(`Insufficient relay wallet balance. Available: ${relayBalance.toFixed(3)} SOL`);
      }
  
      // Priority fee calculation
      const computeUnits = 300000;
      const microLamports = calculatePriorityFee(priorityFee, computeUnits);
  
      const transaction = new Transaction();
  
      // Add compute budget instructions first
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: computeUnits,
        }),
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: microLamports,
        }),
        SystemProgram.transfer({
          fromPubkey: new PublicKey(relayWallet.publicKey),
          toPubkey: new PublicKey(selectedWallet.publicKey),
          lamports: Math.floor(amount * LAMPORTS_PER_SOL),
        })
      );
  
      const signature = await connection.sendTransaction(
        transaction,
        [Keypair.fromSecretKey(bs58.decode(relayWallet.secretKey))]
      );
  
      await connection.confirmTransaction(signature);
      
      // Update balances after successful airdrop
      await updateBalancesAfterTransaction(selectedWallet);
      
      showToast(`Successfully airdropped ${amount} SOL to wallet`, 'success');
      setShowManualAirdropOverlay(false);
    } catch (error) {
      console.error('Manual airdrop error:', error);
      showToast(`Airdrop failed: ${error.message}`, 'error');
    } finally {
      setIsManualAirdropping(false);
    }
  };

  const handleDumpAllClick = () => {
    setShowDumpAllConfirmation(true);
  };

  const handleDumpAllConfirmation = () => {
    setShowDumpAllConfirmation(false);
    executeBundleSell();
  };

  const handleWithdrawClick = () => {
    setShowWithdrawConfirmation(true);
  };

  const handleWithdrawConfirmation = () => {
    setShowWithdrawConfirmation(false);
    withdrawToMain();
  };

  return (
    <div className="volume-bot">
      <h1>Volume Bot</h1>
      
      {/* Relay Wallet Section */}
      <div className="wallet-info">
  <div className="info-section">
    <p>
      <span className="label">Wallet:</span>
      <span className="value">
        {relayWallet ? (
          <>
            <span
              className="truncated-address"
              onClick={() => handleCopyToClipboard(relayWallet.publicKey, 'Public Key')}
              title={relayWallet.publicKey}
            >
              {truncateAddress(relayWallet.publicKey)}
            </span>
            <FontAwesomeIcon icon={faCopy} className="copy-icon" />
          </>
        ) : (
          <span className="truncated-address">-</span>
        )}
      </span>
    </p>
    <div className="balance-actions-refresh">
      <div className="balance">
        <span className="sol-balance">
          <span className="label">Balance:</span>
          <span className="value">
            {relayWallet ? `${relayWallet.balance.toFixed(3)} SOL` : '0.000 SOL'}
          </span>
        </span>
      </div>
      <div className="refresh-balance">
        <button
          className="refresh-button"
          onClick={() => relayWallet && refreshWalletBalance(relayWallet.publicKey, true)}
          disabled={!relayWallet || refreshingBalance[relayWallet?.publicKey]}
        >
          <FontAwesomeIcon 
            icon={faRotate} 
            spin={refreshingBalance[relayWallet?.publicKey]}
            className="refresh-icon"
          />
        </button>
      </div>
    </div>
  </div>
</div>

      {/* Configuration Section */}
      <div className="main-actions-title">
          <span>Configuration</span>
          </div>
      <div className="config-section">
        <div className="config-items">
          <div className="config-item">
            <label>Target Holders:</label>
            <input
              type="number"
              value={targetHolders}
              onChange={(e) => setTargetHolders(Math.max(1, Math.min(100, parseInt(e.target.value) || 0)))}
              min="1"
              max="100"
              className="config-input"
            />
          </div>

          <div className="config-item">
            <label>Jito Tip:</label>
            <input
              type="number"
              value={jitoTip}
              onChange={(e) => setJitoTip(Math.max(0, parseFloat(e.target.value) || 0))}
              min="0"
              step="0.0001"
              className="config-input"
            />
          </div>

          <div className="config-item">
            <label>Buy Delay (sec):</label>
            <input
              type="number"
              value={buyDelay}
              onChange={(e) => setBuyDelay(Math.max(1, parseInt(e.target.value) || 0))}
              min="1"
              step="1"
              className="config-input"
            />
          </div>

          <div className="config-item">
            <label>Sell Delay (sec):</label>
            <input
              type="number"
              value={sellDelay}
              onChange={(e) => setSellDelay(Math.max(1, parseInt(e.target.value) || 0))}
              min="1"
              step="1"
              className="config-input"
            />
          </div>

          <div className="config-item">
            <label>Min Buy (SOL):</label>
            <input
              type="number"
              value={minBuy}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setMinBuy('');
                } else {
                  const numValue = parseFloat(value);
                  if (!isNaN(numValue)) {
                    setMinBuy(Math.max(0, Math.min(maxBuy || Infinity, numValue)));
                  }
                }
              }}
              min="0"
              step="0.01"
              className="config-input"
            />
          </div>

          <div className="config-item">
            <label>Max Buy (SOL):</label>
            <input
              type="number"
              value={maxBuy}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setMaxBuy('');
                } else {
                  const numValue = parseFloat(value);
                  if (!isNaN(numValue)) {
                    setMaxBuy(Math.max(minBuy || 0, numValue));
                  }
                }
              }}
              min="0"
              step="0.01"
              className="config-input"
            />
          </div>
          </div>
      </div>


      {/* Preset selector */}
      <div className="preset-buttons-container">
        <div className="preset-buttons">
          {Object.entries(TRADING_PRESETS).map(([key, preset]) => (
            <button
              key={key}
              onClick={() => {
                if (isRunning) {
                  showToast('Please stop the bot before changing modes', 'warning');
                  return;
                }
                setSelectedPreset(key);
              }}
              className={`preset-button ${selectedPreset === key ? 'active' : ''}`}
              data-tooltip={preset.description}
              disabled={isRunning}
            >
              <FontAwesomeIcon icon={preset.icon} className="preset-icon" />
              <span className="preset-name">{preset.name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Volume Wallets Section */}
      <div className="volume-wallets-section">
      <div className="bot-controls">
        <button 
          onClick={toggleBot}
          className={`volume-bot-start-stop-button ${isRunning ? 'running' : ''}`}
        >
          <FontAwesomeIcon icon={isRunning ? faStop : faPlay} />
          <span>{isRunning ? 'Stop' : 'Start'}</span>
        </button>
        <button
                onClick={handleDumpAllClick}
                disabled={isBundleSelling || !mintAddress || volumeWallets.length === 0}
                className="bundle-sell-button"
              >
                {isBundleSelling ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faArrowTrendDown} /> Dump All
                  </>
                )}
          </button>
      </div>

        {/* Only show this section if there are wallets */}
          <div className="volume-wallets-post-actions">
            <p className="main-actions-title">{volumeWallets.length} Wallets</p>
            {volumeWallets.length < 1 && (
              <div className="no-wallets">
                <div className="wallet-placeholder"></div>
                <div className="wallet-placeholder"></div>
                <div className="wallet-placeholder"></div>
              </div>
            )}
            {volumeWallets.length > 0 && (
            <div className="wallet-list">
              {volumeWallets.map((wallet, index) => (
                <div key={wallet.publicKey} className="info-section">
                    <p>
                      <span className="label">Wallet:</span>
                      <span className="value">
                        <span
                          className="truncated-address"
                          onClick={() => handleCopyToClipboard(wallet.publicKey, 'Public Key')}
                          title={wallet.publicKey}
                        >
                          {truncateAddress(wallet.publicKey)}
                        </span>
                        <FontAwesomeIcon icon={faCopy} className="copy-icon" />
                      </span>
                    </p>
                    <div className="balance-actions-refresh">
                    <div className="balances-actions">
                  <div className="balance-airdrop">
                    <div className="sol-balance">
                      <span className="label">Balance:</span>
                      <span className="value">{wallet.balance.toFixed(3)} SOL</span>
                    </div>
                    <div className="airdrop-sol">
                      <span className="label">Airdrop:</span>
                      <span className="value">{wallet.airdropAmount.toFixed(3)} SOL</span>
                    </div>
                  </div>
                  <div className='balance-divider-bundle'></div>
                  <div className="tokens-percentage">
                    <div className="bundle-tokens">
                      <span className="token-balance">
                        <span className="label">Tokens:</span>
                        {mintAddress ? (
                          <span className="value">
                            {formatTokenBalance(tokenBalances[wallet.publicKey] || 0)}
                          </span>
                        ) : (
                          <span className="value">0</span>
                        )}
                      </span>
                    </div>
                    <div className="bundle-wallet-percentage">
                      <span className="token-balance">
                        <span className="label">Supply:</span>
                        <span className="value">
                          {mintAddress && tokenBalances[wallet.publicKey] 
                            ? `${calculateSupplyPercentage(tokenBalances[wallet.publicKey])}%`
                            : '-'
                          }
                        </span>
                      </span>
                    </div>
                  </div>
                  </div>
                  <div className="refresh-balance">
                      <div 
                        className="manual-airdrop"
                        onClick={() => handleManualAirdrop(wallet)}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </div>
                    <button
                      className="refresh-button"
                      onClick={() => refreshWalletBalance(wallet.publicKey)}
                      disabled={refreshingBalance[wallet.publicKey]}
                    >
                      <FontAwesomeIcon 
                        icon={faRotate} 
                        spin={refreshingBalance[wallet.publicKey]}
                        className="refresh-icon"
                      />
                    </button>
                  </div>
                </div>
                </div>
              ))}
            </div>
          )}

          <div className="section-header">
          <button
            onClick={handleCreateClick}
            disabled={isGenerating}
            className="create-button"
          >
            {isGenerating ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Generate'}
          </button>
          {volumeWallets.length < 1 && (
              <div className="volume-airdrop-placeholder"></div>
          )}
          {volumeWallets.length > 0 && (
          <button
            onClick={airdropToWallets}
            disabled={isAirdropping || !relayWallet}
            className="volume-airdrop-button"
          >
            {isAirdropping ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <>
                Airdrop
                {volumeWallets.length > 0 && (
                  <>
                      <br />
                    <span className="volume-bot-airdrop-amount">
                        {`(${volumeWallets.reduce((sum, w) => sum + w.airdropAmount, 0).toFixed(3)} SOL)`}
                    </span>
                    </>
                  )}
              </>
            )}
          </button>
          )}
          </div>

          {volumeWallets.length > 0 && (
            <div className="post-list-actions">
              <button onClick={exportWallets} className="export-button">
                <FontAwesomeIcon icon={faDownload} /> Export
              </button>
              <button
                onClick={handleWithdrawClick}
                disabled={isWithdrawing || !relayWallet || volumeWallets.length === 0}
                className="withdraw-to-main-button"
              >
                {isWithdrawing ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                  <FontAwesomeIcon icon={faWallet} /> Withdraw
                  </>
                )}

              </button>
              <button 
                onClick={() => setShowDeleteConfirmation(true)} 
                className="delete-button"
              >
                <FontAwesomeIcon icon={faTrashCan} /> Delete
              </button>
            </div>
            )}
            {volumeWallets.length < 1 && (
              <div className="volume-bot-post-link-placeholder">
                <div className="post-link-placeholder"></div>
                <div className="post-link-placeholder"></div>
                <div className="post-link-placeholder"></div>
              </div>
            )}
          </div>
      </div>

      {showCreateConfirmation && (
        <div className="overlay">
          <h1>Generate New Wallets?</h1>
          <div className="overlay-modal">
            <div className="overlay-content">
              <p className="volume-bot-warning-text">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Warning: This will overwrite all existing volume wallets!
              </p>
              <div className="overlay-actions">
                <button onClick={handleCreateConfirm}>Generate</button>
                <button onClick={() => setShowCreateConfirmation(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div className="overlay">
          <h1>Confirm Deletion</h1>
          <div className="overlay-modal">
            <div className="overlay-content">
              <p>Type "confirm" to delete volume wallets:</p>
              <p className="volume-bot-warning-text">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Warning: This will overwrite all existing volume wallets!
              </p>
              <input
                type="text"
                value={deleteConfirmation}
                onChange={(e) => {
                  setDeleteConfirmation(e.target.value);
                  setDeleteError('');
                }}
                placeholder="Type 'confirm'"
                className={deleteError ? 'error-input' : ''}
              />
              {deleteError && (
                <div className="error-message">
                  <FontAwesomeIcon icon={faExclamationTriangle} /> {deleteError}
                </div>
              )}
              <div className="overlay-actions">
                <button onClick={handleDeleteConfirmation}>Delete</button>
                <button 
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                    setDeleteConfirmation('');
                    setDeleteError('');
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

{/* Manual Airdrop Overlay */}
{showManualAirdropOverlay && selectedWallet && (
  <div className="overlay">
    <h1>Manual Airdrop</h1>
    <div className="overlay-modal">
      <div className="overlay-content">
        <div className="manual-airdrop-info">
          <span className="wallet-info-label">
            <p>Wallet:</p>
            <p className="balance-value">
              {`${selectedWallet.publicKey.slice(0, 21)}...${selectedWallet.publicKey.slice(-4)}`}
            </p>
          </span>
        </div>
        <label>Airdrop amount (SOL):</label>
        <input
          type="number"
          value={manualAirdropAmount}
          onChange={(e) => setManualAirdropAmount(e.target.value)}
          min="0"
          step="0.001"
          placeholder="Enter SOL amount"
        />
        <div className="manual-airdrop-module-balances">
          <span className="airdrop-module-balances-info">
            <p className="airdrop-module-balances-label">Available:</p>
            <p className="airdrop-module-balances-value">
              {relayWallet ? relayWallet.balance.toFixed(3) : '0.000'} SOL
            </p>
          </span>
        </div>
        <div className="overlay-actions">
          <button 
            onClick={performManualAirdrop}
            disabled={isManualAirdropping || !manualAirdropAmount}
          >
            {isManualAirdropping ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <>
                <FontAwesomeIcon icon={faPaperPlane} /> Airdrop
              </>
            )}
          </button>
          <button className="close-button" onClick={() => setShowManualAirdropOverlay(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </div>
  </div>
)}

{showDumpAllConfirmation && (
        <div className="overlay">
          <h1>Confirm Dump All</h1>
          <div className="overlay-modal">
            <div className="overlay-content">
              <p className="volume-bot-warning-text">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Warning: This will sell all tokens from all volume wallets!
              </p>
              <div className="overlay-actions">
                <button onClick={handleDumpAllConfirmation}>Confirm</button>
                <button onClick={() => setShowDumpAllConfirmation(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

{showWithdrawConfirmation && (
        <div className="overlay">
          <h1>Confirm Withdraw</h1>
          <div className="overlay-modal">
            <div className="overlay-content">
              <p className="volume-bot-warning-text">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Warning: This will withdraw all SOL from volume wallets to the main wallet!
              </p>
              <div className="overlay-actions">
                <button onClick={handleWithdrawConfirmation}>Confirm</button>
                <button onClick={() => setShowWithdrawConfirmation(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Toast Notifications */}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={clearToast}
        />
      )}
    </div>
  );
};

export default VolumeBot;